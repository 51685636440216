import React, { Component } from 'react';
import { Modal, DropdownButton, Dropdown, Table, Col, Container, Row, Form, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import { connect } from "react-redux";
import { 
    showMessage
} from '../../actions/popupMessageActions';

class AdminChangeRole extends Component {
    constructor() {
        super();
        this.state = {
          isLoggedIn: false,
          prompt: "",
          username: "",
          level: "",
          showAlertChangePass: false,
          updatePassModalShow: false,
          isDisabled: false,

          roles: [],
          user_role: "",
        };
    }
    componentDidMount() {
        this.getUserRoles();
    }
    getUserRoles = () => {
        return axios
        .get('/api/v1/roles', 
        {
            headers: {'Content-Type':'application/json'}
        })
        .then(response => {
          this.setState({roles: response.data});
          
        });
    }
    inputChange = e => {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value
        });
    };
    handelOpenModalChangePass = () => {
        this.setState({ updatePassModalShow: true });
    };
    handleCloseModal = () => {
        this.setState({ updatePassModalShow: false });
    };
    
    submitFormUpdateRole = e => {
        this.setState({ isDisabled: true });
        e.preventDefault();
        return axios
            .post(
                "/api/v1/update-role",
                {
                id: this.props.userId,
                role: this.state.user_role
                },
                {
                headers: {
                    "Content-Type": "application/json"
                }
                }
            )
            .then(response => {
                if (response.data.result["status"] == "Success") {
                this.setState({
                    prompt: response.data.result["Description"] + "!",
                    updatePassModalShow: false,
                    showAlertChangePass: true,
                    isDisabled: false
                });
                } else {
                this.setState({
                    prompt: response.data.result["Description"] + "!",
                    showAlertChangePass: true,
                    isDisabled: false
                });
                }
            })
            .catch(error => {
                this.props.showMessage('danger', "Server Error! ");
            });
    };
    handleCloseModalAlert = () => {
        this.setState({ showAlertChangePass: false });
        window.location.reload();
    };
    render() { 
        const errPwordMsg = this.state.notEqualPassword === true ? "Password doesn't match " : "Please enter your password.";
        const errPwordInpClass = this.state.notEqualPassword === true ? "invalid form-control" : "valid form-control";
        const errPwordMsgDisp = this.state.notEqualPassword === true ? "invalid_msg invalid-feedback" : "invalid-feedback";
        return ( 
            <React.Fragment>
                <a className="mr-3" onClick={this.handelOpenModalChangePass}>
                    Change Role
                </a>
                <Modal
                    show={this.state.showAlertChangePass}
                    onHide={this.handleCloseModalAlert}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    id="modal-for-alert"
                    backdrop="static"
                    centered
                    >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h6>{this.state.prompt}</h6>
                        <div className="btn-submit-change-ok">
                        <button
                            type="submit"
                            onClick={this.handleCloseModalAlert}
                            className="btn btn-change-ok font-bold btn-primary"
                        >
                            Okay
                        </button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.updatePassModalShow}
                    onHide={this.handleCloseModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    id="modal-change-pass"
                    backdrop="static"
                    centered
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4>Change Role</h4>
                        <Form autocomplete="off" onSubmit={this.submitFormUpdateRole.bind(this)}>
                        <Form.Group controlId="admin-email">
                        <Form.Label>User Role</Form.Label>
                          <Form.Control
                          autocomplete="off"
                              required
                              as="select"
                              name="user_role"
                              className="valid"
                              onChange={this.inputChange.bind(this)}
                          > 
                              <option></option>    
                          {       
                                       
                            this.state.roles.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>{item.admin_role}</option>
                              ); 
                              
                            })
                          }
                          </Form.Control>
                        </Form.Group>
                        <div className="btn-submit-change-pass">
                            {this.state.isDisabled ? (
                            <button
                                type="submit"
                                className="btn btn-change-pass isDisabled-admin font-bold btn-primary"
                            >
                                Submit
                            </button>
                            ) : (
                            <button
                                onClick={this.submitFormUpdateRole.bind(this)}
                                type="submit"
                                className="btn btn-change-pass font-bold btn-primary"
                            >
                                Submit
                            </button>
                            )}
                        </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    dashboard: state.dashboard
});
  
export default connect(mapStateToProps, 
{ 
    showMessage 
})(AdminChangeRole);