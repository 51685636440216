import React, { Component } from 'react';
import { Modal, DropdownButton, Dropdown, Table, Col, Container, Row, Form, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';

import { connect } from "react-redux";
import { 
    showMessage
} from '../../actions/popupMessageActions';

class AdminChangePass extends Component {
    constructor() {
        super();
        this.state = {
          isLoggedIn: false,
          prompt: "",
          username: "",
          level: "",
          showAlertChangePass: false,
          updatePassModalShow: false,
          notEqualPassword: false,
          current_password: "",
          new_password: "",
          confirm_password: "",
          isDisabled: false
        };
    }
    inputChange = e => {
        this.setState({
          ...this.state,
          [e.target.name]: e.target.value
        });
    };
    handelOpenModalChangePass = () => {
        this.setState({ updatePassModalShow: true });
    };
    handleCloseModal = () => {
        this.setState({ updatePassModalShow: false });
    };
    onChangePasswordAdmin = e => {
        if (
          e.target.name === "new_password" ||
          e.target.name === "confirm_password"
        ) {
          this.setState(
            {
              ...this.state,
              [e.target.name]: e.target.value
            },
            () => {
              let pword = String(this.state.new_password);
              let confirm_pword = String(this.state.confirm_password);
    
              if (pword === confirm_pword) {
                this.setState({ notEqualPassword: false });
              } else {
                this.setState({ notEqualPassword: true });
              }
            }
          );
        }
      };
    submitFormUpdatePassword = (e) => {
        this.setState({ isDisabled: true });
        e.preventDefault();
        return axios
            .post(
                "/api/v1/update-password",
                {
                id: this.props.userId,
                new_password: this.state.new_password
                },
                {
                headers: {
                    "Content-Type": "application/json"
                }
                }
            )
            .then(response => {
                if (response.data.result["status"] == "Success") {
                this.setState({
                    prompt: response.data.result["Description"] + "!",
                    updatePassModalShow: false,
                    showAlertChangePass: true,
                    isDisabled: false
                });
                } else {
                this.setState({
                    prompt: response.data.result["Description"] + "!",
                    showAlertChangePass: true,
                    isDisabled: false
                });
                }
            })
            .catch(error => {
                this.props.showMessage('danger', "Server Error! ");
            });
    };
    handleCloseModalAlert = () => {
        this.setState({ showAlertChangePass: false });
    };
    render() { 
        const errPwordMsg = this.state.notEqualPassword === true ? "Password doesn't match " : "Please enter your password.";
        const errPwordInpClass = this.state.notEqualPassword === true ? "invalid form-control" : "valid form-control";
        const errPwordMsgDisp = this.state.notEqualPassword === true ? "invalid_msg invalid-feedback" : "invalid-feedback";
        return ( 
            <React.Fragment>
                <a className="mr-3" onClick={this.handelOpenModalChangePass}>
                    Change Password
                </a>
                <Modal
                    show={this.state.showAlertChangePass}
                    onHide={this.handleCloseModalAlert}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    id="modal-for-alert"
                    backdrop="static"
                    centered
                    >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h6>{this.state.prompt}</h6>
                        <div className="btn-submit-change-ok">
                        <button
                            type="submit"
                            onClick={this.handleCloseModalAlert}
                            className="btn btn-change-ok font-bold btn-primary"
                        >
                            Okay
                        </button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.updatePassModalShow}
                    onHide={this.handleCloseModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    id="modal-change-pass"
                    backdrop="static"
                    centered
                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <h4>Change Password</h4>
                        <Form autocomplete="off" onSubmit={this.submitFormUpdatePassword.bind(this)}>
                            <Form.Group controlId="new-pass">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                required
                                autocomplete="off"
                                type="password"
                                name="new_password"
                                className={errPwordInpClass}
                                value={this.state.password}
                                onChange={this.onChangePasswordAdmin.bind(this)}
                                />
                                <Form.Control.Feedback
                                id="feedback"
                                className={errPwordMsgDisp}
                                type="invalid"
                                >
                                {errPwordMsg}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId="conf-pass">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                required
                                type="password"
                                name="confirm_password"
                                autocomplete="off"
                                className={errPwordInpClass}
                                value={this.state.confirmPassword}
                                onChange={this.onChangePasswordAdmin.bind(this)}
                                />
                                <Form.Control.Feedback
                                id="feedback"
                                className={errPwordMsgDisp}
                                type="invalid"
                                >
                                {errPwordMsg}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <div className="btn-submit-change-pass">
                                {this.state.isDisabled ? (
                                <button
                                    type="submit"
                                    className="btn btn-change-pass isDisabled-admin font-bold btn-primary"
                                    onClick={this.submitFormUpdatePassword.bind(this)}
                                >
                                    Submit
                                </button>
                                ) : (
                                <button
                                    type="submit"
                                    className="btn btn-change-pass font-bold btn-primary"
                                    onClick={this.submitFormUpdatePassword.bind(this)}
                                >
                                    Submit
                                </button>
                                )}
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    dashboard: state.dashboard
});
  
export default connect(mapStateToProps, 
{ 
    showMessage 
})(AdminChangePass);