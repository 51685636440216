import React, { Component } from 'react';
import { Navbar,Nav } from 'react-bootstrap';
import PaymentSuccess from '../components/paymentSuccess'
import PaymentUserFailed from '../components/paymentUserFailed'
import PaymentServerFailed from '../components/paymentServerFailed'
import * as moment from 'moment';
import Loading from '../components/loading';
import $ from 'jquery';

class PaymentResult extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            show: false,
          paymentResult: "",
          accountSummary: "",
          currentYear: moment().format('YYYY'),
          isLoading: true
        };
    }
    componentDidMount() {
        var time = new Date().getTime();
        $(document.body).bind("mousemove keypress", function(e) {
            time = new Date().getTime();
        });
        const minute = 60000
        function refresh() {
            if(new Date().getTime() - time >= (minute*15)) {
                localStorage.clear()
                localStorage.setItem("forcedLogout",true)
                window.location.replace("/");
            }
            else {
                setTimeout(refresh, 10000);
            } 
        }
        setTimeout(refresh, 10000);
        console.log('sadsadsadsadsa2321321',localStorage.getItem('paymentResult'))
        try{
            this.setState({
                paymentResult: JSON.parse(localStorage.getItem('paymentResult')),
                accountSummary: JSON.parse(localStorage.getItem('accountSummary'))
            }, () => {
                console.log("this.state.paymentResult",this.state.paymentResult)
                console.log("this.state.accountSummary",this.state.accountSummary)
                this.setState({
                    isLoading: false
                })
                localStorage.removeItem('paymentResult')
                localStorage.removeItem('accountSummary')
            })
        }
        catch(ex){
            window.location.replace('/')
        }
    }
    componentDidCatch() {
        window.location.replace('/')
    }
    render() { 
        if((this.state.paymentResult == null || this.state.accountSummary == null) && !this.state.isLoading){
            return window.location.replace('/dashboard')
        } 
        return ( 
            this.state.isLoading ?
            <Loading />
            :
            
            <React.Fragment>
                <section id="payment-header">
                    <div className="container-fluid">
                        <div className="row secondary-back-color">
                            <div className="col-lg-12 text-right">
                                <br/>
                            </div>
                        </div>
                        <div className="row primary-back-color">
                            <div className="col-lg-12 nav-main">
                                <div className="nav-logo text-center" style={{padding: "60px"}}>
                                    <img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                                </div>
                            </div>
                        </div> 
                    </div>
                </section>
                <section id="payment-content">
                    {
                        this.state.paymentResult.data.Transaction_Approved === "true" ?
                            <PaymentSuccess result={this.state.paymentResult} accountSummary={this.state.accountSummary} />
                        :
                        this.state.paymentResult.data.Transaction_Approved === "serverFailed" ?
                            <PaymentServerFailed result={this.state.paymentResult} accountSummary={this.state.accountSummary} />
                        :
                        this.state.paymentResult.data.Transaction_Approved === "false" ?
                            <PaymentUserFailed result={this.state.paymentResult} accountSummary={this.state.accountSummary} />
                        :
                        ""
                    }
                </section>
                <div id="footer-bottom" style={{border: "1px solid #ebebeb"}}>
                    <div className="container">
                        <div className="row">
                            <div className="footer-bottom-content">
                                <div className="dark-font-color footer-bottom-text">
                                    <p>All Rights Reserved © {this.state.currentYear} Guam Water Authority</p>
                                </div>
                                {/* <div className="dark-font-color footer-bottom-icons">
                                    <img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img>
                                    <img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks"></img>
                                    <img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu"></img>
                                    <img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default PaymentResult;