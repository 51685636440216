import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { 
    searchString
} from '../actions/dashboardActions';
import $ from 'jquery'
import BtnGoBack from './btnGoBackPaymentRes';
class PaymentSuccess extends Component {
    state = { 
        showLogo: false
    }
    getFieldValue = (str, result) => {
        return this.props.searchString(str, result)
    }
    downloadBillPDF = (e) => {
        e.preventDefault();
        var newWindow = window.open();
        document.getElementById('pdf-logo').style.display = 'block';
        const jsPDF = require('jspdf');
        const result = this.props.result;
        const receiptNum = this.getFieldValue("TRANS. REF.", result);
        var pdf = new jsPDF('p','px','a4');
        var source = $("#receipt")[0];
        this.setState({
            showLogo: true
        }, () => {
            pdf.addHTML(source,function() {

                pdf.save('PayGWA_'+receiptNum+'.pdf')
                // const screenWidth = window.screen.width
                // if(screenWidth < 1200){
                //     document.getElementById("btn-my-bills").click();
                    
                // }
                document.getElementById('pdf-logo').style.display = 'none';
            });
            
        })
        setTimeout(function() {
			newWindow.close();
		}, 50);
    }
    render() { 
        const result = this.props.result
        let today = new Date();
        const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
        let date = today.getDate()+' '+months[today.getMonth()]+' '+today.getFullYear();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date+' '+time;
        const priceSplitter = (number) => (number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        return ( 
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h3><span style={{color: 'rgb(8, 196, 132)'}}><i class="fas fa-check-circle"></i></span> Approved - Thank you</h3>
                        </div>
                    </div>
                </div>
                <div id="receipt" className="pt-5">
                    <div id="pdf-logo" className="row mb-3" style={{display: "none"}}>
                        <div className="col-lg-12 text-center">
                            <img src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                        </div>
                    </div>
                    
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="bordered">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div>
                                                <label><strong>CARD & AMOUNT:</strong> {result.data.CardType + " $ " + parseFloat(Math.round(result.data.DollarAmount * 100) / 100).toFixed(2) + " " + result.data.Currency}</label>
                                                <label><strong>CARDHOLDER NAME:</strong> {result.data.CardHoldersName}</label>
                                                <label><strong>CARD NUMBER:</strong> {result.data.Card_Number}</label>
                                                <label><strong>DATE/TIME:</strong>{" "+dateTime}</label>
                                            </div> 
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="left-auto">
                                                <label><strong>REFERENCE #:</strong>{this.getFieldValue("REFERENCE #", result)}</label>
                                                <label><strong>AUTHORIZATION #:</strong>{this.getFieldValue("AUTHOR. #", result)}</label>
                                                <label><strong>RECEIPT NO.:</strong>{this.getFieldValue("TRANS. REF.", result)}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <h5>Accounts Paid:</h5>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="bordered p-0">
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-4 p-0">
                                            <div className="text-center header-left">
                                                <label><strong>ACCOUNT ID:</strong></label>
                                            </div>
                                            <div className="text-center content-left">
                                                {
                                                    _.map(this.props.accountSummary, (item, index) => {
                                                        return(
                                                            item.checked && parseFloat(item.amountToBePaid) > 0.00 ? 
                                                                <label key={index}>{item.accID}</label>
                                                            :
                                                            ""
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 p-0">
                                            <div className="text-center header-left">
                                                <label><strong>SERVICE LOCATION:</strong></label>
                                            </div>
                                            <div className="text-center content-left">
                                                {
                                                    _.map(this.props.accountSummary, (item, index) => {
                                                        return(
                                                            item.checked && parseFloat(item.amountToBePaid) > 0.00 ? 
                                                                <label key={index}>{item.serviceLocation.split(",")[0]}</label>
                                                            :
                                                            ""
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <div className="col-lg-4 p-0">
                                            <div className="text-center header-right">
                                                <label><strong>AMOUNT:</strong></label>
                                            </div>
                                            <div className="text-center content-right">
                                                {
                                                    _.map(this.props.accountSummary, (item, index) => {
                                                        return(
                                                            item.checked && parseFloat(item.amountToBePaid) > 0.00 ? 
                                                                <label key={index}>{"$ "+priceSplitter(parseFloat(Math.round(item.amountToBePaid * 100) / 100).toFixed(2))}</label>
                                                            :
                                                            ""
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="row mb-5">
                    <div className="col-lg-6 text-right">
                        <button id="btn-download" type="submit" class="btn regular-font-size font-bold btn-left" onClick={this.downloadBillPDF.bind(this)}>Save this as PDF file</button>
                    </div>
                    <div className="col-lg-6 text-left">
                        <BtnGoBack />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { searchString })(PaymentSuccess);