import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';

import { 
    hideMessage
} from '../actions/popupMessageActions';

class PopupMessage extends Component {
    state = {  }
    hideModal = (e) => {
        if(this.props.popupMessage.modalConfig.message != "Your payment is processing. Please don't close this page."){
            this.props.hideMessage();
            if(window.location.pathname == "/view-bill" ){
                localStorage.setItem('viewBillURL', window.location.href);
                if(this.props.popupMessage.modalConfig.message != "Sorry, the link has expired."){
                    window.location.replace('/');
                }
            }
        }
    }
    render() { 
        return ( 
            <React.Fragment>
                <Modal
                    id="popup-message"
                    show={this.props.popupMessage.modalConfig.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                        {
                            this.props.popupMessage.modalConfig.type === "" ? 
                            null
                            :
                            this.props.popupMessage.modalConfig.type === "danger" ? 
                            <h5 className="mb-0"><img src={process.env.PUBLIC_URL + '/images/danger.png'} /> Error</h5>
                            :
                            this.props.popupMessage.modalConfig.type === "warning" ? 
                            <h5 className="mb-0"><img src={process.env.PUBLIC_URL + '/images/warning.png'} /> Caution</h5>
                            :
                            <h5 className="mb-0"><img src={process.env.PUBLIC_URL + '/images/success.png'} /> Success</h5>
                        }
                        
                    </Modal.Header>
                    <Modal.Body>
                        <div >
                            <center><label>{this.props.popupMessage.modalConfig.message}</label></center>
                        </div>
                        
                        <div class="mt-2">
                            <center><button class="btn btn-primary font-bold" onClick={this.hideModal.bind(this)} style={{    backgroundColor: "#08c484",
    borderColor: "#08c484",
    height: "40px",
    width: "90px"}}>OK</button></center>
                        </div>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    popupMessage: state.popupMessage
});

export default connect(mapStateToProps, { hideMessage })(PopupMessage);