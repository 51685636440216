import React, { Component } from 'react';
import { Form, Button, Spinner, Col } from 'react-bootstrap';
import {connect} from 'react-redux';
import Loading from './loading';
import { fetchOldUserDetails, getSequQuestions, getCountry, updateUserDetails, updateUserPassword } from '../actions/dashboardActions';
import { 
    showMessage
} from '../actions/popupMessageActions';
import _ from 'lodash';

class EditAccount extends Component {
    constructor() {
        super()
        this.state = {
            validatedEditAcc: false,
            validatedChangePass: false,
            isNotPword: false,
            isEditingPass : false,
            isEditLoading: true,
            isAdressEditable: true,
            isEmail: true,
            isProcessing: false,
            isAllNoPhones: false,
            pwErrMsg: '',
            isInvalidPhones: {
                homePhone: false,
                mobilePhone: false,
                workPhone: false
            },
            isDeletePhones: {
                homePhone: false,
                mobilePhone: false,
                workPhone: false
            },
            userAccDetails: {
                personId: '',
                accountId: '',
                username: '',
                fullName: '',
                emailAddress: '',
                address1: '',
                address2: '',
                city: '',
                postal: '',
                stateInitials: '',
                stateDesc: '',
                country: '',
                home: '',
                mobile: '',
                work: '',
                oldDateEmail: '',
                oldDateQuestion: '',
                oldDateAnswer: '',
                characteristicValue: '',
                answer: ''
            },
            userPassDetails: {
                oldPass: '',
                newPass: '',
                confirmPass: ''
            }
        }
    }

    componentWillMount() {
        //session variable search
        let sessionPersonId, sessionUsername;
        for(var count = 0; count < Object.keys(localStorage).length; count++){
            if(!(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex")){
                
                if(Object.keys(localStorage)[count] === 'personId'){
                    sessionPersonId = localStorage.getItem(Object.keys(localStorage)[count]);
                }
                else if(Object.keys(localStorage)[count] === 'username'){
                    sessionUsername = localStorage.getItem(Object.keys(localStorage)[count]);
                }
            }
        }
        this.props.getSequQuestions()
        .then(() => {
            this.props.getCountry().then(() => {
                this.props.fetchOldUserDetails(sessionPersonId)
                .then(() => {
                    this.setState({
                        ...this.state,
                        isEditLoading: false,
                        isAdressEditable: (this.props.dashboard.userOldDataDetails.billAddressSource !== "PER") ? false : true,
                        userAccDetails: {
                            personId:            sessionPersonId,
                            accountId:           this.props.accountId,
                            username:            sessionUsername,
                            fullName:            this.props.dashboard.userAccountDetails.fullName || '',
                            emailAddress:        this.props.dashboard.userAccountDetails.emailAddress || '',
                            address1:            this.props.dashboard.userAccountDetails.addressLine1 || '',
                            address2:            this.props.dashboard.userAccountDetails.addressLine2 || '',
                            city:                this.props.dashboard.userAccountDetails.city || '',
                            postal:              this.props.dashboard.userAccountDetails.postal || '',
                            stateInitials:       this.props.dashboard.userAccountDetails.stateInitials || '',
                            stateDesc:           this.props.dashboard.userAccountDetails.state || '',
                            country:             this.props.dashboard.userAccountDetails.country || '',
                            home:                this.props.dashboard.userAccountDetails.homePhone,
                            mobile:              this.props.dashboard.userAccountDetails.mobilePhone,
                            work:                this.props.dashboard.userAccountDetails.workPhone,
                            oldDateEmail:        this.props.dashboard.userOldDataDetails.oldDateEmailAdd || '',
                            oldDateQuestion:     this.props.dashboard.userOldDataDetails.oldDateSecuQuestion || '',
                            oldDateAnswer:       this.props.dashboard.userOldDataDetails.oldDateAnswer || '',
                            characteristicValue: this.props.dashboard.userOldDataDetails.oldCharValueSecuQues ||  this.props.dashboard.securityQuestions[0].characteristicValue,
                            answer:              this.props.dashboard.userOldDataDetails.oldAnswerSecuQues || ''
                        }
                    })
                })
            })
        })
      
    }

    onChangePassword = (e) => {
        if(e.target.name === "newPass" || e.target.name === "confirmPass"){
            this.setState({
                ...this.state,
                pwErrMsg: '',
                userPassDetails: {
                    ...this.state.userPassDetails,
                    [e.target.name] : e.target.value
                }
            },
            () => {
                let pword = String(this.state.userPassDetails.newPass);
                let confirm_pword = String(this.state.userPassDetails.confirmPass);
                if(pword.length < 8){
                    this.setState({ 
                        isNotPword: true,
                        pwErrMsg: "Your password must be between 8 – 15 characters long"
                    });
                }
                else{
                    if(pword===confirm_pword){
                        this.setState({ isNotPword: false });
                    }else{
                        this.setState({ isNotPword: true, pwErrMsg: "Password doesn't match!" });
                    }
                }
            })
        }
        else{
            this.setState({
                ...this.state,
                userPassDetails: {
                    ...this.state.userPassDetails,
                    [e.target.name] : e.target.value
                }
            })
            
        }
    }

    onChangeEditAcc = (e) => {
        if(e.target.name === "emailAddress" && e.target.value !== ""){
            this.setState({
                ...this.state,
                userAccDetails: {
                    ...this.state.userAccDetails,
                    [e.target.name] : e.target.value
                }
            },
            () => {
                let email = String(this.state.userAccDetails.emailAddress);
                // eslint-disable-next-line
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                    this.setState({ 
                        ...this.state,
                        isEmail: true 
                    });
                }
                else{
                    this.setState({ 
                        ...this.state,
                        isEmail: false 
                    });
                }
            })
        }
        else if(e.target.name === "mobile" || e.target.name === "work" || e.target.name === "home"){
            var phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
            this.setState({
                ...this.state,
                userAccDetails: {
                    ...this.state.userAccDetails,
                    [e.target.name]: e.target.value
                }
            })
            if(e.target.value === ""){
                this.setState({
                    isDeletePhones: {
                        ...this.state.isDeletePhones,
                        [e.target.name+"Phone"] : true
                    },
                    isInvalidPhones: {
                        ...this.state.isInvalidPhones,
                        [e.target.name+"Phone"] : false
                    }
                })
            }
            else if(!(e.target.value).match(phoneno)){
                this.setState({
                    isInvalidPhones: {
                        ...this.state.isInvalidPhones,
                        [e.target.name+"Phone"] : true
                    }
                })
            }
            else{
                this.setState({
                    isDeletePhones: {
                        ...this.state.isDeletePhones,
                        [e.target.name+"Phone"] : false
                    },
                    isInvalidPhones: {
                        ...this.state.isInvalidPhones,
                        [e.target.name+"Phone"] : false
                    }
                })
            }
        }
        else{
            this.setState({
                ...this.state,
                userAccDetails: {
                    ...this.state.userAccDetails,
                    [e.target.name]: e.target.value
                }
            })
        }
    }

    stateOnChange = (e) => {
        var select = document.getElementById("validationCustom08")
        this.setState({ 
            ...this.state,
            userAccDetails: {
                ...this.state.userAccDetails, 
                stateDesc: select[select.selectedIndex].text,
                stateInitials: e.target.value
            } 
        }) 
    }

    questionOnChange = (e) => {
        this.setState({ 
            ...this.state,
            userAccDetails: {
                ...this.state.userAccDetails, 
                // characteristicValue: select[select.selectedIndex].text,
                characteristicValue: e.target.value
            } 
        }) 
    }

    showChangePass = e => {
        e.preventDefault()
        this.setState({isEditingPass:!this.state.isEditingPass})
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if(this.state.isEditingPass === false){
            if (form.checkValidity() === false){ 
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    validatedEditAcc: true
                })
            }
            else if(document.getElementById("home-phone").value === "" && document.getElementById("work-phone").value === "" && document.getElementById("mobile-phone").value === ""){
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    isAllNoPhones: true
                }, () => {
                    var elmnt = document.getElementById("contact-info-error-message");
                    elmnt.scrollIntoView();
                })
            }
            else if(this.state.isInvalidPhones.mobilePhone || this.state.isInvalidPhones.homePhone || this.state.isInvalidPhones.workPhone){
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    isProcessing: false
                })
            }
            else{
                this.setState({
                    ...this.state,
                    isProcessing: true,
                    isAllNoPhones: false
                })
                const postData = {
                    personId: this.state.userAccDetails.personId,
                    accountId: this.state.userAccDetails.accountId,
                    emailAddress: this.state.userAccDetails.emailAddress,
                    address1: this.state.userAccDetails.address1,
                    address2: this.state.userAccDetails.address2,
                    city: this.state.userAccDetails.city,
                    postal: this.state.userAccDetails.postal,
                    stateInitials: this.state.userAccDetails.stateInitials,
                    stateDesc: this.state.userAccDetails.stateDesc,
                    country: this.state.userAccDetails.country,
                    home: (this.state.userAccDetails.home === this.props.dashboard.userAccountDetails.homePhone) ? '' : this.state.userAccDetails.home,
                    mobile: (this.state.userAccDetails.mobile === this.props.dashboard.userAccountDetails.mobilePhone) ? '' : this.state.userAccDetails.mobile,
                    work: (this.state.userAccDetails.work === this.props.dashboard.userAccountDetails.workPhone) ? '' : this.state.userAccDetails.work,
                    isDeletedHome: (this.props.dashboard.userAccountDetails.homePhone === undefined || this.props.dashboard.userAccountDetails.homePhone === "" || this.props.dashboard.userAccountDetails.homePhone === null) ? false : this.state.isDeletePhones.homePhone,
                    isDeletedMobile: (this.props.dashboard.userAccountDetails.mobilePhone === undefined || this.props.dashboard.userAccountDetails.mobilePhone === "" || this.props.dashboard.userAccountDetails.mobilePhone === null) ? false : this.state.isDeletePhones.mobilePhone,
                    isDeletedWork: (this.props.dashboard.userAccountDetails.workPhone === undefined || this.props.dashboard.userAccountDetails.workPhone === "" || this.props.dashboard.userAccountDetails.workPhone === null) ? false : this.state.isDeletePhones.workPhone,
                    homeSeq: (this.state.userAccDetails.home === this.props.dashboard.userAccountDetails.homePhone) ? '' : this.props.dashboard.userAccountDetails.homePhoneSeq,
                    mobileSeq: (this.state.userAccDetails.mobile === this.props.dashboard.userAccountDetails.mobilePhone) ? '' : this.props.dashboard.userAccountDetails.mobilePhoneSeq,
                    workSeq: (this.state.userAccDetails.work === this.props.dashboard.userAccountDetails.workPhone) ? '' : this.props.dashboard.userAccountDetails.workPhoneSeq,
                    oldDateEmail: this.state.userAccDetails.oldDateEmail,
                    oldDateQuestion: this.state.userAccDetails.oldDateQuestion,
                    oldDateAnswer: this.state.userAccDetails.oldDateAnswer,
                    characteristicValue: this.state.userAccDetails.characteristicValue,
                    answer: this.state.userAccDetails.answer
                }
                this.props.updateUserDetails(postData)
                .then((response) => {
                    this.setState({
                        ...this.state,
                        isProcessing: false
                    })
                    if(response.result.status === "True")
                    {
                        this.props.showMessage('success', response.result.description)
                        // window.location.replace("/dashboard")
                    }
                    else{
                        this.props.showMessage('danger', response.result.description)
                        this.setState({
                            ...this.state,
                            isProcessing: false
                        })
                    }
                })
                .catch((error) => {
                    this.props.showMessage('danger', "Server error! Please try again later!")
                    this.setState({
                        ...this.state,
                        isProcessing: false
                    })
                })
            }
        }
        else{
            if (form.checkValidity() === false){ 
                event.preventDefault();
                event.stopPropagation();
                this.setState({
                    ...this.state,
                    validatedChangePass: true
                })
            }
            else{
                this.setState({
                    ...this.state,
                    isProcessing: true
                })
                const postData = {
                    personId: this.state.userAccDetails.personId,
                    oldPassword: this.state.userPassDetails.oldPass,
                    password: this.state.userPassDetails.newPass
                }
                if(!this.state.isNotPword){
                    this.props.updateUserPassword(postData)
                    .then((response) => {
                        this.setState({
                            ...this.state,
                            isProcessing: false
                        }, () => {
                            this.props.showMessage(response.result.status === "True" ? 'success' : 'danger', response.result.description)
                        })
                    })
                    .catch((error) => {
                        this.props.showMessage('danger', "Server error! Please try again later!")
                        this.setState({
                            ...this.state,
                            isProcessing: false
                        })
                    })
                }
                else{
                    this.setState({
                        ...this.state,
                        isProcessing: false
                    })
                }
            }
        }
        
        // this.setState({ validated: true });
    }

    render() { 
        const { validatedEditAcc } = this.state;
        const { validatedChangePass } = this.state;
        const accountId = this.state.userAccDetails.accountId;
        const username = this.state.userAccDetails.username;
        //customer name modification
        String.prototype.replaceAt=function(index, replacement) {
            return this.substr(0, index) + replacement+ this.substr(index + replacement.length);
        }
        var fullName  = this.state.userAccDetails.fullName;
        var firstName = (fullName.substr(0,fullName.indexOf(' ')).trim()).split(" ")[0]
        var lastName  = fullName.substr(fullName.indexOf(' ')+1)
        if(fullName.includes(',') || lastName === ""){
            firstName = (fullName.substr(fullName.indexOf(',')+1).trim()).split(" ")[0]
            lastName  = fullName.substr(0,fullName.indexOf(','))
        }
        //cutomer details Relyant
        const address1 = this.state.userAccDetails.address1;
        const address2 = this.state.userAccDetails.address2;
        const city = this.state.userAccDetails.city;
        const stateDesc = this.state.userAccDetails.stateDesc;
        const stateInitials = this.state.userAccDetails.stateInitials;
        const postal = this.state.userAccDetails.postal;
        const country = this.state.userAccDetails.country;
        const emailAddress = this.state.userAccDetails.emailAddress;
        const home = this.state.userAccDetails.home;
        const work = this.state.userAccDetails.work;
        const mobile = this.state.userAccDetails.mobile;
        const quesCharValue = this.props.dashboard.userOldDataDetails.oldCharValueSecuQues
        const answer = this.state.userAccDetails.answer;
        // for blur textfields
        const blurStyle = {
            color: "transparent",
            textShadow: "0 0 5px rgba(0,0,0,0.5)"
        }
        const errPwordMsg       = this.state.isNotPword === true ? this.state.pwErrMsg+" " : 'Please enter your password.';
        const errPwordInpClass  = this.state.isNotPword === true ? 'invalid form-control' : 'valid form-control';
        const errPwordMsgDisp   = this.state.isNotPword === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';
        const errEmailMsg       = this.state.isEmail    === true ? "Please enter your Email Address. " : "Please enter a valid Email Address.";
        const errEmailInpClass  = this.state.isEmail    === true ? 'valid form-control' : 'invalid form-control';
        const errEmailMsgDisp   = this.state.isEmail    === true ? ' invalid-feedback' : 'invalid_msg invalid-feedback';
        let errMobilePhoneMsgDisp = (!this.state.isInvalidPhones.mobilePhone) ? 'invalid-feedback' : 'invalid_msg invalid-feedback';
        let errHomePhoneMsgDisp = (!this.state.isInvalidPhones.homePhone) ? 'invalid-feedback' : 'invalid_msg invalid-feedback';
        let errWorkPhoneMsgDisp = (!this.state.isInvalidPhones.workPhone) ? 'invalid-feedback' : 'invalid_msg invalid-feedback';
        return (  
            this.state.isEditingPass ?
            <React.Fragment>
                <div className="row pb-4">
                    <div className="col-lg-8">
                        <h3 className="primary-font-color">Password</h3>
                    </div>
                    <div className="col-lg-4">
                        {/* <div className="btn-logout">
                            <a href="/"><p className="regular-font-size font-bold">Log Out <span><img src={process.env.PUBLIC_URL + '/images/logout.png'} alt="logout"/></span></p></a>
                        </div> */}
                    </div>
                </div>
                <div className="edit-form">
                    <Form
                        autocomplete="off"
                        noValidate
                        validated={validatedChangePass}
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>Current Password</Form.Label>
                                <Form.Control
                                    required
                                    autocomplete="off"
                                    type="password"
                                    name="oldPass"
                                    className="valid"
                                    value={this.state.userPassDetails.oldPass}
                                    onChange={this.onChangePassword.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Current Password.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="validationCustom01">
                                <Form.Label>New Password</Form.Label>
                                
                                <Form.Control
                                    required
                                    autocomplete="off"
                                    type="password"
                                    name="newPass"
                                    className={errPwordInpClass}
                                    value={this.state.userPassDetails.newPass}
                                    onChange={this.onChangePassword.bind(this)}
                                    maxlength="15"
                                />
                                <Form.Label><span style={{fontSize: '12px'}}>(Your password must be between 8 – 15 characters long)</span></Form.Label>
                                <Form.Control.Feedback id="feedback" className={errPwordMsgDisp} type="invalid">
                                    {errPwordMsg}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="6" controlId="validationCustom03">
                                <Form.Label>Confirm Password </Form.Label>
                                <Form.Control
                                    required
                                    autocomplete="off"
                                    type="password"
                                    name="confirmPass"
                                    className={errPwordInpClass}
                                    value={this.state.userPassDetails.confirmPass}
                                    onChange={this.onChangePassword.bind(this)}
                                    
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please confirm your New Password.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        <div id="form-actions" className="row">
                            <div className="col-lg-6">
                            </div>
                            <div className="col-lg-6">
                                <div className="row">
                                    <div id="save" className="col-lg-6 order-lg-last">
                                        {
                                            !this.state.isProcessing ? 
                                                <button type="submit" className="btn btn-primary regular-font-size font-bold" disabled={this.state.isProcessing}>Save Changes</button>
                                            :  
                                                <Button variant="primary" disabled>
                                                    <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                    />
                                                    Loading...
                                                </Button>
                                        }
                                    </div>
                                    <div id="cancel" className="col-lg-6 order-lg-first">
                                        <a href="/dashboard" className="dark-font-color regular-font-size" onClick={this.showChangePass.bind(this)}><u>Cancel</u></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </React.Fragment>
            :
                (this.state.isEditLoading) ? 
                <Loading />
                :
                <React.Fragment>
                    <div className="row pb-4">
                        <div className="col-lg-8">
                            <h3 className="primary-font-color">Edit Account</h3>
                        </div>
                        <div className="col-lg-4">
                            {/* <div className="btn-logout">
                                <a href="/"><p className="regular-font-size font-bold">Log Out <span><img src={process.env.PUBLIC_URL + '/images/logout.png'} alt="logout"/></span></p></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="edit-form">
                        <Form
                            autocomplete="off"
                            noValidate
                            validated={validatedEditAcc}
                            onSubmit={e => this.handleSubmit(e)}
                        >
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom01">
                                    {/* <Form.Label>Account Number</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        name="accountId"
                                        className="valid"
                                        value={accountId}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please enter your Account Number.
                                    </Form.Control.Feedback> */}
                                    <Form.Label>Username </Form.Label>
                                    <Form.Control
                                    autocomplete="off"
                                        disabled
                                        type="text"
                                        name="username"
                                        value={username}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        {/* {errUsernameMsg} */}
                                    </Form.Control.Feedback>
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="validationCustom03">
                                    <Form.Label>Customer Name </Form.Label>
                                   <Form.Control
                                       disabled
                                       type="text"
                                       name="firstName"
                                       className="valid"
                                       value={fullName}
                                       autocomplete="off"
                                       onChange={this.onChangeEditAcc.bind(this)}
                                   />
                                   <Form.Control.Feedback id="feedback" type="invalid">
                                       Please enter your First Name.
                                   </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            
                            {
                                this.state.isAllNoPhones ?
                                <div id="contact-info-error-message" className="text-center">
                                    <label className="secondary-font-color red-font font-bold">Please fill atleast 1 contact number!</label>
                                </div> 
                                :
                                ''
                            } 
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="validationCustom12">
                                    <Form.Label>Email Address </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="emailAddress"
                                        value={emailAddress}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                        className={errEmailInpClass}
                                        autocomplete="off"
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid" className={errEmailMsgDisp}>
                                        {errEmailMsg}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="home-phone">
                                    <Form.Label>Home Phone </Form.Label>
                                    <Form.Control
                                        type="text"
                                        autocomplete="off"
                                        className={((this.state.isAllNoPhones) ? 'invalid form-control' : (this.state.isInvalidPhones.homePhone) ? 'invalid form-control' : '')}
                                        name="home"
                                        value={home}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid" className={errHomePhoneMsgDisp}>
                                        {(this.state.isInvalidPhones.homePhone) ? 'Please enter a valid Home Phone.' : 'Please enter Home Phone.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} md="6" controlId="mobile-phone">
                                    <Form.Label>Mobile Phone </Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={((this.state.isAllNoPhones) ? 'invalid form-control' : (this.state.isInvalidPhones.mobilePhone) ? 'invalid form-control' : '')}
                                        name="mobile"
                                        value={mobile}
                                        autocomplete="off"
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid" className={errMobilePhoneMsgDisp}>
                                        {(this.state.isInvalidPhones.mobilePhone) ? 'Please enter a valid Mobile Phone.' : 'Please enter Mobile Phone.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="work-phone">
                                    <Form.Label>Work Phone</Form.Label>
                                    <Form.Control
                                        type="text"
                                        className={((this.state.isAllNoPhones) ? 'invalid form-control' : (this.state.isInvalidPhones.workPhone) ? 'invalid form-control' : '')}
                                        name="work"
                                        value={work}
                                        autocomplete="off"
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid" className={errWorkPhoneMsgDisp}>
                                        {(this.state.isInvalidPhones.workPhone) ? 'Please enter a valid Work Phone.' : 'Please enter Work Phone.'}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="mt-4">
                                <Form.Group as={Col} md="6" controlId="validationCustom13">
                                    <Form.Label>Security Question </Form.Label>
                                    <Form.Control as="select" name="security_question" ref="sec_ques" defaultValue={quesCharValue} onChange={this.questionOnChange.bind(this)}>
                                        { _.map(this.props.dashboard.securityQuestions, (item, index) => {
                                            return(
                                            (item.characteristicValue === quesCharValue) ? 
                                            <option key={index} value={item.characteristicValue}>{item.description}</option>
                                            :
                                            (item.characteristicValue !== quesCharValue) ? 
                                            <option key={index} value={item.characteristicValue}>{item.description}</option>
                                            :
                                            ''
                                            )
                                        })}
                                    </Form.Control>
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please Select Question.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md="6" controlId="validationCustom14">
                                    <Form.Label>Answer </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="answer"
                                        autocomplete="off"
                                        value={answer}
                                        onChange={this.onChangeEditAcc.bind(this)}
                                    />
                                    <Form.Control.Feedback id="feedback" type="invalid">
                                        Please enter Answer.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            <br />
                            <br />
                            <hr />
                                <div className="change-pass">
                                    <div className="float-left">
                                        <h5>Password</h5>
                                    </div>
                                    <div className="float-right">
                                        {/* <a href="/" onClick={this.showChangePass.bind(this)} className="secondary-font-color"><span><img src={process.env.PUBLIC_URL + '/images/edit.png'} alt="edit" /></span> Edit</a> */}
                                        <button className="btn btn-primary regular-font-size font-bold" onClick={this.showChangePass.bind(this)}>Change Password</button>
                                    </div>
                                </div>
                            <hr />
                            <div id="form-actions" className="row">
                                <div className="col-lg-6">
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        <div id="save" className="col-lg-6 order-lg-last">
                                            {
                                                !this.state.isProcessing ? 
                                                    <button type="submit" className="btn btn-primary regular-font-size font-bold" disabled={this.state.isProcessing}>Save Changes</button>
                                                :  
                                                    <Button variant="primary" disabled>
                                                        <Spinner
                                                        as="span"
                                                        animation="border"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                        />
                                                        Loading...
                                                    </Button>
                                            }
                                        </div>
                                        <div id="cancel" className="col-lg-6 order-lg-first">
                                            <a id="btnCancelEditAcc" href="/dashboard" className="dark-font-color regular-font-size"><u>Cancel</u></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, 
{ 
    fetchOldUserDetails, 
    getSequQuestions, 
    getCountry, 
    updateUserDetails, 
    updateUserPassword, 
    showMessage 
})(EditAccount);