import React, { Component } from 'react';

import Header from '../partials/header';
import Footer from '../partials/footer';

class PrivacyPolicy extends Component {
    render() { 
        return (  
            <React.Fragment>
                <Header />
                <section id="privacy_policy">
                    <div className="container">
                        <div id="title" className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <h4 className="primary-font-color text-center">
                                    Guam Power Authority Internet Privacy Policy
                                </h4>
                            </div>
                        </div>
                        <div id="body" className="row">
                            <div className="col-lg-12">
                                <p>
                                    This Privacy Statement applies only to this Site. Other websites that may be affiliated with Guam Power Authority which have other domain names may have different privacy statements. Guam Power Authority respects the privacy of users of this Site and believes that such information should be used responsibly and appropriately. In general, all users may visit this Site anonymously and without revealing any additional personal information. There are times, however, when we may need information from you, such as your name and address. This is usually required in order for us to promptly respond to your requests for information or to answer your questions. Only the people who need it to respond to your communication will use this information. You personal information will not be shared, sold or used for marketing services other than pertaining to this site. We do keep track of the countries and domains from which people visit us, the browser and/or Internet Service Provider used and other related data. We analyze this data for trends and statistics. We may also collect information automatically provided to us by your browser about the type of computer and operating system you are using. In general, we do not use cookies to help identify users. (Cookies are pieces of information that a Web site transfers to an individual's hard drive for record-keeping purposes.) If you have any questions, please contact Guam Power Authority at webmaster1@gpagwa.com. This Privacy Policy does not extend to anything that is inherent in the operation of the Internet, and beyond the control of Guam Power Authority, and is not to be applied in any manner contrary to applicable laws, rules or regulations.    
                                </p>
                            </div>
                            <div className="col-lg-12 text-center mb-2 mt-5">
                                <button type="submit" className="btn btn-primary font-bold">Back to Homepage</button>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;