import React, { Component } from 'react';
import BtnGoBack from './btnGoBackPaymentRes';
import $ from 'jquery'
class PaymentServerFailed extends Component {
    state = { 
        showLogo: false
    }
    getFieldValue = (str, result) => {
        return this.props.searchString(str, result)
    }
    downloadBillPDF = (e) => {
        e.preventDefault();
        var newWindow = window.open();
        document.getElementById('pdf-logo').style.display = 'block';
        const jsPDF = require('jspdf');
        const result = this.props.result;
        const receiptNum = 'failed_transaction';
        var pdf = new jsPDF('p','px','a4');
        var source = $("#receipt")[0];
        this.setState({
            showLogo: true
        }, () => {
            pdf.addHTML(source,function() {

                pdf.save('PayGWA_'+receiptNum+'.pdf')
                // const screenWidth = window.screen.width
                // if(screenWidth < 1200){
                //     document.getElementById("btn-my-bills").click();
                    
                // }
                document.getElementById('pdf-logo').style.display = 'none';
            });
            
        })
        setTimeout(function() {
			newWindow.close();
		}, 50);
    }
    render() { 
        return ( 
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center">
                            <h3><span style={{color: '#c40808'}}><i class="fas fa-times-circle"></i></span> Sorry, your payment was not processed.</h3>
                        </div>
                    </div>
                </div>
                <div id="receipt" className="p-5">
                    <div id="pdf-logo" className="row mb-3" style={{display: "none"}}>
                        <div className="col-lg-12 text-center">
                            <img src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <label className="font-bold">Please try again later.</label>
                                <label className="font-bold">If you repeatedly see this message, complete your payment by contacting GWA directly.</label>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="row mb-5">
                    <div className="col-lg-6 text-right">
                        <button id="btn-download" type="submit" class="btn regular-font-size font-bold btn-left" onClick={this.downloadBillPDF.bind(this)}>Save this as PDF file</button>
                    </div>
                    <div className="col-lg-6 text-left">
                        <BtnGoBack />
                    </div>
                </div>
            </div>
        );
    }
}
 
export default PaymentServerFailed;