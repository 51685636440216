import React, { Component } from 'react';
import Header from '../partials/header';
class Error404Page extends Component {
    state = {  }
    render() { 
        return ( 
            <React.Fragment>
                <Header />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="text-wrap">
                                    <h1 className="secondary-font-color">Page Not Found</h1>
                                    <label>We can’t fint the page you’re looking for. You can either return to the previous page, cisit our homepage or contact our support team.</label>
                                </div>
                                <div className="button-wrap">
                                   
                                </div>
                            </div>
                            <div className="col-lg-6 text-center">
                                <div className="img-wrap">
                                    <img src={process.env.PUBLIC_URL + '/images/404-banner.png'} alt="404-banner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
 
export default Error404Page;