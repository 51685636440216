import React, { Component } from 'react';
import { Navbar,Nav } from 'react-bootstrap';
import Survey from '../components/survey';
import News from '../components/news';
import HelpAndSupport from '../components/helpAndSupport';
import AccountOverview from '../components/accountOverview';
import MultipleAccView from '../components/multipleAccountView';
import MyBills from '../components/myBills';
import $ from 'jquery';
import { connect } from 'react-redux';
import { 
    saveAccountId,
    savePremiseAddress,
    fetchBillsList,
    fetchMultipleAddOpptyRequest
} from '../actions/dashboardActions';
import Loading from '../components/loading';
import * as moment from 'moment';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            child: '',
            accountId: '',
            viewAccountInfo: false,
            isMultipleAccount: false,
            currentYear: moment().format('YYYY')
        };
    }
    
    componentWillMount(){
        this.userInactivity()
        //session variable search
        let sessionAccountId;
        let lsAccountId  = JSON.parse(localStorage.getItem("accountId"))
        let lsAccountIds = JSON.parse(localStorage.getItem("accountIds"))
        //checking if single account
        if(!(lsAccountId === '' || lsAccountId === null || lsAccountId === undefined)){
            sessionAccountId = lsAccountId
        }
        else if(!(lsAccountIds === '' || lsAccountIds === null || lsAccountIds === undefined)){
            sessionAccountId = lsAccountIds;
        }
        if(sessionAccountId === "" || sessionAccountId === null || sessionAccountId === undefined){
            window.location.replace(localStorage.getItem('baseUrl'));
        }
        else{
            this.setState({
                isMultipleAccount: sessionAccountId.length > 1 ? true : false
            }, () => {
                this.props.saveAccountId(sessionAccountId[0][0])   
                .then(() => {
                    this.props.savePremiseAddress(sessionAccountId[0][1])
                    .then(() => {
                        this.setState({
                            ...this.state,
                            child: MultipleAccView
                        })
                    })
                })
            })
        }
    }
    userInactivity() {
        var time = new Date().getTime();
        $(document.body).bind("mousemove keypress", function(e) {
            time = new Date().getTime();
        });
        const minute = 60000
        function refresh() {
            if(new Date().getTime() - time >= (minute*15)) {
                localStorage.clear()
                localStorage.setItem("forcedLogout",true)
                window.location.replace("/");
            }
            else {
                setTimeout(refresh, 10000);
            } 
        }
        setTimeout(refresh, 10000);
    }
    showAccountOverview = e => {
        e.preventDefault();
        if($('#btnCancelEditAcc').length){
            window.location.reload();
        }
        else{
            // if(this.state.isMultipleAccount){
                this.setState({ child: MultipleAccView, viewAccountInfo: false })
            // }
            // else{
            //     this.setState({ child: AccountOverview, viewAccountInfo: false })
            // }
        }
    }

    showAccountInfo = e => {
        e.preventDefault()
        this.setState({ child: '', viewAccountInfo: true }, () => {
            this.setState({ child: AccountOverview, viewAccountInfo: true })
        })
    }

    showMyBills = e => {
        e.preventDefault()
        this.setState({ child: ''}, () => {
            const accountId = this.props.dashboard.selectedAccountId
            this.props.fetchBillsList(accountId)
            .then(() => {
                this.setState({ child: MyBills, viewAccountInfo: false })
            })
            .catch((error)=>{
                if(!alert(error+'\n\nTry Again Later!')){
                    window.location.reload();
                }
            })
        })
    }

    showSurvey = e => {
        e.preventDefault()
        this.setState({ child: Survey, viewAccountInfo: false })
    }

    showNews = e => {
        e.preventDefault()
        this.setState({ child: News, viewAccountInfo: false })
    }

    showHelpAndSupport = e => {
        e.preventDefault()
        if(this.props.dashboard.userAccountDetails.length != 0){
            this.setState({ child: HelpAndSupport, viewAccountInfo: false })
        }
        
    }

    logoutOnClick = (e) => {
        e.preventDefault();
        localStorage.clear();
        window.location.replace("/");
    }

    faqOnClick = (e) => {
        e.preventDefault();
        window.location.replace("/faq");
    }
    render() { 
        const divStyleRight = {
            backgroundColor: 'white'
        };
        return (  
            <section id="dashboard">
                <div className="container-fluid p-0">
                    <div id="nav-desktop">
                        <div className="row secondary-back-color">
                            <div className="col-lg-12 text-right">
                                <div className="nav-links">
                                    <a href="/#" onClick={this.showSurvey.bind(this)}><p>Survey</p></a>
                                    {/* <a href="/#" onClick={this.showNews.bind(this)}><p>News</p></a> */}
                                    <a href="/#" onClick={this.showHelpAndSupport.bind(this)}><p>Help & Support</p></a>
                                </div>
                            </div>
                        </div>
                        <div className="row primary-back-color">
                            <div className="col-lg-12 nav-main">
                                <div className="nav-logo">
                                    <img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img>
                                </div>
                                <div className="nav-buttons">
                                    <a href="/" className="dark-font-color font-bold" onClick={this.showAccountOverview.bind(this)}><span><img className="account_logo" src={process.env.PUBLIC_URL + '/images/account-overview.png'} alt="account-logo"></img></span>Account Summary</a>
                                    {
                                        // !(this.state.isMultipleAccount) ? 
                                        // <React.Fragment> 
                                        //     <a href="/" className="dark-font-color font-bold" onClick={this.showAccountInfo.bind(this)}><span><img className="account_logo" src={process.env.PUBLIC_URL + '/images/info-button.png'} alt="account-info-logo"></img></span>Account Information</a>
                                        //     <a id="btn-my-bills" href="/" className="dark-font-color font-bold" onClick={this.showMyBills.bind(this)}><span><img className="account_logo" src={process.env.PUBLIC_URL + '/images/my-bills.png'} alt="mybills-logo"></img></span>My Bills</a>
                                        // </React.Fragment>
                                        // :
                                        // ""
                                    }
                                    <a href="/" className="dark-font-color font-bold" onClick={this.faqOnClick.bind(this)}>FAQ</a>
                                    <a href="/" className="logout font-bold pr-4" onClick={this.logoutOnClick.bind(this)}>Log Out <span><img src={process.env.PUBLIC_URL + '/images/dashboard-logout.png'} alt="logout"/></span></a>
                                </div>
                            </div>
                        </div> 
                        <hr />
                    </div>
                    <Navbar id="nav-mobile" bg="light" expand="lg">
                        <Navbar.Brand href="#home"><img className="gpa_logo" src={process.env.PUBLIC_URL + '/images/GPA-Logo.png'} alt="gpa-logo"></img></Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mr-auto">
                                <a href="/" className="dark-font-color font-bold" onClick={this.showAccountOverview.bind(this)}>Account Summary</a>
                                {/* <a href="/" className="dark-font-color font-bold" onClick={this.showAccountInfo.bind(this)}>Account Information</a>
                                <a href="/" className="dark-font-color font-bold" onClick={this.showMyBills.bind(this)}>My Bills</a> */}
                                <a href="/" className="dark-font-color font-bold" onClick={this.showSurvey.bind(this)}>Survey</a>
                                {/* <a href="/" className="dark-font-color font-bold" onClick={this.showNews.bind(this)}>News</a> */}
                                <a href="/" className="dark-font-color font-bold" onClick={this.showHelpAndSupport.bind(this)}>Help & Support</a>
                                <a href="/" className="dark-font-color font-bold" onClick={this.faqOnClick.bind(this)}>FAQ</a>
                                <hr/>
                                <a href="/" className="dark-font-color font-bold" onClick={this.logoutOnClick.bind(this)}>Log Out</a>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    <br/>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 p-0" style={divStyleRight}>
                            <div className="ml-0">
                                <div className="main">
                                    {(this.state.child !== '') ? React.createElement(this.state.child,{viewAccountInfo: this.state.viewAccountInfo},null)
                                    : 
                                    <Loading />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    {(this.state.child !== '') ? 
                    <footer id="footer-main">
                        <hr />
                        <div className="text-center ">
                            <p className="m-0">All Rights Reserved © {this.state.currentYear} Guam Waterworks Authority</p>
                        </div>
                        {/* <div className="text-center float-right pr-5 pb-2">
                            <img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img>
                            <img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks.png"></img>
                            <img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img>
                            <img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu.png"></img>
                        </div> */}
                    </footer>
                    : 
                    <Loading />}
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    users:     state.users,
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    saveAccountId,
    savePremiseAddress,
    fetchBillsList,
    fetchMultipleAddOpptyRequest
})(Dashboard);