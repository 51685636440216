import React, { Component } from 'react';
import _ from 'lodash';

class BtnViewBill extends Component {
    constructor(props){
        super(props);
        this.state = {
            isViewBill: false,
            isLoading: true,
            show: false
        }
    }
    viewBill = (billID) => {
        // localStorage.setItem("invoiceNumber", billID)
        window.open(
            `/view-bill?id=${Date.now()}&user=${this.props.personId}&bill-id=${billID}`,
            '_blank'
        );
    }
    hideModal = (e) => {
        this.setState({ show: false });
    }
    render() { 
        return ( 
            <React.Fragment>
                        
               
                {/* {
                    this.state.show ?
                    <div>
                        {
                            this.state.isLoading === true ?
                            <Loading />
                            :
                            <ViewBill isModalMode={true}/>
                        }
                    </div>
                    :
                    ""
                }   */}
                <td className="secondary-font-color font-bold regular-font-size" onClick={() => this.viewBill(this.props.billId)}>
                    <p><img src={process.env.PUBLIC_URL + '/images/sticky-note-regular.svg'} width="16px"/></p>
                </td>
            </React.Fragment>
        );
    }
}
 
export default BtnViewBill;