import React, { Component } from 'react';
class BtnGoBack extends Component {
    state = {  }

    render() { 
        return ( 
            <button id="btn-member-dashboard" type="submit" class="btn regular-font-size font-bold btn-right" onClick={() => { localStorage.getItem("isAdminLogin") === "true" ? window.location.replace("/admin-dashboard") : localStorage.getItem("paynow") === "true" ? window.location.replace("/paynow") : window.location.replace("/dashboard")}}>Go Back</button>
        );
    }
}
export default BtnGoBack;