import React, { Component } from 'react';
import Loading from '../components/loading';
import ViewBill from '../components/viewBill';
import { connect } from 'react-redux';
import { 
    getViewBillData,
    fetchMultipleAddOpptyRequest
} from '../actions/dashboardActions';
import { 
    showMessage
} from '../actions/popupMessageActions';
import $ from 'jquery';

class ViewBill2 extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: true,
            isError: false
        }
    }
    componentWillMount() {
        var time = new Date().getTime();
        $(document.body).bind("mousemove keypress", function(e) {
            time = new Date().getTime();
        });
        const minute = 60000
        function refresh() {
            if(new Date().getTime() - time >= (minute*15)) {
                localStorage.clear()
                localStorage.setItem("forcedLogout",true)
                window.location.replace("/");
            }
            else {
                setTimeout(refresh, 10000);
            } 
        }
        setTimeout(refresh, 10000);

        // const billId = localStorage.getItem('invoiceNumber')
        // const arrayURL = window.location.href.split("/");
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const billId = urlParams.get('bill-id');
        const personId = urlParams.get('user');
        const date = urlParams.get('id');
        const frm = urlParams.get('frm');

        const dateDiff = Math.abs(new Date() - new Date(parseInt(date)));

        // localStorage.removeItem('invoiceNumber')
        if(billId === "" || billId === null || billId === undefined){
            this.props.showMessage('danger', "Sorry, the link has expired.");
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true
            })
        }
        else if(personId === "" || personId === null || personId === undefined ){
            this.props.showMessage('danger', "Sorry, the link has expired.");
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true
            })
        }
        else if(date === "" || date === null || date === undefined){
            this.props.showMessage('danger', "Sorry, the link has expired.");
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true
            })
        }
        else if(dateDiff > 900000){
            this.props.showMessage('danger', "Sorry, the link has expired.");
            this.setState({
                ...this.state,
                isLoading: false,
                isError: true
            })
        }
        else if(frm != "m" && (personId != localStorage.getItem('personId') || personId != localStorage.getItem('personId') || personId != localStorage.getItem('personId'))){
            
            if(localStorage.getItem('personId') == null || localStorage.getItem('personId') == ''){
                this.props.showMessage('danger', "Action not allowed!");
                this.setState({
                    ...this.state,
                    isLoading: false,
                    isError: true
                })
            }else{
                this.props.showMessage('danger', "Action not allowed!");
                this.setState({
                    ...this.state,
                    isLoading: false,
                    isError: true
                })
            }
        }
        else{
            this.props.getViewBillData(billId)
            .then(() => {
                const urlParams = new URLSearchParams(queryString);
                const frm = urlParams.get('frm');
                console.log("safsaf frm", frm)
                const accountId = this.props.dashboard.viewBillData.result.billInfo1.acctID.toString();
                //console.log("accountId",accountId);
                let lsAccountId  = JSON.parse(localStorage.getItem("accountId"));
                let lsAccountIds = JSON.parse(localStorage.getItem("accountIds"));
                let isValidAcctIdArr = [];

                if(frm != "m"){
                    if(!(lsAccountId === '' || lsAccountId === null || lsAccountId === undefined)){
                        console.log(lsAccountId)
                        isValidAcctIdArr = lsAccountId.filter((item, index) => item[0] == accountId);
                    }
                    else if(!(lsAccountIds === '' || lsAccountIds === null || lsAccountIds === undefined)){
                        console.log("lsAccountIds",lsAccountIds)
                        isValidAcctIdArr = lsAccountIds.filter((item, index) => item[0] == accountId);
                    }else{
                        this.props.showMessage('danger', "Link Expired!");
                        this.setState({
                            ...this.state,
                            isLoading: false,
                            isError: true
                        })
                    }
                }
                
                console.log(isValidAcctIdArr);
             
                if(frm != "m" && isValidAcctIdArr.length == 0){
                    this.props.showMessage('danger', "Link Expired!");
                    this.setState({
                        ...this.state,
                        isLoading: false,
                        isError: true
                    })
                }else{
                    this.props.fetchMultipleAddOpptyRequest(accountId, personId)
                    .then((status) => {
                        if(status.isHaveConsumptionChart == 'True'){
                            this.setState({
                                ...this.state,
                                isLoading: false,
                            })
                            console.log('this.props.dashboard.consumptionDetails', this.props.dashboard.consumptionDetails)
                        }
                        else{
                            alert('No consumption data found!');
                            window.close();
                        }
                    })
                }
            })
            .catch(() => {
                alert('Server Error!');
                window.close();
            })
        }
    }
    render() { 
        return (  
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div id="bill-form-container" className="mt-5 mb-5">
                                {
                                    this.state.isLoading === true ?
                                    <Loading />
                                    :
                                    this.state.isError ? 
                                    null
                                    :
                                    <ViewBill isModalMode={true}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    getViewBillData,  
    fetchMultipleAddOpptyRequest,
    showMessage
})(ViewBill2);
