import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { saveFormConfig } from "../actions/paynowActions";
import {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
  savePaymentData,
  validateVisaPayment,
} from "../actions/dashboardActions";
import { showMessage } from "../actions/popupMessageActions";
import { Form, Col, InputGroup, FormControl, Modal } from "react-bootstrap";
import $ from "jquery";
import * as moment from "moment";
import ResultMessage from "./resultMessage";
import DatePicker from "react-datepicker";
import NumberFormat from "react-number-format";
import CurrencyInput from "react-currency-masked-input";

import "./paynowStep3.css";

class PaynowStep3 extends Component {
  constructor() {
    super();
    this.emailInputRef = React.createRef();

    this.state = {
      showCurrencyInput: false,
      showHint: false,
      showResult: true,
      resultMsgTitle: "test",
      resultMsgBody: "test",
      validated: false,
      amountToBePaid: "",
      accountSummary: "",
      subtotal: "",
      expDateForm: "",
      showErrMsg: false,
      isPaymentProcessing: false,
      username: "",
      userFullName: "",
      cardDetails: {
        cardHolderName: "",
        cardNumber: "",
        expDate: new Date(),
        validExpDate: "",
        cvv: "",
        billingZipCode: "",
        confirmationEmail: "",
      },
      isVisaChecked: "",
      isMasterCardChecked: "",
      mode: "",
      usedCC: "visa",
      edited: false,
    };
  }
  componentWillMount() {
    this.props.saveFormConfig({ btnSubmitValue: "PAY NOW" });
    var _ = this;
    $("#submit").click(function () {
      _.handleSubmit();
    });
    this.setState(
      {
        ...this.state,
        accountSummary: this.props.dashboard.orderData.accountSummary,
        amountToBePaid:
          this.props.dashboard.orderData.accountSummary[0].amount < 0
            ? 0.0
            : this.props.dashboard.orderData.accountSummary[0].amount,
        subtotal: this.props.dashboard.orderData.subTotal,
        username: this.props.paynow.validateZipCodeRes.result.details.Name,
        userFullName: this.props.paynow.validateZipCodeRes.result.details.Name,
        cardDetails: {
          ...this.state.cardDetails,
          confirmationEmail:
            this.props.paynow.validateZipCodeRes.result.details.EmailID,
        },
      },
      () => {}
    );
  }
  saveOrderData = () => {
    const postData = {
      subTotal: this.state.subtotal,
      accountSummary: this.state.accountSummary,
      isHasInvalid: false,
    };
    this.props.saveOrderData(postData);
  };
  handleExpDate = (date) => {
    this.setState({
      ...this.state,
      expDateForm: date,
      cardDetails: {
        ...this.state.cardDetails,
        validExpDate: moment(date).format("MMYY"),
      },
    });
  };

  updateSubTotal = () => {
    let subtotal = 0;
    const arrAccountSummary = this.state.accountSummary;
    for (let count = 0; count < arrAccountSummary.length; count++) {
      subtotal = subtotal + parseFloat(arrAccountSummary[count].amount);
    }
    console.log("subtotal", subtotal);
    const amountToBePaid = this.state.amountToBePaid[0].amountToBePaid;
    this.setState(
      {
        subtotal: this.state.edited ? amountToBePaid : subtotal,
      },
      () => {
        this.saveOrderData();
      }
    );
  };
  formatAmount = (value) => {
    value = value.replace("$ ", ""); //removes '$ '
    value = value.replace(/\,/g, ""); //removes all ','
    return (value = parseFloat(Math.round(value * 100) / 100).toFixed(2));
  };
  amountToBePaidOnChange = (e) => {
    let id = e.target.id,
      value = e.target.value;
    value = value.replace("$ ", ""); //removes '$ '
    value = value.replace(".", ""); //removes '$ '
    value = value.replace(/\,/g, ""); //removes all ','
    value =
      value == 1
        ? "0.01"
        : value.slice(0, -3) == "00"
        ? ".0" + value.slice(-2)
        : Number(value.slice(0, -2)) + "." + value.slice(-2);
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "";
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if (arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    arrAccountSummary[selectedIndex].amountToBePaid =
      value === "" ? 0.0 : value;
    arrAccountSummary[selectedIndex].checked = true;
    this.setState(
      {
        ...this.state,
        amountToBePaid: arrAccountSummary,
        accountSummary: arrAccountSummary,
        edited: true,
      },
      () => {
        this.updateSubTotal();
      }
    );
  };

  amountToBePaidDefault = (accountSummary) => {
    let id = accountSummary.accID,
      value = accountSummary.amount;
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "";
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if (arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    arrAccountSummary[selectedIndex].amountToBePaid =
      value === "" ? 0.0 : value;
    arrAccountSummary[selectedIndex].checked = true;
    this.setState(
      {
        ...this.state,
        amountToBePaid: arrAccountSummary,
        accountSummary: arrAccountSummary,
      },
      () => {
        this.updateSubTotal();
      }
    );
  };

  async validateAmount() {
    // e.preventDefault()
    const accountSummary = this.props.dashboard.orderData.accountSummary;
    const subtotal = this.props.dashboard.orderData.subTotal;
    const usedCC = this.checkedUsedCC(this.state.cardDetails.cardNumber);
    let arrIsAmountValid = [];
    if (
      this.state.cardDetails.cardNumber.charAt(0) === "4" ||
      this.state.cardDetails.cardNumber.charAt(0) === "6"
    ) {
      // try{
      //     let result = await Promise.all([this.props.validateVisaPayment(accountSummary[0].accID, usedCC)])
      //     let paymentAmount = result[0].data.result.data.PaymentAmount
      //     let className = result[0].data.result.data.CustomerClass
      //     const totalPayment = parseInt(accountSummary[0].amountToBePaid) + parseInt(paymentAmount)
      //     if(className != "RESID"){
      //         if(totalPayment > 500){
      //             accountSummary[0].validAmountToBePaid = false
      //             accountSummary[0].alreadyPaid = paymentAmount
      //             arrIsAmountValid.push(false)
      //         }
      //         else{
      //             accountSummary[0].validAmountToBePaid = true
      //             arrIsAmountValid.push(true)
      //         }
      //     }
      // }
      // catch(error){
      //     let result = {
      //         status: "serverFailed"
      //     }
      //     localStorage.setItem('accountSummary', JSON.stringify(this.state.accountSummary))
      //     localStorage.setItem('paymentResult', JSON.stringify(result))
      //     this.setState({
      //         isPaymentProcessing: false
      //     })
      //     this.newTabOpen();
      // }
      for (let count = 0; count < accountSummary.length; count++) {
        accountSummary[count].validAmountToBePaid = true;
      }
      arrIsAmountValid.push(true);
    } else {
      let postData = {};
      for (let count = 0; count < accountSummary.length; count++) {
        accountSummary[count].validAmountToBePaid = true;
      }
      arrIsAmountValid.push(true);
    }
    const postData = {
      subTotal: parseFloat(Math.round(subtotal * 100) / 100).toFixed(2),
      accountSummary: accountSummary,
    };
    if (arrIsAmountValid.includes(false)) {
      this.props.saveOrderData(postData);
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
      const accountSummary = this.state.accountSummary;
      let message =
        "Payment has exceeded the monthly maximum of $500 for non-residential accounts using " +
        (usedCC === "visa" ? "Visa" : "Discover") +
        " Card. ";
      if (500 - parseInt(accountSummary[0].alreadyPaid) > 0) {
        message +=
          "You can only pay up to " +
          ("$ " + (500 - parseInt(accountSummary[0].alreadyPaid))) +
          " for now using a " +
          (usedCC === "visa" ? "VISA" : "DISCOVER") +
          ", or you may complete your payment using Mastercard or " +
          (usedCC === "visa" ? "Discover card." : "Visa card.");
      } else {
        message +=
          "Alternatively, you may complete your payment using a Mastercard or " +
          (usedCC === "visa" ? "Discover card." : "Visa card.");
      }
      this.props.showMessage("danger", message);
    } else {
      this.props.saveOrderData(postData);
      this.validUserInputs(subtotal, accountSummary);
    }
  }

  async handleSubmit() {
    const process = () => {
      this.setState(
        {
          validated: true,
          isPaymentProcessing: true,
        },
        () => {
          const _ = this.state;
          if (
            _.subtotal > 0 &&
            _.cardDetails.cardHolderName != "" &&
            _.cardDetails.cardNumber != "" &&
            _.cardDetails.expDate != "" &&
            _.cardDetails.validExpDate != "" &&
            _.cardDetails.cvv != ""
          ) {
            this.validateAmount();
            this.props.saveFormConfig({ btnSubmitValue: "Please wait..." });
          } else {
            this.setState(
              {
                validated: true,
                isPaymentProcessing: false,
              },
              () => {
                this.props.saveFormConfig({ btnSubmitValue: "Continue" });
              }
            );
          }
        }
      );
    };
    if (!this.state.isPaymentProcessing) {
      const arrAccountSummary = this.state.accountSummary;
      this.updateSubTotal();
      arrAccountSummary[0].checked = true;
      console.log("arrAccountSummary", arrAccountSummary[0]);
      if (!this.state.edited) {
        this.amountToBePaidDefault(arrAccountSummary[0]);
        process();
      } else {
        process();
      }
    }
  }

  checkedUsedCC = (cardNumber) => {
    const usedCC =
      cardNumber.charAt(0) === "4"
        ? "visa"
        : cardNumber.charAt(0) === "6"
        ? "discover"
        : parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) > 50 &&
          parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) < 56
        ? "master"
        : "invalid";
    this.setState({ usedCC: usedCC });
    return usedCC;
  };
  validUserInputs(subtotal, accountSummary) {
    const cardNumber = this.state.cardDetails.cardNumber;
    const usedCC = this.checkedUsedCC(cardNumber);
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let expDate = new Date(this.state.cardDetails.expDate);
    expDate.setHours(0, 0, 0, 0);
    if (usedCC === "invalid") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage("danger", "Invalid Card Number Format!");
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
    } else if (this.state.isVisaChecked && usedCC != "visa") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Visa Card Number!"
      );
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
    } else if (this.state.isMasterCardChecked && usedCC != "master") {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Mastercard Number!"
      );
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "visa"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Visa Card Number!"
      );
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "discover"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Dicover Card Number!"
      );
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
    } else if (
      (this.state.cardDetails.cardNumber.length < 16 ||
        this.state.cardDetails.cardNumber.length > 16) &&
      usedCC === "master"
    ) {
      this.setState({
        isPaymentProcessing: false,
      });
      this.props.showMessage(
        "danger",
        "Please enter a valid Mastercard Number!"
      );
      this.props.saveFormConfig({ btnSubmitValue: "Continue" });
    } else if (this.state.cardDetails.confirmationEmail != "") {
      let email = String(this.state.cardDetails.confirmationEmail);
      // eslint-disable-next-line
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        this.setState(
          {
            subtotal: subtotal,
            accountSummary: accountSummary,
          },
          () => {
            this.executeRequests();
          }
        );
      } else {
        this.setState({
          isPaymentProcessing: false,
        });
        this.props.showMessage("danger", "Invalid email address!");
        this.props.saveFormConfig({ btnSubmitValue: "Continue" });
      }
    } else {
      this.setState(
        {
          subtotal: subtotal,
          accountSummary: accountSummary,
        },
        () => {
          this.executeRequests();
        }
      );
    }
  }
  newTabOpen = () => {
    // let payResult = window.open();
    // payResult.location = "/payment-result"
    window.location.replace("/payment-result");
  };
  executeRequests() {
    localStorage.setItem("reload", "false");
    console.log("PAYMENT", this.state);
    this.props
      .savePaymentData(this.state)
      .then((result) => {
        const { saveToPayEezyStatus, saveAdminReportsStatus } = result;
        if (saveToPayEezyStatus.result.status == "False") {
          this.setState({
            isPaymentProcessing: false,
          });
          this.props.showMessage("danger", saveToPayEezyStatus.result.data);
          this.props.saveFormConfig({ btnSubmitValue: "Continue" });
        } else if (
          saveToPayEezyStatus === false ||
          saveAdminReportsStatus === false
        ) {
          let result = {
            data: {
              Transaction_Approved: "serverFailed",
            },
          };
          localStorage.setItem(
            "accountSummary",
            JSON.stringify(this.state.accountSummary)
          );
          localStorage.setItem("paymentResult", JSON.stringify(result));
          this.newTabOpen();
          this.props.saveFormConfig({ btnSubmitValue: "Continue" });
        } else {
          localStorage.setItem(
            "accountSummary",
            JSON.stringify(this.props.dashboard.orderData.accountSummary)
          );
          localStorage.setItem(
            "paymentResult",
            JSON.stringify(saveToPayEezyStatus.result)
          );
          this.newTabOpen();
          this.props.saveFormConfig({ btnSubmitValue: "Continue" });
        }
      })
      .catch((error) => {
        let result = {
          data: {
            Transaction_Approved: "serverFailed",
          },
        };
        localStorage.setItem(
          "accountSummary",
          JSON.stringify(this.state.accountSummary)
        );
        localStorage.setItem("paymentResult", JSON.stringify(result));
        this.props.saveFormConfig({ btnSubmitValue: "Continue" });
        this.newTabOpen();
      });
  }
  inputOnChange = (e) => {
    if (e.target.name === "expDate") {
      this.setState({
        ...this.state,
        cardDetails: {
          ...this.state.cardDetails,
          [e.target.name]: e.target.value,
          validExpDate:
            e.target.value.split("-")[1] +
            e.target.value.split("-")[0].charAt(2) +
            e.target.value.split("-")[0].charAt(3),
        },
      });
    } else if (e.target.name === "cardNumber") {
      let value = e.target.value.replace(/\ /g, "");
      value = value.replace(/\_/g, "");
      this.setState({
        ...this.state,
        cardDetails: {
          ...this.state.cardDetails,
          cardNumber: value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        cardDetails: {
          ...this.state.cardDetails,
          [e.target.name]: e.target.value,
        },
      });
    }
  };
  showCvvHint = (e) => {
    this.setState({ showHint: true });
  };
  hideCvvHint = (e) => {
    this.setState({ showHint: false });
  };
  showCardType = (cardNumber) => {
    try {
      if (cardNumber.charAt(0) === "4") {
        return "card-visa-logo";
      } else if (cardNumber.charAt(0) === "6") {
        return "card-discover-logo";
      } else if (
        parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) > 50 &&
        parseInt(cardNumber.charAt(0) + "" + cardNumber.charAt(1)) < 56
          ? true
          : false
      ) {
        return "card-master-logo";
      } else if (cardNumber === "") {
        return "";
      } else {
        return "card-undefined-logo";
      }
    } catch (error) {}
  };
  confirmationEmailHint = (e) => {
    this.props.showMessage(
      "",
      "Enter email address to which the receipt will be sent to, otherwise, leave it blank."
    );
  };
  maskString = (value) => {
    let str = value;
    let count = 0;
    let new_str = "";
    for (let i = 0; i < str.length; i++) {
      if (count > 1) {
        if (str.charAt(i) != " ") {
          new_str = new_str + "*";
          count = count + 1;
        } else {
          new_str = new_str + " ";
          count = 0;
        }
      } else if (count < 2) {
        if (str.charAt(i) != " ") {
          new_str = new_str + str.charAt(i);
          count = count + 1;
        } else {
          new_str = new_str + " ";
          count = 0;
        }
      }
    }
    return new_str;
  };
  handleClick = () => {
    console.log("this is on focus");
    this.setState({ showCurrencyInput: true });
    const input = document.getElementById(
      this.props.paynow.step1Data.accountId
    );
    input.focus();
  };
  render() {
    const { validated } = this.state;

    let fullName = this.props.paynow.validateZipCodeRes.result.details.Name;
    const finalFullName = this.maskString(fullName);

    const address1 =
      this.props.paynow.validateZipCodeRes.result.details.Address;
    const finalAdd1 = this.maskString(address1);

    const isAmountValidated =
      this.state.validated && this.state.subtotal === 0 ? true : false;
    return (
      <React.Fragment>
        <Modal
          id="cvv-hint"
          show={this.state.showHint}
          onHide={this.hideCvvHint.bind(this)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pl-0">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-6">
                  <img
                    src={process.env.PUBLIC_URL + "/images/cvv-hint.png"}
                    alt="cvv-hint"
                  />
                </div>
                <div className="col-lg-6">
                  <div className="hint-content">
                    <h5>CVV Code</h5>
                    <p>
                      The CVV Code is a security code and is the last 3-digits
                      printed on the signature panel on the back of your credit
                      card.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Form
          autocomplete="off"
          noValidate
          validated={validated}
          onSubmit={(e) => this.handleSubmit(e)}
        >
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              {console.log(
                "ito yung original amount: ",
                this.props.dashboard.orderData.accountSummary[0].amount
              )}
              <Form.Label className="font-bold form-label">
                Amount to be paid
              </Form.Label>
              <InputGroup>
                {this.state.showCurrencyInput ? (
                  <>
                    <span id="dollarSign">$</span>
                    <CurrencyInput
                      style={{ paddingLeft: 20 }}
                      id={this.props.paynow.step1Data.accountId}
                      className={
                        isAmountValidated
                          ? "invalid form-control currencyInputsss"
                          : "form-control currencyInputsss"
                      }
                      name="amountToBePaid"
                      onChange={this.amountToBePaidOnChange.bind(this)}
                      prefix="$ "
                    />
                  </>
                ) : (
                  <NumberFormat
                    id={this.props.paynow.step1Data.accountId}
                    className={"form-control"}
                    name="amountToBePaid"
                    value={parseFloat(
                      this.props.dashboard.orderData.accountSummary[0].amount <
                        0
                        ? 0.0
                        : this.props.dashboard.orderData.accountSummary[0]
                            .amount
                    )}
                    // value={parseFloat(item.amountToBePaid)}
                    thousandSeparator={true}
                    prefix={"$ "}
                    // onChange={this.amountToBePaidOnChange.bind(this)}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    allowNegative={false}
                    required
                    onFocus={(e) => {
                      this.handleClick();
                    }}
                  />
                )}

                <Form.Control.Feedback
                  id="feedback"
                  type="invalid"
                  style={{ display: isAmountValidated ? "block" : "none" }}
                >
                  Please enter Amount.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label className="font-bold">Card Holder Name</Form.Label>
              <Form.Control
                required
                autocomplete="off"
                id="cardHolderName"
                type="text"
                name="cardHolderName"
                onChange={this.inputOnChange.bind(this)}
              />
              <Form.Control.Feedback id="feedback" type="invalid">
                Please enter Card Holder Name.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label className="font-bold">Card Number</Form.Label>
              <InputGroup>
                <NumberFormat
                  id="cardNumber"
                  name="cardNumber"
                  className="form-control"
                  onChange={this.inputOnChange.bind(this)}
                  aria-describedby={this.showCardType(
                    this.state.cardDetails.cardNumber
                  )}
                  required
                  autocomplete="off"
                  format="#### #### #### ####"
                  mask="_"
                />
                <InputGroup.Append>
                  <InputGroup.Text
                    id={this.showCardType(this.state.cardDetails.cardNumber)}
                    style={{ padding: "0", border: "0" }}
                  ></InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback id="feedback" type="invalid">
                  Please enter Card Number.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label className="font-bold">Expiration Date</Form.Label>
              <DatePicker
                required
                className="form-control"
                dateFormat="MM/yyyy"
                placeholderText="mm/yyyy"
                name="expDate"
                autocomplete="off"
                onChange={this.handleExpDate}
                showMonthYearPicker
                selected={this.state.expDateForm}
                required
              />
              <Form.Control.Feedback
                id="feedback"
                type="invalid"
                style={
                  validated && this.state.cardDetails.validExpDate === ""
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                Please enter Expiration Date.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom02">
              <Form.Label className="font-bold">CVV</Form.Label>
              <InputGroup>
                <FormControl
                  autocomplete="off"
                  required
                  type="text"
                  name="cvv"
                  onChange={this.inputOnChange.bind(this)}
                />
                <InputGroup.Append>
                  <InputGroup.Text style={{ paddingRight: "0" }}>
                    <i
                      class="fas fa-info-circle"
                      style={{
                        fontSize: "25px",
                        verticalAlign: "middle",
                      }}
                      onClick={this.showCvvHint.bind(this)}
                    ></i>
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback id="feedback" type="invalid">
                  Please enter CVV.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          {/* <Form.Row>
                        <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label className="font-bold">Billing Zip Code (Enter your 5-digit zip code.)</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="billingZipCode"
                                onChange={this.inputOnChange.bind(this)}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                            Please enter your zip code.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} md="6" controlId="validationCustom02">
                            
                        </Form.Group>
                    </Form.Row> */}
          <Form.Row>
            <Form.Group as={Col} md="12" controlId="validationCustom01">
              <Form.Label className="font-bold">Confirmation Email</Form.Label>
              <InputGroup>
                <Form.Control
                  autocomplete="off"
                  id="confirmationEmail"
                  type="text"
                  name="confirmationEmail"
                  value={this.state.cardDetails.confirmationEmail}
                  onChange={this.inputOnChange.bind(this)}
                />
                <InputGroup.Append>
                  <InputGroup.Text style={{ paddingRight: "0" }}>
                    <i
                      class="fas fa-info-circle"
                      style={{
                        fontSize: "25px",
                        verticalAlign: "middle",
                      }}
                      onClick={this.confirmationEmailHint.bind(this)}
                    ></i>
                  </InputGroup.Text>
                </InputGroup.Append>
                <Form.Control.Feedback id="feedback" type="invalid">
                  Please enter Confirmation Email.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label className="font-bold">Customer Name</Form.Label>
              <Form.Control
                autocomplete="off"
                required
                type="text"
                name="cname"
                value={finalFullName}
              />
              <Form.Control.Feedback id="feedback" type="invalid">
                Please enter your Customer Name.
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="validationCustom01">
              <Form.Label className="font-bold">Address</Form.Label>
              <Form.Control
                autocomplete="off"
                required
                type="text"
                name="address"
                value={finalAdd1}
              />
              <Form.Control.Feedback id="feedback" type="invalid">
                Please enter your Address.
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
          <Form.Row>
            {/* <Form.Group as={Col} md="6" controlId="validationCustom01">
                            <Form.Label className="font-bold">Address</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="address"
                                value={finalAdd1}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Address 1.
                            </Form.Control.Feedback>
                        </Form.Group> */}
            {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label className="font-bold">Address 2</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="address2"
                                value={finalAdd2}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Address 2.
                            </Form.Control.Feedback>
                        </Form.Group> */}
          </Form.Row>
        </Form>
        <br />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  paynow: state.paynow,
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
  savePaymentData,
  validateVisaPayment,
  showMessage,
  saveFormConfig,
})(PaynowStep3);
