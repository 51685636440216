import React, { Component } from 'react';
import * as moment from 'moment'

class Footer extends Component {
    constructor() {
        super()
        this.state = {
            currentYear: moment().format('YYYY')
        }
    }
    render() { 
        return (  
            <footer id="footer-main">
                <div id="footer-top" className="secondary-back-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="footer-content-title light-font-color">
                                    <h5 className="font-extra-bold">Benefits</h5>
                                </div>
                                <div className="footer-content-body light-font-color mt-3">
                                    <p>Activating your account will take a few minutes. Soon, you will have access to the following features:</p>
                                </div>
                                <div>
                                    <ul className="fa-ul light-font-color">
                                        <li><i className="fa-li fa fa-check"></i>24/7 Access to your GWA Water account information</li>
                                        <li><i className="fa-li fa fa-check"></i>View your water consumption and history</li>
                                        <li><i className="fa-li fa fa-check"></i>Update your basic account information</li>
                                        <li><i className="fa-li fa fa-check"></i>View your bills and payment history</li>
                                        <li><i className="fa-li fa fa-check"></i>Send a secure message to GWA Customer Service</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="footer-content-title light-font-color">
                                    <h5 className="font-extra-bold">Help & Support</h5>
                                </div>
                                {/* <div className="footer-content-body light-font-color mt-3">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div> */}
                                <div id="contacts" className="light-font-color no-line-height mt-4">
                                    <p className=""><strong><span className="light-font-color link-hover" onClick={() => {window.location.replace("/sign-up")}}>Enroll / Re-enroll to PayGWA</span></strong></p>
                                    <p className=""><strong><span className="light-font-color link-hover" onClick={() => {window.location.replace("/faq")}}>Frequently Asked Questions (FAQ)</span></strong></p>
                                    <p className="">
                                        <strong>
                                            <span 
                                                className="light-font-color link-hover" 
                                                onClick={() => {
                                                    window.open(
                                                        'http://guamwaterworks.org/wp-content/uploads/2020/02/ACH-Auto-Pay-Form-v2.012420-Fillable.pdf',
                                                        '_blank' // <- This is what makes it open in a new window.
                                                    );
                                                }}
                                            >
                                                Enroll for Auto Pay
                                            </span>
                                        </strong>
                                    </p>
                                    <p className="">
                                        <strong>
                                            <span 
                                                className="light-font-color link-hover" 
                                                onClick={() => {
                                                    window.open(
                                                        'http://guamwaterworks.org/wp-content/uploads/2020/02/ACH-Auto-Pay-Canx-Form-v1.120419-Fillable.pdf',
                                                        '_blank' // <- This is what makes it open in a new window.
                                                    );
                                                }}
                                            >
                                                Cancel Auto Pay
                                            </span>
                                        </strong>
                                    </p>
                                    <p><strong>Customer Service:</strong> <a className="light-font-color" href="tel:647-7800/3">647-7800/3</a></p>
                                    <p><strong>Water Outages/Leaks:</strong> <a className="light-font-color" href="tel:646-4211">646-4211</a></p>
                                    <p><strong>Email:</strong> <a className="light-font-color" href="mailto:customers@guamwaterworks.org">customers@guamwaterworks.org</a></p>
                                </div>
                                {/* <div className="light-font-color mt-4">
                                    <p><u>Need help registering for My Water?</u></p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="footer-bottom-content">
                                <div className="dark-font-color footer-bottom-text">
                                    <p>All Rights Reserved © {this.state.currentYear} Guam Waterworks Authority</p>
                                </div>
                                {/* <div className="dark-font-color footer-bottom-icons">
                                    <img src={process.env.PUBLIC_URL + '/images/guam-logo-small.png'} alt="guam-logo-small"></img>
                                    <img src={process.env.PUBLIC_URL + '/images/guam-waterworks.png'} alt="guam-waterworks"></img>
                                    <img src={process.env.PUBLIC_URL + '/images/guam-ccu.png'} alt="guam-ccu"></img>
                                    <img src={process.env.PUBLIC_URL + '/images/guam-me-energy.png'} alt="guam-me-energy"></img>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;