import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';
import { Modal,Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest 
} from '../actions/dashboardActions';
import Loading from './loading';
import NumberFormat from 'react-number-format';
class ModalViewChart extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          show: false,
          showMessage: false,
          consumptionViewBy: 'monthlyWater',
          isHaveConsumption: false,
          waterOptions: {},
          amountsOptions: {},
          waterChartData:{},  
          amountsChartData: {},
          isEdit: false,  
          validated: false,
          selectedAccountId: '',
          selectedPremiseAddress: '',
          isLoading: true,
          isMultipleAccount: false
        };
    }
    
    fetchConsumptionData () {
        if(this.state.isLoading){
            let sessionPersonId;
            for(var count = 0; count < Object.keys(localStorage).length; count++){
                if(!(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex")){
                    if(Object.keys(localStorage)[count] === 'personId'){
                        sessionPersonId = localStorage.getItem(Object.keys(localStorage)[count]);
                    }
                }
            }
            const accountId = this.props.accId
            const personId = sessionPersonId
            this.props.fetchMultipleAddOpptyRequest(accountId, personId)
            .then((status) => {
                this.updateState(status.isHaveConsumptionChart)
            })
        }
    }
    updateState = (status) => {
        const userAccountDetails = this.props.dashboard.userAccountDetails;
        const latestBill = this.props.dashboard.latestBill;
        //update details for consumption chart
        if(status !== "False"){
            this.setState({
                ...this.state,
                waterOptions: {
                    tooltips: {
                        callbacks: {
                            title: function(tooltipItem, data) {
                                const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                return startDate + ' - ' + endDate;
                            }
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            gridLines: { display: false },
                            ticks: {
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                steps: 7,
                                // max: highestKwh,
                                beginAtZero: true, 
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                            }
                        }]
                    },
                    responsive: false,
                },
                amountsOptions: {
                    tooltips: {
                        callbacks: {
                            title: function(tooltipItem, data) {
                                const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                return startDate + ' - ' + endDate;
                            },
                            label: function(tooltipItem, data) {
                                return '$ ' + tooltipItem.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                            }
                        }
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            stacked: true,
                            gridLines: { display: false },
                            ticks: {
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                steps: 7,
                                beginAtZero: true, 
                                fontFamily: "Montserrat",
                                fontStyle: "bold",
                                fontColor: '#333333',
                                fontSize: 14,
                                userCallback: function(value, index, values) {
                                    return '$ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                                }
                            }
                        }]
                    },
                    responsive: true,
                },
                waterChartData:{
                    labels: this.props.dashboard.consumptionDetails.months,
                    datasets:[
                        {
                            label: 'Water Consumption',
                            data: this.props.dashboard.consumptionDetails.totalWater,
                            backgroundColor:[
                                '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7',
                            ]
                        }
                    ],
                },
                amountsChartData:{
                    labels: this.props.dashboard.consumptionDetails.months,
                    datasets:[
                        {
                            label: 'Bill Amount',
                            data: this.props.dashboard.consumptionDetails.amounts,
                            backgroundColor:[
                                '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7',
                            ]
                        }
                    ],
                }, 
            }, () => {
                this.setState({
                    isHaveConsumption: true,
                    isLoading: false
                })
            })
        }
        else{
            this.setState({
                isLoading: false
            })
        }
    }
    changeDataSet(e){
        this.setState({
            ...this.state,
            isLoading: true,
            consumptionViewBy: e.target.value
        }, () => {
            this.fetchConsumptionData();
        })
    }
    getAverage = () => {
        const consumptionDetails = this.props.dashboard.consumptionDetails;
        if(this.state.isHaveConsumption === true){
            if(this.state.consumptionViewBy === 'monthlyBill'){
                const totalAmounts = consumptionDetails.amounts
                let sum         = 0;
                let average     = 0;
                for(var count = 0; count < totalAmounts.length; count++){
                    sum += parseFloat(totalAmounts[count])                  
                }
                average = sum / totalAmounts.length
                return (
                    <NumberFormat 
                        value={Math.round(average)} 
                        displayType={'text'} 
                        thousandSeparator={true} 
                        prefix={'$ '} 
                        decimalScale={2}
                        fixedDecimalScale={true}
                        renderText={value => <h5 className="sub-total-val">{value}</h5>} 
                    />
                )
            }
            else if(this.state.consumptionViewBy === 'monthlyWater'){
                const totalWater = consumptionDetails.totalWater
                let sum         = 0;
                let average     = 0;
                for(var count=0; count < totalWater.length; count++){
                    sum += totalWater[count]                      
                }
                average = sum / totalWater.length
                return <h4>{String(Math.round(average))+' Gallons'}</h4>
            }
        }
    }
    showModal = (e) => {
        this.setState({ show: true, isLoading: true, consumptionViewBy: 'monthlyWater'}, () => {
            this.fetchConsumptionData();
        });
    }
    hideModal = (e) => {
        this.setState({ show: false, isLoading: true, consumptionViewBy: 'monthlyWater'});
    }
    render() { 
        return ( 
            <React.Fragment>
                <Modal
                    id="bar-chart-modal"
                    show={this.state.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isLoading === true ?
                            <center>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="md"
                                    role="status"
                                    aria-hidden="true"
                                />
                            </center>
                            :
                            <div id="bar-chart-wrap">
                                <div className="row top">
                                    <div className="col-lg-6 text-center">
                                        <label className="primary-font-color font-bold">
                                            {
                                                (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                'Your Monthly Water Bills Chart' :
                                                (this.state.consumptionViewBy === 'monthlyWater') ?
                                                'Your Monthly Water Consumption Chart' : ''
                                            }
                                        </label>
                                    </div>
                                    <div className="col-lg-6 text-center">
                                        <p>View By:</p>
                                        <select onChange={this.changeDataSet.bind(this)} defaultValue={this.state.consumptionViewBy}>
                                            <option value="monthlyWater">Monthly Water Consumption</option>
                                            <option value="monthlyBill">Monthly Bill Amount</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row bottom">
                                    <div className="col-lg-12 graph-label">
                                        <label className="font-bold">
                                            {
                                                (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                'USD' :
                                                (this.state.consumptionViewBy === 'monthlyWater') ?
                                                'Gallons' : ''
                                            }
                                        </label>
                                    </div>
                                    <div className="col-lg-12 scroll-hidden-mobile">
                                        <div id="bar-scroll-mobile">
                                            {
                                                <Bar
                                                    id="consumption-chart"
                                                    data={  
                                                            (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                            this.state.amountsChartData :
                                                            (this.state.consumptionViewBy === 'monthlyWater') ?
                                                            this.state.waterChartData : ''
                                                            }
                                                    height={300}
                                                    width={980}
                                                    options={
                                                            (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                            this.state.amountsOptions :
                                                            (this.state.consumptionViewBy === 'monthlyWater') ?
                                                            this.state.waterOptions : ''
                                                            }
                                                    redraw
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="text-center">
                                            <br/>
                                            <label>
                                                {
                                                    (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                    'Average Bill for '+this.state.amountsChartData?.labels?.length+' Months' :
                                                    (this.state.consumptionViewBy === 'monthlyWater') ?
                                                    'Average Usage for '+this.state.waterChartData?.labels?.length+' Months' : ''
                                                }
                                            </label>
                                            {
                                                this.getAverage()
                                            }
                                        </div>
                                    </div>
                                    {/* <div className="content-right">
                                    </div> */}
                                </div>
                            </div>
                        }
                    </Modal.Body>
                </Modal>
                <p style={{margin: "0"}} onClick={this.showModal.bind(this)}><img src={process.env.PUBLIC_URL + '/images/chart-bar-regular.svg'} width="15px"/> View Consumption</p>
            </React.Fragment>
        );
    }
}
 
const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest 
})(ModalViewChart);