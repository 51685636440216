import React, { Component } from "react";
import Header from "../../partials/header";
import Footer from "../../partials/less-content-footer";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import parse from "html-react-parser";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {
  Modal,
  Tabs,
  Tab,
  Table,
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Accordion,
  Card
} from "react-bootstrap";

import { 
  showMessage
} from '../../actions/popupMessageActions';

import axios from "axios";

const dashboardBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class AdminAnnouncements extends Component {
  constructor() {
    super();
    this.state = {
      announcementImageSource: dashboardBaseURL+'/images/',
      announcementPop: [],
      announcementsList: [],
      checkAnnounce: [],
      showWithoutAttachment: false,
      showWithAttachment: false,
      selectAllAnnounce: false,
      selectAllAnnouncePop: false,
      selectedFile: "",
      fileText: "Choose file",
      chooseFile: "",
      title: "",
      embeded: "",
      editorState: EditorState.createEmpty(),
      type: "Image",
      isDisabled: false,
      showAlert: false,
      prompt: "",
      hideOk: false,
      baseUrl: "",
      activePop: "",
      activeList: "",
      showEmbeded: false,
      announceIdUpdate: "",
      showUpdateButton: false,
      isImage: false,
      modalImage: false,
      modalAnnounceType: "",
      modalAnnounceContent: "",
      headerText: "Add Announcement"
    };
    this.handleShowWithoutAttachment = this.handleShowWithoutAttachment.bind(
      this
    );
    this.handleCloseWithoutAttachment = this.handleCloseWithoutAttachment.bind(
      this
    );
    this.handleShowWithAttachment = this.handleShowWithAttachment.bind(this);
    this.handleCloseWithAttachment = this.handleCloseWithAttachment.bind(this);
    this.uploadFileHandler = this.uploadFileHandler.bind(this);
    this.submitFormWithoutAttachment = this.submitFormWithoutAttachment.bind(
      this
    );
    this.submitFormWithAttachment = this.submitFormWithAttachment.bind(this);
  }

  componentWillMount() {
    this.getAnnouncements();
  }

  handleCloseWithoutAttachment() {
    this.setState({ showWithoutAttachment: false });
  }

  handleShowWithoutAttachment(e) {
    e.preventDefault();
    this.setState({ showWithoutAttachment: true, headerText: "Add Announcement" });
  }

  handleCloseWithAttachment() {
    this.setState({ showWithAttachment: false });
  }

  handleShowWithAttachment(e) {
    e.preventDefault();
    this.setState({ showWithAttachment: true, headerText: "Add Announcement" });
  }

  uploadFileHandler = event => {
    this.setState({
      isImage: true,
      fileText: event.target.files[0].name,
      selectedFile: event.target.files[0]
    });
  };

  inputChange = e => {
    this.setState(
      {
        ...this.state,
        [e.target.name]: e.target.value
      },
      () => {
        if (this.state.type == "Video") {
          this.setState({ showEmbeded: true });
        } else {
          this.setState({ showEmbeded: false });
        }
      }
    );
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  handleInputAnnounceAllPop = e => {
    let announcementPop = this.state.announcementPop;
    let checkAnnounce = [];

    this.setState(
      { selectAllAnnouncePop: !this.state.selectAllAnnouncePop },
      () => {
        if (this.state.selectAllAnnouncePop == true) {
          //true or false
          for (let x = 0; x < announcementPop.length; x++) {
            checkAnnounce.push(announcementPop[x].id);
          }
          this.setState({
            checkAnnounce
          });
        } else {
          this.setState({
            checkAnnounce
          });
        }
        this.setState({
          chooseFile: "Pop"
        });
      }
    );
  };

  handleInputAnnounceAll = e => {
    let announcementsList = this.state.announcementsList;
    let checkAnnounce = [];

    this.setState({ selectAllAnnounce: !this.state.selectAllAnnounce }, () => {
      if (this.state.selectAllAnnounce == true) {
        //true or false
        for (let x = 0; x < announcementsList.length; x++) {
          checkAnnounce.push(announcementsList[x].id);
        }
        this.setState({
          checkAnnounce
        });
      } else {
        this.setState({
          checkAnnounce
        });
      }
      this.setState({
        chooseFile: "Announce"
      });
    });
  };

  checkInputAnnouncePop = (item, index) => {
    if (this.state.checkAnnounce.includes(item.id)) {
      //if true, it is in checked users -> user will uncheck
      let copyOfcheckAnnounce = this.state.checkAnnounce;
      copyOfcheckAnnounce.splice(copyOfcheckAnnounce.indexOf(item.id), 1);
      this.setState({
        //remove on checked list and disable select all mode
        selectAllAnnouncePop: false,
        checkAnnounce: copyOfcheckAnnounce
      });
    } else {
      let announcementPop = this.state.announcementPop;
      let checkAnnounce = this.state.checkAnnounce;
      checkAnnounce.push(announcementPop[index].id);
      this.setState(
        {
          selectAllAnnouncePop: false,
          checkAnnounce: checkAnnounce
        }
      );
    }

    // FINAL CHECKER IF ALL ITEMS ARE SELECTED
    if (this.state.checkAnnounce.length == this.state.announcementPop.length) {
      let announcementPop = this.state.announcementPop;
      let checkAnnounce = [];
      for (let x = 0; x < announcementPop.length; x++) {
        checkAnnounce.push(announcementPop[x].id);
      }
      this.setState(
        {
          selectAllAnnouncePop: true,
          checkAnnounce
        }
      );
    }
    this.setState({
      chooseFile: "Pop"
    });
  };

  checkInputAnnounce = (item, index) => {
    if (this.state.checkAnnounce.includes(item.id)) {
      //if true, it is in checked users -> user will uncheck
      let copyOfcheckAnnounce = this.state.checkAnnounce;
      copyOfcheckAnnounce.splice(copyOfcheckAnnounce.indexOf(item.id), 1);
      this.setState({
        //remove on checked list and disable select all mode
        selectAllAnnounce: false,
        checkAnnounce: copyOfcheckAnnounce
      });
    } else {
      let announcementsList = this.state.announcementsList;
      let checkAnnounce = this.state.checkAnnounce;
      checkAnnounce.push(announcementsList[index].id);
      this.setState(
        {
          selectAllAnnounce: false,
          checkAnnounce: checkAnnounce
        }
      );
    }

    // FINAL CHECKER IF ALL ITEMS ARE SELECTED
    if (
      this.state.checkAnnounce.length == this.state.announcementsList.length
    ) {
      let announcementsList = this.state.announcementsList;
      let checkAnnounce = [];
      for (let x = 0; x < announcementsList.length; x++) {
        checkAnnounce.push(announcementsList[x].id);
      }
      this.setState(
        {
          selectAllAnnounce: true,
          checkAnnounce
        }
      );
    }
    this.setState({
      chooseFile: "Announce"
    });
  };

  deleteAnnounce = () => {
    let ids = this.state.checkAnnounce;
    ids.map((itemId, i) => {
      axios
        .post(
          "/api/v1/delete-announcement",
          {
            id: itemId
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          if (response.data.result == "Success") {
            if (this.state.chooseFile == "Pop") {
              let copyOfcheckAnnouncePop = this.state.announcementPop;
              copyOfcheckAnnouncePop.splice(
                copyOfcheckAnnouncePop.indexOf(itemId.id),
                1
              );
              this.setState({
                prompt: "Deleted Successfully!",
                showAlert: true,
                hideOk: false,
                announcementPop: copyOfcheckAnnouncePop,
                checkAnnounce: copyOfcheckAnnouncePop
              });
            } else {
              let copyOfcheckAnnounce = this.state.announcementsList;
              copyOfcheckAnnounce.splice(
                copyOfcheckAnnounce.indexOf(itemId.id),
                1
              );
              this.setState({
                prompt: "Deleted Successfully!",
                showAlert: true,
                hideOk: false,
                announcementsList: copyOfcheckAnnounce,
                checkAnnounce: copyOfcheckAnnounce
              });
            }

            this.setState({
              selectAllAnnounce: false,
              selectAllAnnouncePop: false
            });
          }
        })
        .catch(error => {
          this.props.showMessage('danger', "Server Error! ");
        });
    });
  };

  getAnnouncements = () => {
    let baseUrl = localStorage.getItem("baseUrl");
    this.setState({ baseUrl: baseUrl });
    return axios
      .get("/api/v1/announcements", {
        headers: { "Content-Type": "application/json" }
      })
      .then(response => {
        let y = response.data;
        let announcementsList = [];
        let announcementPop = [];
        for (let x = 0; x < y.length; x++) {
          if (y[x].featured_image != null && y[x].type != null) {
            announcementsList.push(y[x]);
          } else {
            announcementPop.push(y[x]);
          }
        }

        this.setState({
          announcementsList: announcementsList,
          announcementPop: announcementPop
        });
      });
  };

  submitFormWithoutAttachment = event => {
    let cont = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    this.setState({
      isDisabled: true
    });
    event.preventDefault();
    const formData = new FormData();
    formData.append("title", this.state.title);
    formData.append("content", cont);
    axios
      .post("/api/v1/insert-announcement-without", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response.data.result == "Success") {
          this.getAnnouncements();
          this.setState({
            isDisabled: false,
            prompt: "Added Successfully!",
            showAlert: true,
            hideOk: true,
            showWithoutAttachment: false,
            id: "",
            type: "",
            featured_image: "",
            title: "",
            embeded: "",
            fileText: "",
            editorState: EditorState.createEmpty(),
          });
        } else {
          this.setState({
            isDisabled: false,
            prompt: "Failed! Please try again!",
            showAlert: true
          });
        }
      })
      .catch(error => {
        this.props.showMessage('danger', "Server Error! ");
      });
  };

  submitFormWithAttachment = event => {
    let cont = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    this.setState({
      isDisabled: true
    });
    event.preventDefault();
    const formData = new FormData();
    formData.append("type", this.state.type);
    formData.append("title", this.state.title);
    formData.append("content", cont);
    if (this.state.type == "Video") {
      formData.append("myFile", this.state.embeded);
    } else {
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );
    }

    axios
      .post("/api/v1/insert-announcement", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response.data.result == "Success") {
          this.getAnnouncements();
          this.setState({
            isDisabled: false,
            prompt: "Added Successfully!",
            showAlert: true,
            hideOk: true,
            showWithAttachment: false,
            id: "",
            type: "",
            featured_image: "",
            title: "",
            embeded: "",
            fileText: "",
            editorState: EditorState.createEmpty(),
          });
        } else {
          this.setState({
            isDisabled: false,
            prompt: "Failed! Please try again!",
            showAlert: true
          });
        }
      })
      .catch(error => {
        this.props.showMessage('danger', "Server Error! ");
      });
  };

  handleCloseModalAlert = () => {
    this.setState({ showAlert: false, modalImage: false });
  };

  handleClickList(i) {
    return e => {
      let activeList = this.state.activeList === i ? null : i;
      this.setState({ activeList: activeList });
    };
  }

  handleClickPop(i) {
    return e => {
      let activePop = this.state.activePop === i ? null : i;
      this.setState({ activePop: activePop });
    };
  }

  displayPop(i) {
    return this.state.activePop === i ? "block" : "none";
  }

  displayList(i) {
    return this.state.activeList === i ? "block" : "none";
  }

  updateVisibility = e => {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("valueid");
    let flag = e.currentTarget.getAttribute("flag") == 0 ? 1 : 0;

    return axios
      .post(
        "/api/v1/update-flag-announcement",
        {
          id: id,
          flag: flag
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data.result == "Success") {
          this.getAnnouncements();
          this.setState({
            prompt: "Updated Successfully!",
            showAlert: true
          });
        } else {
          this.setState({
            prompt: "Please try again!",
            showAlert: true
          });
        }
      })
      .catch(error => {
        this.props.showMessage('danger', "Server Error! ");
      });
  };

  updateModalPop = e => {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("valueid");
    let title = e.currentTarget.getAttribute("valuetitle");
    let content = e.currentTarget.getAttribute("valuecontent");
    const contentBlock = htmlToDraft(content);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState
      });
    }
    this.setState({
      headerText: "Update Announcement",
      showUpdateButton: true,
      showWithoutAttachment: true,
      announceIdUpdate: id,
      title: title
    });
  };

  submitUpdatePop = e => {
    e.preventDefault();
    let cont = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    return axios
      .post(
        "/api/v1/update-content-announcement",
        {
          id: this.state.announceIdUpdate,
          type: "",
          featured_image: "",
          title: this.state.title,
          content: cont
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data.result == "Success") {
          this.getAnnouncements();
          this.setState({
            headerText: "Add Announcement",
            id: "",
            type: "",
            featured_image: "",
            title: "",
            editorState: EditorState.createEmpty(),
            showUpdateButton: false,
            prompt: "Updated Successfully!",
            showAlert: true,
            isDisabled: false,
            showAlert: true,
            hideOk: true,
            showWithoutAttachment: false
          });
        } else {
          this.setState({
            prompt: "Please try again!",
            showAlert: true,
            showWithoutAttachment: false
          });
        }
      })
      .catch(error => {
        this.props.showMessage('danger', "Server Error! ");
      });
  };

  updateModalPopWithAttachment = e => {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("valueid");
    let title = e.currentTarget.getAttribute("valuetitle");
    let content = e.currentTarget.getAttribute("valuecontent");
    let type = e.currentTarget.getAttribute("valuetype");
    let attacthment = e.currentTarget.getAttribute("valueattach");
    const contentBlock = htmlToDraft(content);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({
        editorState
      });
    }

    if (type == "Video") {
      this.setState({ showEmbeded: true, embeded: attacthment });
    } else {
      this.setState({ showEmbeded: false, fileText: attacthment });
    }

    this.setState({
      headerText: "Update Announcement",
      showUpdateButton: true,
      showWithAttachment: true,
      announceIdUpdate: id,
      title: title,
      type: type
    });
  };

  submitUpdatePopWithAttachment = e => {
    e.preventDefault();
    let cont = draftToHtml(
      convertToRaw(this.state.editorState.getCurrentContent())
    );
    this.setState({
      isDisabled: true
    });
    const formData = new FormData();
    formData.append("id", this.state.announceIdUpdate);
    formData.append("type", this.state.type);
    formData.append("title", this.state.title);
    formData.append("content", cont);
    if (this.state.type == "Video") {
      formData.append("myFile", this.state.embeded);
    } else {
      if (this.state.isImage) {
        formData.append(
          "myFile",
          this.state.selectedFile,
          this.state.selectedFile.name
        );
      }else {
        formData.append(
          "myFile",
          null
        );
      }
    }

    axios
      .post("/api/v1/update-content-with-announcement", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(response => {
        if (response.data.result == "Success") {
          this.getAnnouncements();
          this.setState({
            headerText: "Add Announcement",
            id: "",
            type: "Image",
            featured_image: "",
            title: "",
            embeded: "",
            fileText: "",
            editorState: EditorState.createEmpty(),
            showUpdateButton: false,
            prompt: "Updated Successfully!",
            showAlert: true,
            isDisabled: false,
            showAlert: true,
            hideOk: true,
            showWithAttachment: false,
            isImage:false
          });
        } else {
          this.setState({
            isDisabled: false,
            prompt: "Failed! Please try again!",
            showAlert: true
          });
        }
      })
      .catch(error => {
        this.props.showMessage('danger', "Server Error! ");
      });
  };

  showAnnounceModal = (e) => {
    e.preventDefault();
    let type = e.currentTarget.getAttribute("modaltype");
    let image = e.currentTarget.getAttribute("modalimage");

    this.setState({
      modalAnnounceType: type,
      modalAnnounceContent: image,
      modalImage: true,
    });
  }

  render() {
    const { editorState } = this.state;
    return (
      <React.Fragment>
        <Modal
          show={this.state.showAlert}
          onHide={this.handleCloseModalAlert}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-alert"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h6>{this.state.prompt}</h6>
            {this.state.hideOk ? null : (
              <div className="btn-submit-change-ok">
                <button
                  type="submit"
                  onClick={this.handleCloseModalAlert}
                  className="btn btn-change-ok font-bold btn-primary"
                >
                  Okay
                </button>
              </div>
            )}
          </Modal.Body>
        </Modal>
        
        <Modal
          show={this.state.modalImage}
          onHide={this.handleCloseModalAlert}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-announce"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
          {   
            this.state.modalAnnounceType == 'Image' ?
            <img width="450" height="300" src={this.state.announcementImageSource+this.state.modalAnnounceContent} />
            :
            <div className="iframe-cont">
                {parse(this.state.modalAnnounceContent)}
            </div>
          }
          </Modal.Body>
        </Modal>
        
        <Modal
          show={this.state.showWithoutAttachment}
          onHide={this.handleCloseWithoutAttachment}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-with-file"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h4>{this.state.headerText}</h4>
            <Form
              autocomplete="off"
              onSubmit={
                this.state.showUpdateButton
                  ? this.submitUpdatePop
                  : this.submitFormWithoutAttachment
              }
            >
              <Form.Group controlId="announce-title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  value={this.state.title}
                  required
                  type="text"
                  autocomplete="off"
                  name="title"
                  className="valid"
                  autocomplete="off"
                  onChange={this.inputChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="announce-content">
                <Form.Label>Content</Form.Label>
                <Editor
                  name="content"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "list",
                      "textAlign",
                      "link",
                      "history"
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true }
                  }}
                  editorState={editorState}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </Form.Group>
              <div className="btn-submit-with-file">
                {this.state.isDisabled ? (
                  <button
                    type="submit"
                    className="btn btn-announcement isDisabled-admin font-bold btn-primary"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-announcement font-bold btn-primary"
                  >
                    Submit
                  </button>
                )}
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showWithAttachment}
          onHide={this.handleCloseWithAttachment}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-with-file"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h4>{this.state.headerText}</h4>
            <Form
              autocomplete="off"
              onSubmit={
                this.state.showUpdateButton
                  ? this.submitUpdatePopWithAttachment
                  : this.submitFormWithAttachment
              }
            >
              <Form.Group controlId="announce-title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  value={this.state.title}
                  required
                  type="text"
                  autocomplete="off"
                  name="title"
                  className="valid"
                  autocomplete="off"
                  onChange={this.inputChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="announce-content">
                <Form.Label>Content</Form.Label>
                <Editor
                  name="content"
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  toolbarClassName="toolbar-class"
                  toolbar={{
                    options: [
                      "inline",
                      "blockType",
                      "list",
                      "textAlign",
                      "link",
                      "history"
                    ],
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true }
                  }}
                  editorState={editorState}
                  onEditorStateChange={this.onEditorStateChange}
                />
              </Form.Group>
              <Form.Label>Image/Video</Form.Label>
              <div
                className="select-type"
                onChange={this.inputChange.bind(this)}
              >
                <label>
                  <input
                    type="radio"
                    defaultChecked={this.state.type === "Image"}
                    name="type"
                    value="Image"
                  />
                  Image
                </label>
                <label>
                  <input
                    type="radio"
                    defaultChecked={this.state.type === "Video"}
                    name="type"
                    value="Video"
                  />
                  Video
                </label>
              </div>
              {this.state.showEmbeded ? (
                <Form.Control
                  required
                  autocomplete="off"
                  value={this.state.embeded}
                  as="textarea"
                  rows="5"
                  name="embeded"
                  className="valid"
                  placeholder="Paste the embeded code here ... "
                  onChange={this.inputChange.bind(this)}
                />
              ) : (
                <div className="input-group" id="custom-upload">
                  <div className="custom-file">
                   {
                     this.state.selectedFile == null ?
                     <input
                      required
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile01"
                      aria-describedby="inputGroupFileAddon01"
                      onChange={this.uploadFileHandler}
                    />
                    :
                    <input
                      type="file"
                      className="custom-file-input"
                      id="inputGroupFile01"
                      aria-describedby="inputGroupFileAddon01"
                      onChange={this.uploadFileHandler}
                    />
                   }
                    <label
                      className="custom-file-label"
                      htmlFor="inputGroupFile01"
                    >
                      {this.state.fileText}
                    </label>
                  </div>
                </div>
              )}

              <div className="btn-submit-with-file">
                {this.state.isDisabled ? (
                  <button
                    type="submit"
                    className="btn btn-announcement font-bold isDisabled-admin btn-primary"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-announcement font-bold btn-primary"
                  >
                    Submit
                  </button>
                )}
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <div id="admin-announcement-container">
          <Container fluid={true}>
            <Row>
              <Col>
                <h5>Announcements</h5>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.announcementPop.length < 1 ? (
                  <div className="button-left">
                    <button
                      type="submit"
                      className="btn btn-announcement font-bold btn-primary"
                      onClick={this.handleShowWithoutAttachment}
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>Add Text
                    </button>
                  </div>
                ) : (
                  <div className="button-left">
                    <button
                      type="submit"
                      className="btn btn-announcement-disabled font-bold btn-primary"
                    >
                      <i className="fa fa-plus" aria-hidden="true"></i>Add Text
                    </button>
                  </div>
                )}
                <div className="button-left">
                  <button
                    type="submit"
                    className="btn btn-announcement font-bold"
                    onClick={this.handleShowWithAttachment}
                  >
                    <i className="fa fa-plus" aria-hidden="true"></i>Add
                    Image/Video
                  </button>
                </div>
                <div className="button-right">
                  <button
                    type="submit"
                    className="btn btn-announcement-delete font-bold"
                    onClick={this.deleteAnnounce}
                  >
                    <i className="fa fa-trash" aria-hidden="true"></i>Delete
                    Selected
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div id="tabs-containers">
                  <Tabs defaultActiveKey="key1" id="uncontrolled-tab-example">
                    <Tab eventKey="key1" title="System Pop up">
                      <div className="announce-container">
                        <Table className="table" responsive="sm">
                          <thead>
                            <tr>
                              <th width="10%" className="outlined">
                                <Form.Group className="check-container">
                                  <label className="container-check">
                                    <Form.Check.Input
                                      isValid
                                      className=""
                                      checked={this.state.selectAllAnnouncePop}
                                      onClick={this.handleInputAnnounceAllPop.bind(
                                        this
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Form.Group>
                              </th>
                              <th width="30%">Title</th>
                              <th>Content</th>
                              <th width="8%" colSpan="2">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.announcementPop.map((item, i) => {
                              let cont = parse(item.content);
                              return (
                                <tr key={i}>
                                  <td className="outlined">
                                    <Form.Group className="check-container">
                                      <label className="container-check">
                                        <Form.Check.Input
                                          isValid
                                          className=""
                                          value={item.id}
                                          checked={this.state.checkAnnounce.includes(
                                            item.id
                                          )}
                                          onClick={() =>
                                            this.checkInputAnnouncePop(item, i)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </Form.Group>
                                  </td>
                                  <td>{item.title}</td>
                                  <td>
                                    <div className="item-cont">
                                      <a onClick={this.handleClickPop(i)}>
                                        View Content
                                      </a>
                                      <div
                                        className="body-cont"
                                        style={{ display: this.displayPop(i) }}
                                      >
                                        <Card>
                                          <Card.Body>{cont}</Card.Body>
                                        </Card>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {item.flag == 0 ? (
                                      <a
                                        onClick={this.updateVisibility.bind(
                                          this
                                        )}
                                        flag={item.flag}
                                        valueid={item.id}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/view-eye.png"
                                          }
                                          title="Visibility"
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        onClick={this.updateVisibility.bind(
                                          this
                                        )}
                                        flag={item.flag}
                                        valueid={item.id}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/hide-eye.png"
                                          }
                                          title="Visibility"
                                        />
                                      </a>
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      valueid={item.id}
                                      valuetitle={item.title}
                                      valuecontent={item.content}
                                      onClick={this.updateModalPop.bind(this)}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/images/writing.png"
                                        }
                                        title="Edit"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        {this.state.announcementPop.length < 1 ? (
                          <p className="no-data">No data yet</p>
                        ) : null}
                      </div>
                    </Tab>
                    {/* <Tab eventKey="key2" title="Announcements List">
                      <div className="announce-container">
                        <Table className="table" responsive="sm">
                          <thead>
                            <tr>
                              <th width="10%" className="outlined">
                                <Form.Group className="check-container">
                                  <label className="container-check">
                                    <Form.Check.Input
                                      isValid
                                      className=""
                                      checked={this.state.selectAllAnnounce}
                                      onClick={this.handleInputAnnounceAll.bind(
                                        this
                                      )}
                                    />
                                    <span className="checkmark"></span>
                                  </label>
                                </Form.Group>
                              </th>
                              <th width="15%">Featured Image</th>
                              <th width="20%">Title</th>
                              <th>Content</th>
                              <th width="8%" colSpan="2">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.announcementsList.map((item, i) => {
                              let cont = parse(item.content);
                              let iframe = parse(item.featured_image);
                              return (
                                <tr key={i}>
                                  <td className="outlined">
                                    <Form.Group className="check-container">
                                      <label className="container-check">
                                        <Form.Check.Input
                                          isValid
                                          className=""
                                          value={item.id}
                                          checked={this.state.checkAnnounce.includes(
                                            item.id
                                          )}
                                          onClick={() =>
                                            this.checkInputAnnounce(item, i)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                      </label>
                                    </Form.Group>
                                  </td>
                                  <td>
                                    {
                                      item.type == 'Image' ?
                                      <a modaltype={item.type} modalimage={item.featured_image} onClick={this.showAnnounceModal.bind(this)}><img width="100" height="80" src={this.state.announcementImageSource+item.featured_image} /></a>
                                      :
                                      <div className="click-a" modaltype={item.type} modalimage={item.featured_image} onClick={this.showAnnounceModal.bind(this)}>
                                        <a modaltype={item.type} modalimage={item.featured_image} className="iframe-click" >
                                            {iframe}
                                        </a>
                                      </div>
                                    }

                                  </td>
                                  <td>{item.title}</td>
                                  <td>
                                    <div className="item-cont">
                                      <a onClick={this.handleClickList(i)}>
                                        View Content
                                      </a>
                                      <div
                                        className="body-cont"
                                        style={{ display: this.displayList(i) }}
                                      >
                                        <Card>
                                          <Card.Body>{cont}</Card.Body>
                                        </Card>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    {item.flag == 0 ? (
                                      <a
                                        onClick={this.updateVisibility.bind(
                                          this
                                        )}
                                        flag={item.flag}
                                        valueid={item.id}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/view-eye.png"
                                          }
                                          title="Visibility"
                                        />
                                      </a>
                                    ) : (
                                      <a
                                        onClick={this.updateVisibility.bind(
                                          this
                                        )}
                                        flag={item.flag}
                                        valueid={item.id}
                                      >
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/hide-eye.png"
                                          }
                                          title="Visibility"
                                        />
                                      </a>
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      valueid={item.id}
                                      valuetitle={item.title}
                                      valuecontent={item.content}
                                      valuetype={item.type}
                                      valueattach={item.featured_image}
                                      onClick={this.updateModalPopWithAttachment.bind(
                                        this
                                      )}
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/images/writing.png"
                                        }
                                        title="Edit"
                                      />
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                        {this.state.announcementsList.length < 1 ? (
                          <p className="no-data">No data yet</p>
                        ) : null}
                      </div>
                    </Tab> */}
                  </Tabs>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
});

export default connect(mapStateToProps, 
  { 
      showMessage 
  })(AdminAnnouncements);