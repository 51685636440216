import React, { Component } from 'react';
import { connect } from 'react-redux';
import { 
    
} from '../actions/paynowActions';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';


class PaynowStep2 extends Component {
    maskString = (value) => {
        let str = value;
        let count = 0;
        let new_str = "";
        for(let i = 0; i < str.length; i++){
            if(count > 1){
                if(str.charAt(i) != " "){
                    new_str = new_str + '*'
                    count = count + 1
                }
                else{
                    new_str = new_str + " "
                    count = 0;
                }
                
            }
            else if(count < 2){
                if(str.charAt(i) != " "){
                    new_str = new_str + str.charAt(i)
                    count = count + 1
                }
                else{
                    new_str = new_str + " "
                    count = 0;
                }
            }
            
        }
        return new_str;
    }
    render() { 
        let fullName  = this.props.paynow.validateZipCodeRes.result.details.Name;

        const finalFullName = this.maskString(fullName);

        const address1 = this.props.paynow.validateZipCodeRes.result.details.Address;

        const finalAdd1 = this.maskString(address1);

        return (  
            <React.Fragment>
                <h4>Is This You?</h4>
                <br />
                <Form autocomplete="off">
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label className="font-bold">Customer Name</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="cname"
                                value={finalFullName}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Customer Name.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label className="font-bold">Last Name</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="lname"
                                value={finalLastName}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Last Name.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                    </Form.Row>
                    <Form.Row>
                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                            <Form.Label className="font-bold">Address</Form.Label>
                            <Form.Control
                            autocomplete="off"
                                required
                                type="text"
                                name="address"
                                value={finalAdd1}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Address 1.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group as={Col} md="6" controlId="validationCustom02">
                            <Form.Label className="font-bold">Address 2</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                name="address2"
                                value={finalAdd2}
                            />
                            <Form.Control.Feedback id="feedback" type="invalid">
                                Please enter your Address 2.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                    </Form.Row>
                </Form>
                <br />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    paynow: state.paynow
});

export default connect(mapStateToProps, { })(PaynowStep2);