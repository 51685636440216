import React, { Component } from 'react';
import { CSVLink } from "react-csv";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import axios from "axios";
// /api/v1/transaction-logs
class TransactionLogs extends Component {
    state = { 
        reports: [],
    }
    componentDidMount(){
        this.getTransLogs()
    }
    getTransLogs = () => {
        // return axios
        //   .get("/api/v1/transaction-logs", {
        //     headers: { "Content-Type": "application/json" }
        //   })
        //   .then(response => {
        //     this.setState({
        //       pages: response.data.result.pages,
        //       reports: response.data.result.list,
        //       sumOnline: response.data.result.sumOnline ,
        //       sumPhonedin: response.data.result.sumPhonedin,
        //       totalOnline: response.data.result.totalOnline,
        //       totalPhonedIn: response.data.result.totalPhonedIn,
        //       isLoading: false,
        //     });
        //   });
      };
    renderList() {
        const columns = 
        [
            {
            dataField: 'username',
            text: 'User ID'
            }, {
            dataField: 'accountId',
            text: 'Account #',
            sort: true,
        }, {
            dataField: 'auth_no',
            text: 'Auth #',
            sort: true,
            }, {
            dataField: 'payment_date',
            text: 'Payment Date',
            sort: true,
            }, {
            dataField: 'receipt_number',
            text: 'Receipt #',
            sort: true,
            },{
            dataField: 'count',
            text: '# of Payments',
            sort: true,
            },{
            dataField: 'transaction_tag',
            text: 'Trans. Tag',
            sort: true,
            },
            // {
            // dataField: 'pay_eezy_seq_num',
            // text: 'Payeezy Seq. #',
            // sort: true,
            // },
            {
            dataField: 'amount',
            text: 'Amount',
            sort: true,
            }, {
            dataField: 'id',
            text: 'Details',
            formatter: (cellContent, row) => (
                <a id={row.id} receipt={row.receipt_number} onClick={this.getIndividual.bind(this)}>view</a>
            )
            }, {
            dataField: 'process_message',
            text: 'Process Msg'
            }, {
            dataField: 'bank_response',
            text: 'Bank Response'
            }, {
            dataField: 'mode',
            text: 'Mode'
            },{ 
            dataField: 'ccb_status',
            text: 'In CCB',
            sort: true,
            }
        ];

        const options = {
            paginationSize: 4,
            pageStartIndex: 1,
            // alwaysShowAllBtns: true, // Always show next and previous button
            // withFirstAndLast: false, // Hide the going to First and Last page button
            // hideSizePerPage: true, // Hide the sizePerPage dropdown always
            // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
            firstPageText: 'First',
            prePageText: 'Back',
            nextPageText: 'Next',
            lastPageText: 'Last',
            nextPageTitle: 'First page',
            prePageTitle: 'Pre page',
            firstPageTitle: 'Next page',
            lastPageTitle: 'Last page',
            showTotal: true,
            // paginationTotalRenderer: customTotal,
            sizePerPageList: [{
            text: '10', value: 10
            }, {
            text: '30', value: 30
            }, {
            text: '50', value:50
            }]
        };

        const headers = [
            {
            label: 'UserId',
            key: 'username'
            },
            {
            label: 'Account #',
            key: 'accountId'
            },
            {
            label: 'Auth #',
            key: 'auth_no'
            },
            {
            label: 'Payment Date',
            key: 'payment_date'
            },
            {
            label: 'Receipt #',
            key: 'receipt_number'
            },
            {
            label: '# of Payments',
            key: 'count'
            },
            {
            label: 'Trans. Tag',
            key: 'transaction_tag'
            },
            // {
            // label: 'Payeezy Seq. #',
            // key: 'pay_eezy_seq_num'
            // },
            {
            label: 'Amount',
            key: 'amount'
            },
            {
            label: 'Process Msg',
            key: 'process_message'
            },
            {
            label: 'Bank Response',
            key: 'bank_response'
            },
            {
            label: 'Mode',
            key: 'mode'
            },
            {
            label: 'In CCB',
            key: 'ccb_status'
            },
        ]

        return (
            <div id="report-list-container">
                <div class="csv-container">
                    <CSVLink 
                    data={ this.state.reports }
                    headers={headers}
                    filename={this.state.fileName} 
                    className="btn-export-csv">
                    Export CSV
                    </CSVLink>
                </div>
                <BootstrapTable 
                bootstrap4 = {true}
                keyField='id' 
                data={ this.state.reports } 
                columns={ columns } 
                pagination={ paginationFactory(options) }
                noDataIndication="No Transaction Logs Made"  
                hover
                condensed
                wrapperClasses="table-responsive"
                />
            </div>
        );
    }
    render() { 
        return (  
            <React.Fragment>
                <div id="admin-reset-pass-container">
                    {
                        this.renderList()
                    }
                </div>
            </React.Fragment>
        );
    }
}
 
export default TransactionLogs;