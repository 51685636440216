import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getViewBillData } from '../actions/dashboardActions';
import _ from 'lodash';
import ViewBill from './viewBill';
import Loading from '../components/loading';

class MyBills extends Component {
    constructor(props){
        super(props);
        this.state = {
            isViewBill: false,
            selectedItem: 0,
            itemCount: 0,
            selectedBillID: '',
            isLoading: false
        }
    }
    componentWillMount() {
        let totalItems = 0;
        for(var count1 = 0; count1 < this.props.dashboard.billList.length; count1++){
            for(var count2 = 0; count2 < this.props.dashboard.billList[count1].length; count2++){
                totalItems = totalItems + 1
            }
        }
        this.setState({
            ...this.state,
            itemCount: totalItems
        })
    }
    viewBill = (billID) => {
        this.setState({
            isLoading: true
        }, () => {
            this.props.getViewBillData(billID)
            .then(() => {
                this.setState({
                    ...this.state,
                    isLoading: false,
                    selectedBillID: billID,
                    isViewBill: true
                })
            })
        })
    }
    
    handleNext = () => {
        if(this.state.selectedItem < this.props.dashboard.billList.length - 1)
        {
            this.setState({
                selectedItem: this.state.selectedItem + 1
            })
        }
    }
    handlePrev = () => {
        if(this.state.selectedItem > 0)
        {
            this.setState({
                selectedItem: this.state.selectedItem - 1
            })
        }
    }

    render() { 
        return (  
            this.state.isViewBill ? 
            <React.Fragment>
                <ViewBill billID={this.state.selectedBillID}/>
            </React.Fragment>
            :
            this.state.isLoading ?
                <Loading />
            :
            <React.Fragment>
                <div id="title">
                    <div className="row pb-3">
                        <div className="col-lg-12">
                            <h3 className="primary-font-color">My Bills</h3>
                        </div>
                    </div>
                    <div className="row my-bills-info pb-4">
                        <div className="col-lg-12">
                            <p className="font-bold">Your bills for account {this.props.dashboard.selectedAccountId}</p>
                            <p>Click on the corresponding link next to a bill to view or save the bill as a PDF file.</p>
                        </div>
                    </div>
                </div>
                <div id="my-bills-table">
                    <div className="row">
                        <div className="col-lg-9 mx-auto p-0">
                            <table className="table table-bordered table-striped">
                                <thead>
                                    <tr>
                                    <th scope="col">Bill Date</th>
                                    <th scope="col">Total Amount Due</th>
                                    <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { _.map(this.props.dashboard.billList[this.state.selectedItem], (item, index) => {
                                        return(
                                        <tr key={index}>
                                            <td>{item.billDate}</td>
                                            <td>${item.totalAmount}</td>
                                            <td className="secondary-font-color font-bold regular-font-size" onClick={() => this.viewBill(item.billID)}>
                                                <p><span><img src={process.env.PUBLIC_URL + '/images/sticky-note-regular.svg'} width="16px"/></span> View Bill</p>
                                            </td>
                                        </tr>
                                        )
                                    })}    
                                </tbody>
                            </table>
                            <div className="pt-2">
                                <div className="float-left">
                                    <p>Showing {this.props.dashboard.billList[this.state.selectedItem].length} Results out of {this.state.itemCount}</p>
                                </div>
                                <div className="float-right">
                                    <p>
                                        <span className="fa fa-chevron-left prev" style={{cursor: "pointer"}} onClick={this.handlePrev}></span> 
                                        {" " + (this.state.selectedItem+1) + ' / ' + this.props.dashboard.billList.length + " "} 
                                        <span className="fa fa-chevron-right next" style={{cursor: "pointer"}} onClick={this.handleNext}></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    getViewBillData,  
})(MyBills);