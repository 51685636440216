import React, { Component } from 'react';

import Header from '../partials/header';
import Footer from '../partials/footer';

import { library } from '@fortawesome/fontawesome-svg-core'
// fa-chevron-right
import {
  faChevronRight,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  faChevronRight,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare
)

class TermsAndConditions extends Component {
    render() { 
        return (  
            <React.Fragment>
                <Header />
                <section id="terms_and_conditions">
                    <div className="container">
                        <div id="title" className="row justify-content-md-center">
                            <div className="col-lg-6">
                                <h4 className="secondary-font-color text-center">
                                    GWA BILLPAY ONLINE PAYMENT PORTAL TERMS & CONDITIONS
                                </h4>
                            </div>
                        </div>
                        <div id="body" className="row">
                            <div className="col-lg-12">
                                <p>
                                    TERMS OF USE FOR GWA'S ONLINE BILL PAYMENT PROGRAM
                                </p>
                                <label className="secondary-font-color">
                                    <strong>I. INTRODUCTION AND GENERAL INFORMATION.</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Using GWA's On-Line Payment Program allows you to pay bills on-line that are issued by Guam Waterworks Authority using a current and valid Visa, MasterCard or Discover (no other card type will be accepted).  
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> You may pay any bill on-line, including past due bills. However, since bills will not be credited to your account immediately you may be subject to disconnection while the transaction is being processed. Given the potential delays in crediting your account by paying on-line, GWA recommends that you immediately pay past-due bills in person at GWA's Upper Tumon location in order to avoid disconnection of service.  
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> You may pay someone else's bills for them using your credit card, although you will need to enter the correct GWA account information.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you have any questions regarding GWA's On-line Payment Program you may contact Guam Waterworks Authority at 647-7800 or 647-7803 between 8:00 a.m. and 5:00 p.m. Guam time, Monday through Friday, excluding Government of Guam <a href="https://guamwaterworks.org/2020-observed-holidays/" target="_blank">holidays</a>.
                                </p>
                                <label className="secondary-font-color">
                                    <strong>II. TERMS AND CONDITIONS FOR USE OF GWA'S ON-LINE BILL PAYMENT PROGRAM.</strong>
                                </label>
                                <p>
                                    By utilizing GWA's On-line Payment Program, website and associated software you do hereby agree to the following terms and conditions:
                                </p>
                                <label className="dark-font-color">
                                    <strong>A. GENERAL TERMS:</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" />  To use GWA's On-line Payment Program you must provide accurate and complete billing details supplied by the Guam Waterworks Authority and you must also provide your complete and accurate credit card information.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" />  You, and not GWA, are responsible for entering all information correctly and if you enter incorrect information you acknowledge that:
                                </p>
                                <p>
                                    1. Your bills may not be paid and all penalties associated with non-payment of your account may be added to your account.<br />
                                    2. Your water may be disconnected for non-payment.<br />
                                    3. GWA is under no responsibility to inform you that your bill has not been paid.<br />
                                    4. You are responsible for any and all fees or charges imposed by your credit card provider or your bank for any reason.<br />
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Upon providing GWA with your credit card details you agree that you are authorizing Guam Waterworks Authority to charge the specified amount to your designated account.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Where your credit card issuer or financial institution for any reason declines a payment made by you on-line those bills will be considered not paid.
                                </p>

                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Credit card payments made on-line will be debited to your credit card account immediately.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you are uncertain when a payment will be processed contact your designated financial institution.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> It is your responsibility to ensure that you have sufficient funds available on your credit card in order to allow payments to be debited immediately.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you believe GWA's On-line Payment Program transaction is incorrect or unauthorized, you must contact Guam Waterworks Customer Service Division immediately.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> The Guam Waterworks Authority has access to transaction history and may check the details of the transaction.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Should the dispute require the services of the financial institution, you may be asked to contact your financial institution. Information relating to the dispute may be provided to that financial institution by Guam Waterworks Authority to assist with resolution.
                                </p>
                                <label className="dark-font-color">
                                    <strong>B. SECURITY.</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> GWA uses standard internet banking security levels that large scale e-commerce sites utilize and which involves the use of standard SSL 128-bit encryption. You can check the security level of a web page by clicking on your Internet browser's padlock or key icon.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Encryption is the standard way of protecting your information as it is transmitted between you and Guam Waterworks Authority. This involves converting the information into an unreadable code using a "key" and also decoding it using this "key." The longer the key, the more difficult it is for others to break the encrypted codes.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> To access GWA's On-line Payment Program, your browser needs to be enabled to access these secure keys. You will need to have version 5.0 or later of Internet Explorer.
                                </p>
                                <label className="dark-font-color">
                                    <strong>C. PRIVACY.</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Personal information collected through GWA's On-line Payment Program includes your credit card details. This information is required to process your bill payments and may be shared for these purposes between Guam Waterworks Authority, banks and related financial institutions.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you wish to request access to any personal information Guam Waterworks Authority has collected about you for the purposes of GWA's On-line Payment Program please contact the General Manager for the Guam Waterworks Authority.
                                </p>
                                <label className="dark-font-color">
                                    <strong>D. WARRANTIES.</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> While every effort has been made to ensure that information is free from error, the Guam Waterworks Authority does not warrant the accuracy, adequacy or completeness of GWA's On-line Payment Program material.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Notice is hereby provided that all information on GWA's On-line Payment Program and these Terms of Use are subject to change without notice.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> The Guam Waterworks Authority does not warrant that GWA's On-line Payment Program will be free from viruses, that access to GWA's On-line Payment Program will be uninterrupted or that the data transferred through GWA's On-line Payment Program is secure and will not be read or monitored by others.
                                </p>
                                <label className="dark-font-color">
                                    <strong>E. WAIVER AND JURISDICTION.</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> The Guam Waterworks Authority makes no representations, either express or implied, that GWA's On-line Payment Program complies with the laws of any jurisdiction. If you access this site you do so at your own risk and you are responsible for complying with the laws relating to such use in the applicable jurisdiction.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> Any disputes arising out of the use of GWA's On-line Payment Program shall be governed by the laws of the Territory of Guam and other related federal statutes.
                                </p>
                                <label className="dark-font-color">
                                    <strong>F. COPYRIGHT AND TRADEMARKS.</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> GWA retains all copyright and trademarks rights and unauthorized use is prohibited.
                                </p>
                                <label className="dark-font-color">
                                    <strong>G. 	WITHDRAWAL AND SUSPENSION OF SERVICE.</strong>
                                </label>
                                <p>
                                    GWA's Online Payment Program, or your access to it, may be withdrawn or suspended for a period of time for any reason, including, without limitation, the following:
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If there is an interruption or fault in any applicable computer or telecommunications system.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you breach any of these Terms of Use.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If you use GWA's On-line Payment Program for an inappropriate purpose, including the storage or transmission of inappropriate or offensive material.
                                </p> 
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If GWA's On-line Payment Program, any applicable website computer or telecommunications systems are subjected to any virus, unauthorized access, denial-of-service or other malicious attack.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> For maintenance or security purposes.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> If Guam Waterworks Authority no longer wishes to provide GWA's On-line Payment Program.
                                </p>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> You acknowledge that withdrawal of GWA's On-line Payment Program does not release you of any responsibility to pay outstanding fees, charges or other liabilities incurred prior to the date of withdrawal.
                                </p>
                                <label className="dark-font-color">
                                    <strong>H. ACCEPTANCE AND CHANGES TO THESE TERMS OF USE.</strong>
                                </label>
                                <p>
                                    <FontAwesomeIcon icon={ faChevronRight } size="1x" /> By utilizing GWA's On-line Payment Program, website and associated software you agree to the above terms and conditions. You further acknowledge that GWA retains the right to modify the terms of use at any time with or without notice.
                                </p>
                            </div>
                            <div className="col-lg-12 text-center mb-2 mt-5">
                                <button className="btn btn-primary font-bold" onClick={() => {window.location.replace("/")}}>Back to Homepage</button>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </React.Fragment>
        );
    }
}

export default TermsAndConditions;