import React, { Component } from 'react';
import { Form, Spinner, Button } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { 
    submitHelpAndSupport,
} from '../actions/dashboardActions';
import { 
    showMessage
} from '../actions/popupMessageActions';
import { library } from '@fortawesome/fontawesome-svg-core'
// fa-chevron-right
import {
  faChevronRight,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
  faChevronRight,
  faCog,
  faSpinner,
  faQuoteLeft,
  faSquare,
  faCheckSquare
)

class HelpAndSupport extends Component {
    constructor(props){
        super(props);
        this.state = {
            emailAddress: '',
            fname: '',
            lname: '',
            contactType: 'TECHNICAL',
            message: '',
            validated: false,
            isLoading: false,
        }
    }
    onChange = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.stopPropagation();
        }
        else{
            this.setState({
                ...this.state,
                isLoading: true
            })
            const postData = {
                accountId:    this.props.dashboard.selectedAccountId,
                emailAddress: this.state.emailAddress,
                // fullName:     this.state.fname + ' ' + this.state.lname,
                fullName:     this.state.fname,
                contactType:  this.state.contactType,
                message:      this.state.message,
            }
            this.props.submitHelpAndSupport(postData)
            .then((status) => {
                if(status === 'Success'){
                    this.setState({
                        ...this.state,
                        isLoading: false
                    })
                    this.props.showMessage('success', 'Your ticket has been submitted successfully!');
                    // window.location.reload();
                }
                else{
                    this.setState({
                        ...this.state,
                        isLoading: false
                    })
                    this.props.showMessage('danger', 'An error occurred. Please try again!');
                }
            })
        }
    }
    render() { 
        const { validated } = this.state;
        return (
            <React.Fragment>
                <div className="row pb-4">
                    <div className="col-lg-8">
                        <h3 className="primary-font-color">Help & Support</h3>
                    </div>
                    <div className="col-lg-4">
                        
                    </div>
                </div>
                <div className="customer-feedback" style={{ maxWidth: "750px" }}>
                    <h5 className="dark-font-color">Customer Feedback</h5>
                    <Form
                        autocomplete="off"
                        validated={validated}
                        onSubmit={e => this.handleSubmit(e)}
                    >
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="first-name">
                                <Form.Label>Customer Name</Form.Label>
                                <Form.Control
                                autocomplete="off"
                                    required
                                    type="text"
                                    name="fname"
                                    className="valid"
                                    value={this.props.dashboard.userAccountDetails.fullName}
                                    onChange={this.onChange.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter Customer Name.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* <Form.Group as={Col} md="6" controlId="last-name">
                                <Form.Label>Last name</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="lname"
                                    onChange={this.onChange.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Last Name.
                                </Form.Control.Feedback>
                            </Form.Group> */}
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} md="6" controlId="emailAddress">
                                <Form.Label>Email Address</Form.Label>
                                <Form.Control
                                    required
                                    autocomplete="off"
                                    type="email"
                                    name="emailAddress"
                                    className="valid"
                                    value={this.props.dashboard.userAccountDetails.emailAddress}
                                    onChange={this.onChange.bind(this)}
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Email Address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            {/* <Form.Group as={Col} md="6" controlId="validationCustom04">
                                <Form.Label>Phone</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="phone"
                                />
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Phone.
                                </Form.Control.Feedback>
                            </Form.Group> */}
                            <Form.Group as={Col} md="6" controlId="contactType">
                                <Form.Label>Contact Type</Form.Label>
                                <Form.Control as="select" name="contactType" ref="subject" onChange={this.onChange.bind(this)}>
                                    <option value="TECHNICAL">Technical Query</option>
                                    <option value="COMPLAINT">Comment or Complaint</option>
                                </Form.Control>
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please select Contact Type.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        {/* <Form.Row>
                            <Form.Group as={Col} md="12" controlId="validationCustom05">
                                <Form.Label>Subject</Form.Label>
                                <Form.Control as="select" name="subject" ref="subject">
                                    <option>Subject 1</option>
                                    <option>Subject 2</option>
                                    <option>Subject 3</option>
                                    <option>Subject 4</option>
                                    <option>Subject 5</option>
                                </Form.Control>
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please select Subject.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row> */}
                        <Form.Row>
                            <Form.Group as={Col} md="12" controlId="message">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" name="message" rows="3" autocomplete="off" required onChange={this.onChange.bind(this)}/>
                                <Form.Control.Feedback id="feedback" type="invalid">
                                    Please enter your Message.
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Form.Row>
                        {
                            this.state.isLoading === true ? 
                            <button type="submit" className="btn btn-primary regular-font-size font-bold mt-2" disabled> 
                                <Spinner
                                as="span"
                                class="mt-1"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                />
                                <span className="sr-only">Loading...</span>
                            </button>
                            :
                            <button type="submit" className="btn btn-primary regular-font-size font-bold mt-2"> 
                                Submit
                            </button>
                        }
                    </Form>
                    <br />
                    <br />
                    <br />
                    <br />
                    <h5 className="dark-font-color">FAQ</h5>
                    <p className="secondary-font-color" style={{cursor: "pointer"}} onClick={() => window.location.replace("/faq")}>Click here to view</p>
                    <br />
                    {/* <div className="accordion" id="accordionExample">
                        <div class="card">
                            <div class="card-header" id="headingTwo">
                            <h5 class="mb-0">
                                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Collapsible Group Item #2
                                </button>
                            </h5>
                            </div>
                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div class="card-body">
                                Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                            </div>
                            </div>
                        </div>
                    </div> */}
                    <br />
                    <h5 className="dark-font-color">GWA’s Contact Information</h5>
                    <p className="font-bold">GWA’s location and business hours</p>
                    <p className="font-bold-lite">Gloria B. Nelson Public Service Building:</p>
                    <ul className="fa-ul dark-font-color ml-3">
                        <li><FontAwesomeIcon icon={ faChevronRight } size="1x" /> Mon-Fri 7:00am-5:00pm</li>
                    </ul>
                    <p className="font-bold-lite">Upper Tumon Satellite Office:</p>
                    <ul className="fa-ul dark-font-color ml-3">
                        <li><FontAwesomeIcon icon={ faChevronRight } size="1x" /> Mon-Fri 7:30am-6:00pm</li>
                        <li><FontAwesomeIcon icon={ faChevronRight } size="1x" /> Saturday 9:00 am to 1:00 pm</li>
                    </ul>
                    <p className="font-bold-lite">Julale Satellite Office Hagatna:</p>
                    <ul className="fa-ul dark-font-color ml-3">
                        <li><FontAwesomeIcon icon={ faChevronRight } size="1x" /> Mon-Fri 8:00am-5:00pm</li>
                    </ul>
                    <p className="font-bold-lite">All locations are closed on ALL GovGuam Holidays</p>
                    <br />
                    <h5 className="dark-font-color">GWA’s Customer Service Email</h5>
                    <p className="font-bold-lite">You can send us an email at <a href="mailto:customers@guamwaterworks.org">customers@guamwaterworks.org</a></p>
                    <br/>
                </div>
            </React.Fragment>
        ); 
    }
}


const mapStateToProps = state => ({
    users:     state.users,
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    submitHelpAndSupport,
    showMessage 
})(HelpAndSupport);