import React, { Component } from 'react';
class SuccessMessage extends Component {
    state = {  }
    componentWillMount() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }
    gotoLogin = (e) => {
        window.location.replace("/");
    }
    render() { 
        return ( 
            <React.Fragment>
                <section id="success-message-icon">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <img src={process.env.PUBLIC_URL + '/images/sign-up-success-msg-icon.png'} alt="sign-up-success-msg-icon"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-title">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <h5 className="primary-font-color">Thank you for registering your PayGWA Account.</h5>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <label className="">You will receive an email confirming your registration.</label>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <label className="">Remember to check your junk or spam folder as the email may have been caught by your spam filter.</label>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="success-message-button">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center">
                                <button type="submit" className="btn btn-primary form-button regular-font-size font-bold" onClick={this.gotoLogin.bind(this)}>Login Here</button>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}
export default SuccessMessage;