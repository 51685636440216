import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Link } from 'react-router-dom'

import AdminControl from './admin/admin-login';
import AdminDashboard from './admin/adminControl';
import SignInAccountNumber from './signInAccountNumber';
import Login from './login';
import BDLogin from './backdoorlogin';
import Dashboard from './dashboard';
import PayNow from './paynow';
import TermsAndConditions from './termsAndConditions';
import PrivacyPolicy from './privacyPolicy';
import Signup from './signup';
import Error404Page from './error404Page'
import ViewBill from './viewBill'
import PaymentResult from './paymentResult'
import $ from 'jquery'
import PopupMessage from '../components/popupMessage';
import FAQ from './faq';
import Maintenance from './maintenance';

import * as HELPER from '../actions'
class Body extends Component {
    componentWillMount(){
        // localStorage.clear()
        let baseUrl = null; 
        localStorage.removeItem('reload')
        if (window.location.protocol === 'https:') 
        { 
            if(window.location.hostname === 'localhost')
            {
                baseUrl = 'https://' + window.location.hostname + ':3000';
            }
            else
            {
                baseUrl = 'https://' + window.location.hostname;
            }
        } 
        else 
        {
            if(window.location.hostname === 'localhost')
            {
                baseUrl = 'http://' + window.location.hostname + ':3000';
            }
            else
            {
                baseUrl = 'http://' + window.location.hostname;
            }
        }
        localStorage.setItem('baseUrl', baseUrl);
    }
    
    render() { 
        return (  
            <React.Fragment>
                <PopupMessage />
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" component={Login} />
                        <Route exact path="/backdoor-login" component={BDLogin} />
                        <Route exact path="/sign-up" component={Signup} />
                        <Route exact path="/dashboard" component={Dashboard} />
                        <Route exact path="/paynow" component={PayNow} />
                        <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
                        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                        <Route exact path="/gwaadmin" component={AdminControl} />
                        <Route exact path="/admin-dashboard" component={AdminDashboard} />
                        <Route exact path="/view-bill" component={ViewBill} />
                        <Route exact path="/payment-result" component={PaymentResult} />
                        <Route exact path="/faq" component={FAQ} />
                        <Route exact path="/maintenance" component={Maintenance} />
                        <Route component={Error404Page} />
                    </Switch>
                </BrowserRouter>
            </React.Fragment>
        );
    }
}
 
export default Body;