import React, { Component } from "react";

import Header from "../../partials/header";
import Footer from "../../partials/less-content-footer";
import { connect } from "react-redux";
import {
  Alert,
  Modal,
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button,
  Navbar,
  Nav,
  Table,
  ButtonToolbar,
  DropdownButton,
  SplitButton,
  Dropdown,
  Tabs,
  Tab
} from "react-bootstrap";

import AdminLogin from "./admin-login";
import AdminUsers from "../../components/admin-control/admin-users";
import AdminSystemMaintenance from "../../components/admin-control/admin-system-maintenance";
import AdminAnnouncements from "../../components/admin-control/admin-announcements";
import AdminReports from "../../components/admin-control/admin-reports";
import AdminPhonedIn from "../../components/admin-control/admin-phonedin";
import AdminResetPassword from "../../components/admin-control/admin-reset-password";
import Loading from "../../components/loading";
import axios from "axios";
import $ from 'jquery'
import TransactionLogs from "../../components/admin-control/admin-transaction-logs";

class AdminControl extends Component {
  constructor() {
    super();
    this.state = {
      isLoggedIn: false,
      prompt: "",
      username: "",
      level: "",
      showAlertChangePass: false,
      updatePassModalShow: false,
      notEqualPassword: false,
      current_password: "",
      new_password: "",
      confirm_password: "",
      isDisabled: false
    };
  }

  componentDidMount() {
    this.checkIfLoggin();
    this.userInactivity();
  }

  userInactivity() {
    var time = new Date().getTime();
    $(document.body).bind("mousemove keypress", function(e) {
        time = new Date().getTime();
    });
    const minute = 60000;
    function refresh() {
        if(new Date().getTime() - time >= (minute*15)) {
            localStorage.clear()
            window.location.replace("/gwaadmin");
        }
        else {
            setTimeout(refresh, 10000);
        }
    }
    setTimeout(refresh, 10000); 
  }

  checkIfLoggin = () => {
    let a = localStorage.getItem("isAdminLogin");
    let b = localStorage.getItem("adminusername");
    let c = localStorage.getItem("level");

    if (a == null) {
      window.location.replace("/gwaadmin");
    }
    this.setState({ username: b, level: c });
  };

  onClick = e => {
    localStorage.clear();
    window.location.replace("/gwaadmin");
  };

  inputChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  onChangePasswordAdmin = e => {
    if (
      e.target.name === "new_password" ||
      e.target.name === "confirm_password"
    ) {
      this.setState(
        {
          ...this.state,
          [e.target.name]: e.target.value
        },
        () => {
          let pword = String(this.state.new_password);
          let confirm_pword = String(this.state.confirm_password);

          if (pword === confirm_pword) {
            this.setState({ notEqualPassword: false });
          } else {
            this.setState({ notEqualPassword: true });
          }
        }
      );
    }
  };

  handelOpenModalChangePass = () => {
    this.setState({ updatePassModalShow: true });
  };

  handleCloseModal = () => {
    this.setState({ updatePassModalShow: false });
  };

  handleCloseModalAlert = () => {
    this.setState({ showAlertChangePass: false });
  };

  submitFormUpdatePassword = e => {
    this.setState({ isDisabled: true });
    e.preventDefault();
    return axios
      .post(
        "/api/v1/update-admin-password",
        {
          id: localStorage.getItem("adminId"),
          old_password: this.state.current_password,
          new_password: this.state.new_password
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        if (response.data.result["status"] == "Success") {
          this.setState({
            prompt: response.data.result["Description"] + "!",
            updatePassModalShow: false,
            showAlertChangePass: true,
            isDisabled: false
          });
        } else {
          this.setState({
            prompt: response.data.result["Description"] + "!",
            showAlertChangePass: true,
            isDisabled: false
          });
        }
      })
      .catch(error => {
      });
  };

  render() {
    const errPwordMsg =
      this.state.notEqualPassword === true
        ? "Password doesn't match "
        : "Please enter your password.";
    const errPwordInpClass =
      this.state.notEqualPassword === true
        ? "invalid form-control"
        : "valid form-control";
    const errPwordMsgDisp =
      this.state.notEqualPassword === true
        ? "invalid_msg invalid-feedback"
        : "invalid-feedback";
    return (
      <React.Fragment>
        <Modal
          show={this.state.showAlertChangePass}
          onHide={this.handleCloseModalAlert}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-alert"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h6>{this.state.prompt}</h6>
            <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalAlert}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Okay
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.updatePassModalShow}
          onHide={this.handleCloseModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-change-pass"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <h4>Change Password</h4>
            <Form autocomplete="off" onSubmit={this.submitFormUpdatePassword.bind(this)}>
              <Form.Group controlId="">
                <Form.Label>Current Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="current_password"
                  className="valid"
                  onChange={this.inputChange.bind(this)}
                />
              </Form.Group>
              <Form.Group controlId="">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="new_password"
                  className={errPwordInpClass}
                  value={this.state.password}
                  onChange={this.onChangePasswordAdmin.bind(this)}
                />
                <Form.Control.Feedback
                  id="feedback"
                  className={errPwordMsgDisp}
                  type="invalid"
                >
                  {errPwordMsg}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="confirm_password"
                  className={errPwordInpClass}
                  value={this.state.confirmPassword}
                  onChange={this.onChangePasswordAdmin.bind(this)}
                />
                <Form.Control.Feedback
                  id="feedback"
                  className={errPwordMsgDisp}
                  type="invalid"
                >
                  {errPwordMsg}
                </Form.Control.Feedback>
              </Form.Group>

              <div className="btn-submit-change-pass">
                {this.state.isDisabled ? (
                  <button
                    type="submit"
                    className="btn btn-change-pass isDisabled-admin font-bold btn-primary"
                  >
                    Submit
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-change-pass font-bold btn-primary"
                  >
                    Submit
                  </button>
                )}
              </div>
            </Form>
          </Modal.Body>
        </Modal>

        <div id="admin-control">
          <div className="container-fluid p-0">
            <div id="nav-desktop">
              <div className="row secondary-back-color">
                <div className="col-lg-12 text-right">
                  <div className="nav-links"></div>
                </div>
              </div>
              <div className="row primary-back-color">
                <div className="col-lg-12 nav-main">
                  <div className="nav-logo">
                    <img
                      className="gpa_logo"
                      src={process.env.PUBLIC_URL + "/images/GPA-Logo.png"}
                      alt="gpa-logo"
                    ></img>
                  </div>
                  <div className="nav-buttons">
                    <div id="header-drop-admin">
                      <div className="content-left">
                        <h6>
                          Hi, <span>{this.state.username}</span>
                        </h6>
                      </div>
                      {/* 270E */}
                      <div className="content-right">
                        <ButtonToolbar>
                          <DropdownButton
                            drop="left"
                            variant="secondary"
                            id={`dropdown-button-drop-left`}
                          >
                            <Dropdown.Item
                              eventKey="1"
                              onClick={this.handelOpenModalChangePass}
                            >
                              Change Pass
                              <span>
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/images/writing.png"
                                  }
                                  alt="logout"
                                />
                              </span>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item
                              eventKey="2"
                              onClick={this.onClick.bind(this)}
                            >
                              <div class="logout-cont">
                                Log Out
                                <span>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/images/logout.png"
                                    }
                                    alt="logout"
                                  />
                                </span>
                              </div>
                            </Dropdown.Item>
                          </DropdownButton>
                        </ButtonToolbar>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
            </div>

            <Navbar id="nav-mobile" bg="light" expand="lg">
              <Navbar.Brand href="#home">
                <img
                  className="gpa_logo"
                  src={process.env.PUBLIC_URL + "/images/GPA-Logo.png"}
                  alt="gpa-logo"
                ></img>
              </Navbar.Brand>
              <div className="col-xs-12 text-right">
                <a href="/" className="dark-font-color font-bold">
                  <img
                    src={process.env.PUBLIC_URL + "/images/logout.png"}
                    alt="logout"
                  />
                </a>
              </div>
            </Navbar>
            <div id="container-top">
              <h4>Admin Control</h4>
              <br/>
              {
                this.state.level == 1 ? 
                  this.superAdmin()
                : this.state.level == 2 ? 
                  this.ITAdmin()
                : this.state.level == 3 ? 
                  this.finance()
                : this.state.level == 4 ? 
                  this.phonedIn()
                : this.state.level == 5 ? 
                  this.csFinance()
                : this.state.level == 6 ? 
                  this.announmentGroup()
                :
                null
              }
            </div>
          </div>
        </div>
        <Footer />
      </React.Fragment>
    );
  }

  superAdmin() {
    return <Tabs defaultActiveKey="users" id="uncontrolled-tab-example">
      <Tab eventKey="users" title="Users">
        <div id="admin-add-user-section">
          <AdminUsers />
        </div>
      </Tab>
      <Tab eventKey="maintenance" title="System Maintenance">
        <div id="admin-system-maintenance-section">
          {<AdminSystemMaintenance />}
        </div>
      </Tab>
      <Tab eventKey="announcements" title="Announcements">
        <div id="admin-system-maintenance-section">
          {<AdminAnnouncements />}
        </div>
      </Tab>
      <Tab eventKey="reports" title="Reports">
        <div id="reports-section">
          {<AdminReports />}
        </div>
      </Tab>
      <Tab eventKey="phonedIn" title="Phoned In">
        <div id="phonedIn-section">
          {<AdminPhonedIn adminUsername={this.state.username}/>}
        </div>
      </Tab>
      <Tab eventKey="resetPassword" title="Password Reset">
        <div id="admin-reset-password-section">
          {<AdminResetPassword/>}
        </div>
      </Tab>
      
    </Tabs>;
  }

  ITAdmin() {
    return <Tabs defaultActiveKey="maintenance" id="uncontrolled-tab-example">
      <Tab eventKey="maintenance" title="System Maintenance">
        <div id="admin-system-maintenance-section">
          {<AdminSystemMaintenance />}
        </div>
      </Tab>
      <Tab eventKey="announcements" title="Announcements">
        <div id="admin-system-maintenance-section">
          {<AdminAnnouncements />}
        </div>
      </Tab>
      <Tab eventKey="reports" title="Reports">
        <div id="reports-section">
          {<AdminReports />}
        </div>
      </Tab>
    </Tabs>;
  }

  finance() {
    return <Tabs defaultActiveKey="reports" id="uncontrolled-tab-example">
      <Tab eventKey="reports" title="Reports">
        <div id="reports-section">
          {<AdminReports />}
        </div>
      </Tab>
      <Tab eventKey="phonedIn" title="Phoned In">
        <div id="phonedIn-section">
          {<AdminPhonedIn adminUsername={this.state.username}/>}
        </div>
      </Tab>
    </Tabs>;
  }

  phonedIn() {
    return <Tabs defaultActiveKey="reports" id="uncontrolled-tab-example">
      <Tab eventKey="reports" title="Reports">
        <div id="reports-section">
          {<AdminReports />}
        </div>
      </Tab>
      <Tab eventKey="resetPassword" title="Password Reset">
        <div id="admin-reset-password-section">
          {<AdminResetPassword/>}
        </div>
      </Tab>
    </Tabs>;
  }

  csFinance() {
    return <Tabs defaultActiveKey="reports" id="uncontrolled-tab-example">
      <Tab eventKey="reports" title="Reports">
        <div id="reports-section">
          {<AdminReports />}
        </div>
      </Tab>
      <Tab eventKey="phonedIn" title="Phoned In">
        <div id="phonedIn-section">
          {<AdminPhonedIn adminUsername={this.state.username}/>}
        </div>
      </Tab>
      <Tab eventKey="resetPassword" title="Password Reset">
        <div id="admin-reset-password-section">
          {<AdminResetPassword/>}
        </div>
      </Tab>
    </Tabs>;
  }

  announmentGroup() {
    return <Tabs defaultActiveKey="announcements" id="uncontrolled-tab-example">
      <Tab eventKey="announcements" title="Announcements">
        <div id="admin-system-maintenance-section">
          {<AdminAnnouncements />}
        </div>
      </Tab>
      </Tabs>
  }
}

export default AdminControl;
