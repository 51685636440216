import React, { Component } from 'react';
import Header from '../../partials/header';
import Footer from '../../partials/less-content-footer';
import { connect } from 'react-redux';

import { Modal, Table, Col, Container, Row, Form, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';

class AdminSystemMaintenance extends Component {
  constructor(){
    super()
    this.state = {
        loginFlag: [],
        payNowFlag: [],
        showAlert: false,
        prompt: '',
        hideOk: false
    }
  }

  componentWillMount() {
    this.systeMaintenance();
  }

  systeMaintenance = () => {
    return axios
    .get('api/v1/system-maintenance', 
    {
        headers: {'Content-Type':'application/json'}
    })
    .then(response => {
      this.setState({
        loginFlag: [response.data[0].id, response.data[0].flag],
        payNowFlag:[response.data[1].id, response.data[1].flag]
      });
    });
  }

  onChangeLogin = (id, flag) => {
    let flagStats = this.state.loginFlag[1] == 0 ? 1 : 0;
    this.setState({
      loginFlag: [id, flagStats]
    }, this.updateFlag(id, flagStats));
  }
   
  onChangePaynow = (id, flag) => {
    let flagStats = this.state.payNowFlag[1] == 0 ? 1 : 0;
    this.setState({
      payNowFlag: [id, flagStats]
    }, this.updateFlag(id, flagStats));
  }

  updateFlag = (id, flag) => {
    axios.post('api/v1/system-maintenance-update',
      {
          id: id,
          flag:flag
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        this.setState({
          showAlert: true,
          prompt: 'Updated Successfully!',
          hideOk: false
        })
      })
      .catch(error => {
      });
  }

  handleCloseModalAlert = () => {
    this.setState({showAlert: false});
  }

  render() { 
    return (
      <React.Fragment>

      <Modal
        show={this.state.showAlert} 
        onHide={this.handleCloseModalAlert} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        id="modal-for-alert"
        backdrop="static"
        centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h6>{this.state.prompt}</h6>
          {
            this.state.hideOk ? 
            null :
            <div className="btn-submit-change-ok" >
              <button type="submit" onClick={this.handleCloseModalAlert} className="btn btn-change-ok font-bold btn-primary">Okay</button>
            </div>
          }
          
        </Modal.Body>
      </Modal>

        <div id="admin-system-maintenance-container">
          <Container fluid={true}>
            <Row>
              <Col>
                <h5>System Maintenance</h5>
                <Table className="table table-borderless">
                <tbody>
                  <tr>
                    <td>
                      <h6>Login / Sign Up</h6>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                    </td>
                    <td>
                      <input
                        className="react-switch-checkbox"
                        name="login"
                        id="react-switch-new"
                        type="checkbox"
                        checked={this.state.loginFlag[1]}
                        onClick={ () => this.onChangeLogin(this.state.loginFlag[0],this.state.loginFlag[1])}
                      />
                      <label
                        className="react-switch-label"
                        htmlFor="react-switch-new"
                      >
                        <span className="react-switch-button" />
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <h6>Pay Now</h6>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                    </td>
                    <td>
                      <input
                        className="react-switch-checkbox"
                        name="paynow"
                        id="react-switch-new1"
                        type="checkbox"
                        checked={this.state.payNowFlag[1]}
                        onClick={ () => this.onChangePaynow(this.state.payNowFlag[0],this.state.payNowFlag[1])}
                      />
                      <label
                        className="react-switch-label"
                        htmlFor="react-switch-new1"
                      >
                        <span className="react-switch-button" />
                      </label>
                    </td>
                    
                  </tr>
                  <tr>
                    <td>
                      <h6>Admin test login</h6>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                    </td>
                    <td>
                      <label>
                        <a href="/backdoor-login" target="_blank"><button type="submit" className="btn btn-primary font-bold"  disabled={this.state.isLoading}>{(this.state.isLoading === true) ? 'Please wait...' : 'Log In'}</button></a>
                      </label>
                    </td>
                  </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment> 
    );
  }
}

export default AdminSystemMaintenance;