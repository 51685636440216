import React, { Component } from 'react';
import { Provider } from 'react-redux'; 
import store from './store';

import Body from './pages/body';

import './AdminControl.css';
import './less-content-footer.css';
import './style.css';
import './dashboard.css';
import $ from 'jquery';

class App extends Component {
  componentWillMount() {
    const app_version = localStorage.getItem('app_version');
    if(window.location.pathname == "/dashboard"){
      if(process.env.REACT_APP_VERSION != app_version){
        localStorage.clear();
      }
    }
  }
  // componentDidCatch() {
  //   localStorage.clear();
  //   window.location.replace('/');
  //   alert('System Error! Logging out.')
  // }
  render() {
    return (
      <Provider store={store}>
        <React.Fragment>
          <Body />
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;