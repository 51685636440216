import React, { Component } from "react";
import { connect } from "react-redux";
import $ from "jquery";
import { getViewBillData } from "../actions/dashboardActions";
import _ from "lodash";
import Barcode from "react-barcode";
import { Bar } from "react-chartjs-2";
import * as moment from "moment";
import Modal from "react-modal";

class ViewBill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barcodeImgURL: "",
      isDownloadDone: false,
      screenWidth: window.screen.width,
      showModal: false,
    };
  }
  componentDidMount() {
    // if(this.state.screenWidth < 1200){
    //     this.downloadBillPDF();
    // }
    const viewBillData = this.props.dashboard.viewBillData.result;
    const billInfo2 = viewBillData["billInfo2"];
    // console.log(viewBillData);
    console.log(billInfo2);
    console.log("length", billInfo2.length);

    if (billInfo2.length == undefined) {
      if (billInfo2.bseg.reads == null || billInfo2.bseg.reads == undefined) {
        this.setState({ showModal: true });
      }
    } else {
      if (
        billInfo2[0].bseg.reads == null ||
        billInfo2[0].bseg.reads == undefined
      ) {
        this.setState({ showModal: true });
      }
    }
  }
  componentDidUpdate() {}
  downloadBillPDF = () => {
    // $("#canvas").show();
    document.getElementById("bill-form-container").style.overflow = "unset";
    const svgToDataURL = require("svg-to-dataurl");
    const dataUrl = svgToDataURL(
      '<?xml version="1.0" encoding="utf-8"?>' + $(".barcode").html()
    );
    this.setState(
      {
        barcodeImgURL: dataUrl,
      },
      () => {
        if (!(document.getElementsByClassName("barcode-img") === null)) {
          var svgString = new XMLSerializer().serializeToString(
            document.querySelector("svg")
          );
          var canvas = document.getElementById("canvas");
          var ctx = canvas.getContext("2d");
          var DOMURL = window.URL || window.webkitURL;
          var img = new Image();
          var svg = new Blob([svgString], {
            type: "image/svg+xml;charset=utf-8",
          });
          var url = DOMURL.createObjectURL(svg);
          img.onload = () => {
            ctx.drawImage(img, 0, 0);
            var png = canvas.toDataURL("image/png");
            document.querySelector("#png-container").innerHTML =
              '<img src="' + png + '"/>';
            $("#canvas").hide();
            DOMURL.revokeObjectURL(png);
            const jsPDF = require("jspdf");
            var pdf = new jsPDF("p", "px", "a4");
            var source = $("#view-bill")[0];
            pdf.addHTML(source, function () {
              pdf.save(`bill-${new Date().getTime()}.pdf`);
              const screenWidth = window.screen.width;
              if (screenWidth < 1200) {
                window.close();
              }
            });
          };
          img.src = url;

          // var c = document.getElementById('view-bill');
          // // var t = c.getContext('2d');
          // window.open('', document.getElementById('view-bill').toDataURL());
        }
      }
    );

    setInterval(() => {
      document.getElementById("bill-form-container").style.overflow = "scroll";
    }, 1000);
  };
  currBillPeriodDetails() {
    const billInfo2 = this.props.dashboard.viewBillData.result.billInfo2;
    return (
      <div className="bill-segment">
        {Array.isArray(billInfo2) ? (
          _.map(billInfo2, (item, index) => {
            return (
              <React.Fragment>
                <div className="bill-segment-content" key={index}>
                  <div className="row bsegDescrOnBill">
                    <div className="col-lg-6">
                      <p className="font-bold">
                        {item.bseg.billHeader.bsegDescrOnBill}
                      </p>
                    </div>
                    <div className="col-lg-6">
                      <p className="font-bold float-right">
                        {item.bseg.payoffAmt}
                      </p>
                    </div>
                  </div>
                  {Array.isArray(item.bseg.billHeader.calcLine) ? (
                    _.map(item.bseg.billHeader.calcLine, (item1, index1) => {
                      return (
                        <React.Fragment>
                          <div className="row calcLine" key={index1}>
                            <div style={{ width: "54%" }}>
                              <p>{item1.lineDescrOnBill}</p>
                            </div>
                            <div style={{ width: "43%" }}>
                              {index1 === 1 ? (
                                <p className="float-left">
                                  <span>
                                    {item1.lineDescrOnBill.split(" ")[0]}
                                  </span>
                                  <span>x</span>
                                  <span>
                                    {item1.lineDescrOnBill.split(" ")[3]}
                                  </span>
                                </p>
                              ) : index1 === 2 &&
                                item1.lineDescrOnBill !=
                                  "SUPPLEMENTAL ANNUITY SURCHARGE" ? (
                                <p className="float-left">
                                  <span>
                                    {item1.lineDescrOnBill.split(" ")[0]}
                                  </span>
                                  <span>x</span>
                                  <span>
                                    {item1.lineDescrOnBill.split(" ")[3]}
                                  </span>
                                </p>
                              ) : (
                                ""
                              )}
                              <p className="float-right">{item1.calcAmt}</p>
                            </div>
                          </div>
                        </React.Fragment>
                      );
                    })
                  ) : (
                    <React.Fragment>
                      <div className="row calcLine">
                        <div className="col-lg-6">
                          <p>{item.bseg.billHeader.calcLine.lineDescrOnBill}</p>
                        </div>
                        <div className="col-lg-6">
                          <p className="float-right">
                            {item.bseg.billHeader.calcLine.calcAmt}
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <React.Fragment>
            <div className="bill-segment-content">
              <div className="row bsegDescrOnBill">
                <div className="col-lg-6">
                  <p className="font-bold">
                    {billInfo2.bseg.billHeader.bsegDescrOnBill}
                  </p>
                </div>
                <div className="col-lg-6">
                  <p className="font-bold float-right">
                    {/* {billInfo2.bseg.payoffAmt} */}
                    {billInfo2?.bseg?.billHeader?.calcLine?.print === "false"
                      ? billInfo2.bseg.curAmt
                      : billInfo2.bseg.payoffAmt}
                  </p>
                </div>
              </div>
              {_.map(billInfo2.bseg.billHeader.calcLine, (item1, index1) => {
                return (
                  <React.Fragment>
                    <div className="row calcLine" key={index1}>
                      <div className="" style={{ width: "54%" }}>
                        <p>{item1.lineDescrOnBill}</p>
                      </div>
                      <div className="" style={{ width: "43%" }}>
                        {index1 === 1 ? (
                          <p className="float-left">
                            <span>{item1.lineDescrOnBill.split(" ")[0]}</span>
                            <span>x</span>
                            <span>{item1.lineDescrOnBill.split(" ")[3]}</span>
                          </p>
                        ) : index1 === 2 &&
                          item1.lineDescrOnBill !=
                            "SUPPLEMENTAL ANNUITY SURCHARGE" ? (
                          <p className="float-left">
                            <span>{item1.lineDescrOnBill.split(" ")[0]}</span>
                            <span>x</span>
                            <span>{item1.lineDescrOnBill.split(" ")[3]}</span>
                          </p>
                        ) : (
                          ""
                        )}
                        <p className="float-right">{item1.calcAmt}</p>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
  billIDBarcode() {
    const viewBillData = this.props.dashboard.viewBillData.result;
    return (
      <div className="barcode">
        <Barcode
          id="testing"
          value={viewBillData.billInfo1.billID}
          displayValue={false}
          width={1}
          height={50}
        />
      </div>
    );
  }
  formatDate = (date) => {
    return moment(date).format("MM/DD/YYYY");
  };
  render() {
    const viewBillData = this.props.dashboard.viewBillData.result;
    const topAccInfo = {
      accountId: viewBillData.billInfo1.acctID,
      customerName: viewBillData.billInfo1.billRouting.name1,
      serviceLocation:
        viewBillData.billInfo1.billSegments.serviceAddress ||
        viewBillData.billInfo1.billSegments[0].serviceAddress,
      invoiceNumber: viewBillData.billInfo1.billID,
    };
    const bottomAccInfo = {
      customerName: viewBillData.billInfo1.billRouting.entityInfo,
      address1: viewBillData.billInfo1.billRouting.address1,
      city:
        viewBillData.billInfo1.billRouting.city +
        ", " +
        viewBillData.billInfo1.billRouting.state +
        " " +
        viewBillData.billInfo1.billRouting.postal,
    };
    const bottomBillInfo = {
      accountId: viewBillData.billInfo1.acctID,
      customerName: viewBillData.billInfo1.billRouting.entityInfo,
      billDate: viewBillData.billInfo1.billDate,
      dueDate: viewBillData.billInfo1.dueDate,
      invoiceNumber: viewBillData.billInfo1.billID,
      amountDue: viewBillData.billInfo1.endingBalance,
    };
    const chargesInfo = {
      prevBalance: "$" + viewBillData.billInfo1.beginBalance,
      amountPaid: "$" + viewBillData.billInfo1.totalPayments,
      adjustments: "$" + viewBillData.billInfo1.totalAdjustments,
      corrections: "$" + viewBillData.billInfo1.totalCorrections,
      currCharges: "$" + viewBillData.billInfo1.totalCurrentCharges,
      totalAmountDue: "$" + viewBillData.billInfo1.endingBalance,
      amountDueBy: viewBillData.billInfo1.dueDate,
    };
    const bill1BillSegments = Array.isArray(viewBillData.billInfo1.billSegments)
      ? viewBillData.billInfo1.billSegments[0]
      : viewBillData.billInfo1.billSegments;
    let date1 = new Date(bill1BillSegments.startDate);
    let date2 = new Date(bill1BillSegments.endDate);
    let Difference_In_Time = date2.getTime() - date1.getTime();
    let Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24) + 1;

    const billInfo2 = Array.isArray(viewBillData.billInfo2)
      ? viewBillData.billInfo2[0]
      : viewBillData.billInfo2;

    //console.log('billInfo2', billInfo2)
    const consumptionDetailsLeft = {
      dateApplied: moment(viewBillData.billInfo1.billDate).format("MM/DD/YYYY"),
      readDate: moment(bill1BillSegments.endDate).format("MM/DD/YYYY"),
      meterNumber: billInfo2.bseg.reads
        ? billInfo2.bseg.reads?.badgeNbr
          ? billInfo2.bseg.reads.badgeNbr
          : billInfo2.bseg.reads[0].badgeNbr
        : "N/A",
      billTable: billInfo2.bseg.billHeader.rate,
      mult: billInfo2.bseg.reads
        ? "00000" +
          (billInfo2.bseg.reads?.gcMultiplier
            ? billInfo2.bseg.reads.gcMultiplier
            : billInfo2.bseg.reads[0].gcMultiplier)
        : "N/A",
      days: Difference_In_Days,
    };

    let usage = billInfo2.bseg.reads
      ? billInfo2.bseg.reads?.finalRegQty
        ? (billInfo2.bseg.reads?.finalRegQty).split(".")[0]
        : (billInfo2.bseg.reads[0]?.finalRegQty).split(".")[0]
      : "N/A";

    const consumptionDetailsRight = {
      currReadingGal: billInfo2.bseg.reads
        ? billInfo2.bseg.reads?.endRegRead
          ? billInfo2.bseg.reads.endRegRead.split(".")[0]
          : billInfo2.bseg.reads[0].endRegRead.split(".")[0]
        : "N/A",
      prevReadingGal: billInfo2.bseg.reads
        ? billInfo2.bseg.reads?.startRegRead
          ? billInfo2.bseg.reads.startRegRead.split(".")[0]
          : billInfo2.bseg.reads[0].startRegRead.split(".")[0]
        : "N/A",
      usage: usage,
      unit: billInfo2.bseg.reads
        ? billInfo2.bseg.reads?.finalUOM
          ? billInfo2.bseg.reads?.finalUOM
          : billInfo2.bseg.reads[0].finalUOM
        : "N/A",
      dailyAverage: Math.round(usage / Difference_In_Days),
    };

    const billInfo3 = this.props.dashboard.viewBillData.result.billInfo3;
    let dateLabels = [],
      monthlyusage = [];
    for (var count = 0; count < billInfo3.length; count++) {
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      if (count == billInfo3.length - 1) {
        const currDate = new Date();

        var date1a = new Date(billInfo3[count].endReadDate.slice(0, 10));
        dateLabels.push(months[date1a.getMonth()]);
        monthlyusage.push(billInfo3[count].measuredQuantity.split(".")[0]);

        const { totalWater } = this.props.dashboard.consumptionDetails;
        var date = new Date(consumptionDetailsLeft.readDate.slice(0, 10));
        dateLabels.push(months[date1a.getMonth() - 1]);
        monthlyusage.push(
          totalWater[
            totalWater.length -
              (currDate.getMonth() - (date1a.getMonth() - 1) + 1)
          ]
        );
        // console.log('[totalWater.length - (currDate - date1a.getMonth()-1)]', (currDate.getMonth() - (date1a.getMonth()-1))+1)
      } else {
        var date = new Date(billInfo3[count].endReadDate.slice(0, 10));
        dateLabels.push(months[date.getMonth()]);
        monthlyusage.push(billInfo3[count].measuredQuantity.split(".")[0]);
      }
    }
    const chartData = {
      labels: dateLabels,
      datasets: [
        {
          data: monthlyusage,
          backgroundColor: ["#1788c7", "#1788c7", "#1788c7", "#1788c7"],
        },
      ],
    };
    const options = {
      animation: {
        duration: 0,
      },
      tooltips: {
        callbacks: {
          title: function (tooltipItem, data) {
            const startDate = data["labels"][tooltipItem[0]["index"]][0];
            const endDate = data["labels"][tooltipItem[0]["index"]][2];
            return startDate + " - " + endDate;
          },
        },
      },
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: { display: false },
            ticks: {
              fontFamily: "sans-serif",
              fontStyle: "bold",
              fontColor: "#333333",
              fontSize: 14,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              steps: 4,
              beginAtZero: true,
              fontFamily: "sans-serif",
              fontStyle: "bold",
              fontColor: "#333333",
              fontSize: 14,
              userCallback: function (value, index, values) {
                return value;
              },
            },
          },
        ],
      },
      responsive: true,
    };

    const customModalStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        paddingLeft: 50,
        paddingRight: 50,
        borderRadius: 20,
        width: 600,
      },
    };

    const billingPeriod =
      this.formatDate(bill1BillSegments.startDate) +
      " - " +
      this.formatDate(bill1BillSegments.endDate);

    return (
      <React.Fragment>
        {/* {
                    this.state.screenWidth < 1200 ?
                        <div className="overlay"></div>
                    :
                        ''
                } */}

        <Modal
          isOpen={this.state.showModal}
          onRequestClose={() => this.setState({ showModal: false })}
          style={customModalStyles}
        >
          <center>
            <div>
              <b>
                Something went wrong upon loading bill, Please contact GWA
                support
              </b>
            </div>
          </center>
          <br />
          <p>Missing values: </p>
          <ul>
            <li>reads.badgeNbr</li>
            <li>reads.gcMultiplier</li>
            <li>reads.endRegRead</li>
            <li>reads.startRegRead</li>
          </ul>

          <center>
            <button
              class="btn btn-danger regular-font-size font-bold float-none"
              onClick={() => this.setState({ showModal: false })}
            >
              Close
            </button>
          </center>
        </Modal>

        <div id="view-bill-actions">
          {this.props.isModalMode ? (
            <button
              id="btn-download"
              onClick={() => {
                this.downloadBillPDF();
              }}
              className="btn btn-primary download regular-font-size font-bold float-none"
            >
              Download
            </button>
          ) : (
            <React.Fragment>
              <button
                onClick={() => {
                  document.getElementById("btn-my-bills").click();
                }}
                className="btn btn-primary back regular-font-size font-bold"
              >
                Go Back to My Bills
              </button>
              <button
                id="btn-download"
                onClick={() => {
                  this.downloadBillPDF();
                }}
                className="btn btn-primary download regular-font-size font-bold"
              >
                Download
              </button>
            </React.Fragment>
          )}
        </div>
        <div id="view-bill-spacer"></div>

        <div id="view-bill">
          <div className="row">
            <div className="col-lg-12">
              <div className="bill-template">
                <img
                  src={process.env.PUBLIC_URL + "/images/viewBillTemplate.png"}
                  alt="bill-template"
                />
                <div className="top-account-info">
                  <p>{topAccInfo.accountId}</p>
                  <p>{topAccInfo.customerName}</p>
                  <p style={{ fontSize: "13px", margin: "2px 0px" }}>
                    {topAccInfo.serviceLocation.split(",")[0] +
                      ", " +
                      topAccInfo.serviceLocation.split(",")[1]}
                  </p>
                  <p>{topAccInfo.invoiceNumber}</p>
                </div>
                <p className="charges-info prevBalance">
                  {chargesInfo.prevBalance}
                </p>
                <p className="charges-info amountPaid">
                  {chargesInfo.amountPaid}
                </p>
                <p className="charges-info adjustments">
                  {chargesInfo.adjustments}
                </p>
                <p className="charges-info corrections">
                  {chargesInfo.corrections}
                </p>
                <p className="charges-info currCharges">
                  {chargesInfo.currCharges}
                </p>
                <p className="charges-info totalAmountDue">
                  {chargesInfo.totalAmountDue}
                </p>
                <p className="charges-info amountDueBy">
                  {this.formatDate(chargesInfo.amountDueBy)}
                </p>
                <div className="consumption-details-left">
                  <p>{consumptionDetailsLeft.readDate || "N/A"}</p>
                  <p>{consumptionDetailsLeft.meterNumber || "N/A"}</p>
                  <p>{consumptionDetailsLeft.billTable || "N/A"}</p>
                  <p>{consumptionDetailsLeft.mult || "N/A"}</p>
                  <p>{consumptionDetailsLeft.days || "N/A"}</p>
                </div>
                <div className="consumption-details-right">
                  <p>{consumptionDetailsRight.currReadingGal || "N/A"}</p>
                  <p>{consumptionDetailsRight.prevReadingGal || "N/A"}</p>
                  <p>{consumptionDetailsRight.usage || "N/A"}</p>
                  <p>{consumptionDetailsRight.unit || "N/A"}</p>
                  <p>{consumptionDetailsRight.dailyAverage || "N/A"}</p>
                </div>
                {this.currBillPeriodDetails()}
                {viewBillData.billInfo1.billMessages != undefined ? (
                  <div className="bill-message">
                    {viewBillData.billInfo1.billMessages.billMessageDesc}
                  </div>
                ) : null}

                <div className="bottom-bill-info">
                  <p>{bottomBillInfo.accountId}</p>
                  <p>{bottomBillInfo.customerName}</p>
                  <p>{bottomBillInfo.billDate}</p>
                  <p>{bottomBillInfo.dueDate}</p>
                  <p>{bottomBillInfo.invoiceNumber}</p>
                  <p className="font-bold">${bottomBillInfo.amountDue}</p>
                </div>
                <div className="bottom-acc-info">
                  <p>{bottomAccInfo.customerName}</p>
                  <p>{bottomAccInfo.address1}</p>
                  <p>{bottomAccInfo.city}</p>
                </div>

                {this.billIDBarcode()}
                <canvas id="canvas" width="200" height="100"></canvas>
                <div id="png-container" className="barcode"></div>
                {/* {
                                    !(this.state.barcodeImgURL === '') ?
                                        <div className="barcode-img">
                                            <img src={this.state.barcodeImgURL} alt=""/>
                                        </div>
                                    :
                                    this.billIDBarcode()
                                 } */}
                <div className="bar-chart">
                  <p>Mothly Water Usage History</p>
                  <Bar data={chartData} options={options} redraw />
                </div>
                <div className="billing-period">
                  <p className="light-font-color font-bold">{billingPeriod}</p>
                </div>
                <div className="date-applied">
                  <p className="light-font-color font-bold">
                    {this.formatDate(consumptionDetailsLeft.dateApplied)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  getViewBillData,
})(ViewBill);
