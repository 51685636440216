import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';
import EditAccount from '../components/editAccount';
import { connect } from 'react-redux';
import { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest 
} from '../actions/dashboardActions';
import _ from 'lodash';
import Loading from './loading';
class AccountOverview extends Component {
    constructor(props) {
        super(props)
        this.showEdit = this.showEdit.bind(this);
        this.state = {
            userAccDetails: {
                accountId: '',
                personId: '',
                fullName: '',
                addressLine1: '',
                city: '',
                state: '',
                postal: '',
                country: '',
                homePhone: '',
                mobilePhone: '',
                workPhone: '',
                emailAddress: '',
            },
            userLatestBill: {
                billDate: '',
                totalAmount: '',
                dueDate: ''
            },
            consumptionViewBy: 'monthlyWater',
            isHaveConsumption: false,
            waterOptions: {},
            amountsOptions: {},
            waterChartData:{},  
            amountsChartData: {},
            isEdit: false,  
            validated: false,
            selectedAccountId: '',
            selectedPremiseAddress: '',
            isLoading: true,
            isMultipleAccount: false
        }
    }

    componentWillMount() {
        //session storage key search
        let sessionAccountId, sessionPersonId;
        for(var count = 0; count < Object.keys(localStorage).length; count++){
            if(!(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex")){
                if(Object.keys(localStorage)[count] === 'accountId'){
                    var accountId = [];
                    var arrAccountId = localStorage.getItem(Object.keys(localStorage)[count]).split(",")
                    accountId.push([arrAccountId[0], arrAccountId[1]])
                    sessionAccountId = accountId
                }
                else if(Object.keys(localStorage)[count] === 'accountIds'){
                    var evenElements = [], oddElements = [], accountIds = [];
                    var arrAccountIds = localStorage.getItem(Object.keys(localStorage)[count]).split(",")
                    for(var count = 0; count < arrAccountIds.length; count++){
                        if(count % 2 === 0){
                            evenElements.push(arrAccountIds[count])
                        }
                        else{
                            oddElements.push(arrAccountIds[count])
                        }
                    }
                    for(var count = 0; count < evenElements.length; count++){
                        accountIds.push([evenElements[count], oddElements[count]])
                    }
                    sessionAccountId = accountIds;
                }
            }
        }
        for(var count = 0; count < Object.keys(localStorage).length; count++){
            if(!(Object.keys(localStorage)[count] === "baseUrl" || Object.keys(localStorage)[count] === "ruulzIndex")){
                if(Object.keys(localStorage)[count] === 'personId'){
                    sessionPersonId = localStorage.getItem(Object.keys(localStorage)[count]);
                }
            }
        }
        this.setState({
            ...this.state,
            selectedAccountId: this.props.dashboard.selectedAccountId,
            selectedPremiseAddress: this.props.dashboard.selectedPremAdd,
            isMultipleAccount: sessionAccountId.length > 1 ? true : false,
            userAccDetails: {
                ...this.state.userAccDetails,
                accountId: sessionAccountId,
                personId: sessionPersonId
            }
        },() => {
            const accountId = this.state.selectedAccountId
            const personId = sessionPersonId
            this.props.saveAccountId(accountId)
            this.props.fetchMultipleAddOpptyRequest(accountId, personId)
            .then((status) => {
                this.updateState(status)
                if(this.props.viewAccountInfo){
                    var elmnt = document.getElementById("info-wrap");
                    elmnt.scrollIntoView();
                }
            })
        })
    }

    updateState = (status) => {
        const userAccountDetails = this.props.dashboard.userAccountDetails;
        const latestBill = this.props.dashboard.latestBill;
        this.setState({
            ...this.state,
            consumptionViewBy: 'monthlyWater',
            userAccDetails: {
                ...this.state.userAccDetails,
                fullName:     userAccountDetails.fullName,
                addressLine1: userAccountDetails.addressLine1,
                city:         userAccountDetails.city,
                state:        userAccountDetails.state,
                postal:       userAccountDetails.postal,
                country:      userAccountDetails.country,
                homePhone:    userAccountDetails.homePhone,
                mobilePhone:  userAccountDetails.mobilePhone,
                workPhone:    userAccountDetails.workPhone,
                emailAddress: userAccountDetails.emailAddress,
            },
            userLatestBill: {
                ...this.state.userLatestBill,
                billDate:    latestBill.billDate,
                totalAmount: latestBill.totalAmount,
                dueDate:     latestBill.dueDate
            },
        }, () => {
            //update details for consumption chart
            if(status !== "False"){
                this.setState({
                    ...this.state,
                    waterOptions: {
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem, data) {
                                    const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                    const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                    return startDate + ' - ' + endDate;
                                },
                            }
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,
                                gridLines: { display: false },
                                ticks: {
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    steps: 7,
                                    // max: highestKwh,
                                    beginAtZero: true, 
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                }
                            }]
                        },
                        responsive: true,
                    },
                    amountsOptions: {
                        tooltips: {
                            callbacks: {
                                title: function(tooltipItem, data) {
                                    const startDate =  data['labels'][tooltipItem[0]['index']][0];
                                    const endDate =  data['labels'][tooltipItem[0]['index']][2];
                                    return startDate + ' - ' + endDate;
                                },
                            }
                        },
                        legend: {
                            display: false
                        },
                        scales: {
                            xAxes: [{
                                stacked: true,
                                gridLines: { display: false },
                                ticks: {
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                }
                            }],
                            yAxes: [{
                                ticks: {
                                    steps: 7,
                                    beginAtZero: true, 
                                    fontFamily: "Montserrat",
                                    fontStyle: "bold",
                                    fontColor: '#333333',
                                    fontSize: 14,
                                    userCallback: function(value, index, values) {
                                        return '$ ' + value;
                                    }
                                }
                            }]
                        },
                        responsive: true,
                    },
                    waterChartData:{
                        labels: this.props.dashboard.consumptionDetails.months,
                        datasets:[
                            {
                                label: 'Water Consumption',
                                data: this.props.dashboard.consumptionDetails.totalWater,
                                backgroundColor:[
                                    '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7',
                                ]
                            }
                        ],
                    },
                    amountsChartData:{
                        labels: this.props.dashboard.consumptionDetails.months,
                        datasets:[
                            {
                                label: 'Bill Amount',
                                data: this.props.dashboard.consumptionDetails.amounts,
                                backgroundColor:[
                                    '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7', '#1788c7','#1788c7','#1788c7','#1788c7','#1788c7','#1788c7',
                                ]
                            }
                        ],
                    }, 
                }, () => {
                    this.setState({
                        isHaveConsumption: true,
                        isLoading: false
                    })
                })
            }
            else{
                this.setState({
                    isLoading: false
                })
            }
        })
    }

    showEdit = e =>{
        e.preventDefault();
        this.setState({ isEdit: true })
    }

    onChangeSelect = (e) => {
        const selectedAccId   = e.target.value.split("@-@")[0] 
        const selectedPremAdd = e.target.value.split("@-@")[1] 
        const personId = this.state.userAccDetails.personId
        this.props.saveAccountId(selectedAccId)
        this.props.savePremiseAddress(selectedPremAdd)
        this.setState({
            ...this.state,
            selectedAccountId: selectedAccId,
            selectedPremiseAddress: selectedPremAdd,
            isLoading: true
        }, () => {
            this.props.fetchMultipleAddOpptyRequest(selectedAccId, personId)
            .then((status) => {
                this.updateState(status)
            })
            .catch((error)=>{
                if(!alert(error+'\n\nTry Again Later!')){
                    window.location.reload();
                }
            })
        })   
    }

    changeDataSet(e){
        this.setState({
            ...this.state,
            consumptionViewBy: e.target.value
        })
    }
    
    getAverage = () => {
        const consumptionDetails = this.props.dashboard.consumptionDetails;
        if(this.state.isHaveConsumption === true){
            if(this.state.consumptionViewBy === 'monthlyBill'){
                const totalAmounts = consumptionDetails.amounts
                let sum         = 0;
                let average     = 0;
                for(var count = 0; count < totalAmounts.length; count++){
                    sum += parseInt(totalAmounts[count])                  
                }
                average = sum / totalAmounts.length
                return '$ '+String(Math.round(average))
            }
            else if(this.state.consumptionViewBy === 'monthlyWater'){
                const totalWater = consumptionDetails.totalWater
                let sum         = 0;
                let average     = 0;
                for(var count=0; count < totalWater.length; count++){
                    sum += totalWater[count]                      
                }
                average = sum / totalWater.length
                return String(Math.round(average))+' Gallons'
            }
        }
    }

    render() { 
        //customer name modification
        String.prototype.replaceAt=function(index, replacement) {
            return this.substr(0, index) + replacement+ this.substr(index + replacement.length);
        }
        var fullName  = this.state.userAccDetails.fullName;
        var firstName = fullName.substr(0,fullName.indexOf(' '))
        var lastName  = fullName.substr(fullName.indexOf(' ')+1)
        if(fullName.includes(',')){
            firstName = fullName.substr(fullName.indexOf(',')+1)
            lastName  = fullName.substr(0,fullName.indexOf(','))
        }
        //user details
        const userAccountId     = this.state.userAccDetails.accountId;
        const selectedAccountId = String(this.state.selectedAccountId).substr(0, 10)+'';
        const userFullname      = firstName+' '+lastName;
        //const userFullname = lastName;
        const addressLine1      = this.state.userAccDetails.addressLine1;
        const city              = this.state.userAccDetails.city;
        const state             = this.state.userAccDetails.state;
        const postal            = this.state.userAccDetails.postal;
        const country           = this.state.userAccDetails.country;
        const homePhone         = this.state.userAccDetails.homePhone;
        const mobilePhone       = this.state.userAccDetails.mobilePhone;
        const workPhone         = this.state.userAccDetails.workPhone;
        const emailAddress      = this.state.userAccDetails.emailAddress;
        const premiseAddress    = this.state.selectedPremiseAddress;
        //user latest bill
        const billDate    = this.state.userLatestBill.billDate;
        const totalAmount = this.state.userLatestBill.totalAmount;
        const dueDate     = this.state.userLatestBill.dueDate;
        return (
            this.state.isEdit === true ?
                <EditAccount accountId={selectedAccountId}/>
            :  
            this.state.isLoading === true ?
            <Loading />
            :
            <React.Fragment>
                <div id="elementH"></div>
                <div id="welcome-wrap">
                <div className="row pb-1">
                        <div className="col-lg-8">
                            <h3 className="primary-font-color">Hi {userFullname}!</h3>
                            <p className="font-bold">Welcome to your GWA member dashboard</p>
                        </div>
                        <div className="col-lg-4">
                            {/* <div className="btn-logout">
                                <a href="/"><p className="regular-font-size font-bold">Log Out <span><img src={process.env.PUBLIC_URL + '/images/logout.png'} alt="logout"/></span></p></a>
                            </div> */}
                        </div>
                    </div>
                    <div className="row account-overview">
                        <div className="col-lg-12 bill-summary">
                            <div className="row">
                                <div className="col-lg-12 header">
                                    <p>Account Summary for Account  Number:</p>
                                    {
                                        (userAccountId.length > 1) ? 
                                        <select id="accountId" onChange={this.onChangeSelect.bind(this)} defaultValue={selectedAccountId+ '@-@' +premiseAddress}>
                                            {
                                                _.map(userAccountId, (item, index) => {
                                                    return(
                                                        <option key={index} value={item[0] + '@-@' + item[1]}>
                                                        { 
                                                            (item[1] !== '') ? 
                                                                (item[0] + ' - ' + item[1]).substr(0, 42) + '...'
                                                            :
                                                            item[0]
                                                        }
                                                        </option>
                                                    )
                                                })
                                            }
                                        </select>
                                        : 
                                        <input type="text" autocomplete value={(userAccountId[0][0] + ' - ' + userAccountId[0][1]).substr(0, 46) + '...'}/>
                                    }
                                </div>
                                <div className="col-lg-3 p-0"> 
                                    <div className="text-center border">
                                        <p>Bill Date:</p>
                                    </div>
                                    <div className="text-center border border-top-0">
                                        <h5>{billDate}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 p-0">
                                    <div className="text-center border">
                                        <p>Total Amount Due:</p>
                                    </div>
                                    <div className="text-center border border-top-0">
                                        <h5 className="primary-font-color">$ {totalAmount}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 p-0">
                                    <div className="text-center border">
                                        <p>Due Date:</p>
                                    </div>
                                    <div className="text-center border border-top-0">
                                        <h5>{dueDate}</h5>
                                    </div>
                                </div>
                                <div className="col-lg-3 p-0">
                                    <div className="text-center border pay-now">
                                        <a href="/paynow"><button type="submit" className="btn btn-primary regular-font-size font-bold">Pay Now</button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isHaveConsumption === true ? 
                <div id="bar-chart-wrap" className="border">
                    <div className="row top">
                        <div className="col-lg-5 text-center">
                            <label className="primary-font-color font-bold">
                                {
                                    (this.state.consumptionViewBy === 'monthlyBill') ? 
                                    'Your Monthly Water Bills Chart' :
                                    (this.state.consumptionViewBy === 'monthlyWater') ?
                                    'Your Monthly Water Consumption Chart' : ''
                                }
                            </label>
                        </div>
                        <div className="col-lg-5 text-center">
                            <p>View By:</p>
                            <select onChange={this.changeDataSet.bind(this)} defaultValue={this.state.consumptionViewBy}>
                                <option value="monthlyWater">Monthly Water Consumption</option>
                                <option value="monthlyBill">Monthly Bill Amount</option>
                            </select>
                        </div>
                    </div>
                    <div className="row bottom">
                        <div className="col-lg-1 graph-label">
                            <label className="font-bold">
                                {
                                    (this.state.consumptionViewBy === 'monthlyBill') ? 
                                    'USD' :
                                    (this.state.consumptionViewBy === 'monthlyWater') ?
                                    'Gallons' : ''
                                }
                            </label>
                        </div>
                        <div className="col-lg-8 scroll-hidden-mobile">
                            <div id="bar-scroll-mobile">
                                {
                                    <Bar
                                        data={  
                                                (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                this.state.amountsChartData :
                                                (this.state.consumptionViewBy === 'monthlyWater') ?
                                                this.state.waterChartData : ''
                                                }
                                        height={160}
                                        options={
                                                (this.state.consumptionViewBy === 'monthlyBill') ? 
                                                this.state.amountsOptions :
                                                (this.state.consumptionViewBy === 'monthlyWater') ?
                                                this.state.waterOptions : ''
                                                }
                                        redraw
                                    />
                                }
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="text-center">
                                <label>
                                    {
                                        (this.state.consumptionViewBy === 'monthlyBill') ? 
                                        'Average Bill for 12 Months' :
                                        (this.state.consumptionViewBy === 'monthlyWater') ?
                                        'Average Usage for 12 Months' : ''
                                    }
                                </label>
                                <h4>
                                    {
                                        this.getAverage()
                                    }
                                </h4>
                            </div>
                        </div>
                        {/* <div className="content-right">
                        </div> */}
                    </div>
                </div>
                :
                <center>
                    <div className="p-5 m-5">
                        <h3>No consumption chart available</h3>
                    </div>
                </center>
                }
                <div id="info-wrap" className="border">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="account-info">
                                <div className="header">
                                    <label className="secondary-font-color font-bold">Account Information</label>
                                    {/* <a href="/" className="secondary-font-color"><span><img src={process.env.PUBLIC_URL + '/images/add.png'} /></span> Add New Account</a> */}
                                    {
                                        (!this.state.isMultipleAccount) ?
                                            <a href="/" className="primary-font-color" onClick={this.showEdit}><span><img src={process.env.PUBLIC_URL + '/images/edit-user.png'} /></span> Edit Account</a>
                                        :
                                            ''
                                    }
                                </div>
                                <hr/>
                                <div className="body">
                                    <p>Name: {userFullname}</p>
                                    <p>Account Number:  <strong>{selectedAccountId}</strong></p>
                                    {/* <p>Meter Number - 02334342</p>
                                    <p>Service Type - Water</p>
                                    <p>Tariff - Electric Residential Schedule R</p>
                                    <p>Service Address - 191 CAMELIA LN, BARRIGADA, Guam, 96913, United States of America</p> */}
                                </div>
                            </div>
                            <br />
                            <br />
                            <div className="contact-info">
                                <div className="header">
                                    <label className="secondary-font-color font-bold">Contact Information</label>
                                </div>
                                <hr/>
                                <div className="body">
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <p><strong>Premise Address</strong></p>
                                            <p>{premiseAddress}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p><strong>Mailing Address</strong></p>
                                            <p>{addressLine1+' '+city+' '+state+' '+postal+' '+country}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p><strong>Phone Numbers</strong></p>
                                            <p>Home: {homePhone}</p>
                                            <p>Mobile: {mobilePhone}</p>
                                            <p>Work: {workPhone}</p>
                                        </div>
                                        <div className="col-lg-3">
                                            <p><strong>Email Address</strong></p>
                                            <p>{emailAddress}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest 
})(AccountOverview);