import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal,Spinner } from 'react-bootstrap';
import { 
    fetchBillsList 
} from '../actions/dashboardActions';
import _ from 'lodash';
import Loading from './loading';
import BtnViewBill from './btnViewBill'
import { 
    showMessage
} from '../actions/popupMessageActions';
import currencyFormatter from 'currency-formatter';

class ModalMyBills extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
          show: false,
          isLoading: true,
          fetched_bills_per_id: [],
        };
    }
    
    showModal = (e) => {
        this.setState({ 
            show: true,
            isLoading: true
        });
        // console.log('this.state.isLoading', this.state.isLoading)
        if(this.props.accId){
            // console.log('napasok dito')
            this.props.fetchBillsList(this.props.accId)
            .then((response) => {
                // console.log('response', response);
                this.setState({ fetched_bills_per_id: response })
                this.setState({ isLoading: false });
            })
            .catch((error)=>{
                this.props.showMessage('danger', "Server Error. Try again later!");
                console.log('error', error)
            })
        }
    }

    hideModal = () => {
        this.setState({ 
            show: false,
            fetched_bills_per_id: []
        });
    }

    render() { 

        const AddFormatToAmount = amount => {
            let format = 0;
           if (amount) {
                format = currencyFormatter.format(amount, { code: 'USD' });
                return format;
           }
           return format;
        }

        console.log(this.state.fetched_bills_per_id)

        return ( 
            <React.Fragment>
                <Modal
                    id="my-bills-modal"
                    show={this.state.show}
                    onHide={this.hideModal.bind(this)}
                    dialogClassName="modal-90w"
                    aria-labelledby="example-custom-modal-styling-title"
                    centered
                >
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            this.state.isLoading === true ?
                            <React.Fragment>
                                <center>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="md"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                </center>
                                <br/>
                            </React.Fragment>
                            :
                            <React.Fragment>
                            <div className="my-bills-title">
                                <label className="light-font-color font-bold">My Bills</label>
                            </div>
                            <div id="my-bills-table">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <table className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                <th scope="col" className="text-center">Date of Bill</th>
                                                <th scope="col" className="text-center">Current Charges</th>
                                                <th scope="col" className="text-center">View Bill</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { _.map(this.state.fetched_bills_per_id, (item, index) => {
                                                    return(
                                                        index < 18 ?
                                                            <tr key={index}>
                                                                <td>{item.billDate}</td>
                                                                {/* <td className="text-right">${parseFloat(Math.round(item.totalAmount * 100) / 100).toFixed(2)}</td> */}
                                                                <td className="text-right">
                                                                    {AddFormatToAmount(parseFloat(Math.round(item.totalAmount * 100) / 100))}
                                                                </td>
                                                                <BtnViewBill billId={item.billID} personId={localStorage.getItem('personId')}/>
                                                            </tr>
                                                        :
                                                            ""
                                                    )
                                                })}    
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>
                <p style={{margin: "0"}} onClick={this.showModal.bind(this)}><img src={process.env.PUBLIC_URL + '/images/sticky-note-regular.svg'} alt="sticky note" width="16px"/> View Bill</p>
            </React.Fragment>
        );
    }
}
    
const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    fetchBillsList,  
    showMessage,
})(ModalMyBills);