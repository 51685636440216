import React, { Component } from 'react';

class Maitenance extends Component {
    state = {  }
    render() { 
        return ( 
            <div className="container">
                {
         
                    <div className="row mt-3 mb-3 maintenance-wrap">
                        <div className="col-lg-12 text-center">
                            <img className="maintenance-logo" src={process.env.PUBLIC_URL + '/images/maintenance-logo.png'}/>
                        </div>
                        <div className="col-lg-12">
                            <h1 className="arial text-center secondary-font-color" style={{fontSize: "60px"}}>THE NEW PAYGWA.COM<br/>WILL BE LAUNCHING SOON!</h1>
                            <h3 className="arial text-center font-bold" style={{color: "#005878"}}>THE SITE WILL BE TEMPORARILY UNDER MAINTENANCE<br/>AND WILL BE BACK SHORTLY.</h3>
                        </div>
                        <div className="col-lg-12 text-center pt-3">
                            <img className="content-img"style={{width: "50%"}} src={process.env.PUBLIC_URL + '/images/PAY-GWA-HERO.png'} alt="GPA-Logo"/>
                        </div>
                        <div className="col-lg-12 pt-3">
                            <h3 className="arial text-center font-bold" style={{color: "#005878"}}>WE APPRECIATE YOUR PATIENCE AND LOOK FORWARD<br/>TO IMPROVING YOUR PAYGWA.COM EXPERIENCE.</h3>
                        </div>
                    </div>
                    
                }
                
            </div>
        );
    }
}
 
export default Maitenance;