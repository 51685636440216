import { 
    SAVE_STEP1_DATA, 
    SAVE_STEP2_DATA, 
    SAVE_STEP3_DATA,
    CHECK_STEP1_RESPONSE,
    SAVE_STEP_CONFIG
} from '../actions/types';

const initialState = {
    step1Data: {
        validated: false,
        accountId: "",
        zipcode: "",
    },
    step2Data: {},
    step3Data: {},
    validateZipCodeRes: {},
    formConfig: {
        step1: true,
        step2: false,
        step3: false,
        accountId: '',
        showMessage: 'none',
        resultMessage: '',
        showSample: false,
        isLoading: false,
        isSignInSuccess: false,
        btnSubmitValue: "Continue"
    }
}

export default function(state = initialState, action){
    switch(action.type){
        case SAVE_STEP_CONFIG:
            return {
                ...state,
                formConfig: {
                    ...state.formConfig,
                    step1: action.payload.step1 === undefined ? {...state.formConfig.step1} : action.payload.step1,
                    step2: action.payload.step2 === undefined ? {...state.formConfig.step2} : action.payload.step2,
                    step3: action.payload.step3 === undefined ? {...state.formConfig.step3} : action.payload.step3,
                    accountId: action.payload.accountId === undefined ? {...state.formConfig.accountId} : action.payload.accountId,
                    showMessage: action.payload.showMessage === undefined ? {...state.formConfig.showMessage} : action.payload.showMessage,
                    resultMessage: action.payload.resultMessage === undefined ? {...state.formConfig.resultMessage} : action.payload.resultMessage,
                    showSample: action.payload.showSample === undefined ? {...state.formConfig.showSample} : action.payload.showSample,
                    isLoading: action.payload.isLoading === undefined ? {...state.formConfig.isLoading} : action.payload.isLoading,
                    isSignInSuccess: action.payload.isSignInSuccess === undefined ? {...state.formConfig.isSignInSuccess} : action.payload.isSignInSuccess,
                    btnSubmitValue: action.payload.btnSubmitValue === undefined ? {...state.formConfig.btnSubmitValue} : action.payload.btnSubmitValue
                }
            }
        case SAVE_STEP1_DATA:
            return {
                ...state,
                step1Data: {
                    ...state.step1Data,
                    [action.payload.targetName]: action.payload.targetValue
                }
            }
        case SAVE_STEP2_DATA:
            return{
                ...state,
                step2Data: action.payload
            }
        case SAVE_STEP3_DATA:
            return {
                ...state,
                step3Data: action.payload
            }
        case CHECK_STEP1_RESPONSE:
            return {
                ...state,
                validateZipCodeRes: action.payload
            }
        default:
            return state;
    }
}