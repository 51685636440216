import React, { Component } from 'react';
import Header from '../../partials/header';
import Footer from '../../partials/less-content-footer';
import { connect } from 'react-redux';

import { Modal, ButtonToolbar,DropdownButton, Dropdown, Table, Col, Container, Row, Form, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';
import $ from 'jquery';
import AdminChangePass from './admin-change-pass';
import AdminChangeRole from './admin-change-role';

axios.defaults.baseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class AdminUsers extends Component {
  constructor(){
    super()
    this.state = {
        user_role: '',
        email: '',
        prompt: '',
        username: '',
        password: '',
        confirmPassword: '',
        checkUsernameIfExist: false,
        notEqualPassword: false,
        roles: [],
        users: [],
        checkedUsers : [],
        selectAll : false,
        showAlert: false,
        hideOk: false,
        isDisabled: false,
        pwErrMsg: ''
    }
  }
  
  componentDidMount() {
    this.getUserAdmin();
    this.getUserRoles();
  }

  checkUsername = (username) => {
    return axios 
      .post('/api/v1/check-admin-username',
      {
          username: username
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if(response.data.result == 'true'){
          this.setState({checkUsernameIfExist: true});
        }else {
          this.setState({checkUsernameIfExist: false});
        }
      })
      .catch(error => {
      });
  } 

  submitAddUserForm = (e) => {
    this.setState({isDisabled: true});
    e.preventDefault();
    if(!this.state.checkUsernameIfExist && !this.state.notEqualPassword ){
      return axios 
      .post('api/v1/insert-admin',
      {
          admin_role: this.state.user_role,
          email: this.state.email,
          username: this.state.username,
          password: this.state.password
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if(response.data.result == 'Success') {
          this.setState({
            isDisabled: false,
            hideOk: true,
            prompt: 'Added Successfully!',
            showAlert: true,
          });
          window.location.replace("/admin-dashboard");
        }else{
          this.setState({
            isDisabled: false,
            prompt: 'Failed! Please try again!',
            showAlert: true,
          });
        }
        
      })
      .catch(error => {
      });
    }
  }

  getUserRoles = () => {
    return axios
    .get('/api/v1/roles', 
    {
        headers: {'Content-Type':'application/json'}
    })
    .then(response => {
      this.setState({roles: response.data});
    });
  }

  getUserAdmin = () => {
    return axios
    .get('/api/v1/users', 
    {
        headers: {'Content-Type':'application/json'}
    })
    .then(response => {
      this.setState({users: response.data});
    });
  }

  handleCheckAll = (e) => {
    let users = this.state.users;
    let checkedUsers = [];

    this.setState({ selectAll : !this.state.selectAll }, () => {
      if(this.state.selectAll == true) { //true or false
        for(let x = 0; x < users.length ; x++){
          checkedUsers.push(users[x].id);
        }
        this.setState({
          checkedUsers
        })
      }
      else{
        this.setState({
          checkedUsers
        })
      }
    });
  }

  checkInput = (item, index) => {
    if(this.state.checkedUsers.includes(item.id)){ //if true, it is in checked users -> user will uncheck 
      let copyOfCheckedUsers = this.state.checkedUsers;
      copyOfCheckedUsers.splice(copyOfCheckedUsers.indexOf(item.id), 1);
      this.setState({ //remove on checked list and disable select all mode
        selectAll : false,
        checkedUsers : copyOfCheckedUsers
      })
    }
    else
    {
      let users = this.state.users;
      let checkUsers = this.state.checkedUsers;
      checkUsers.push(users[index].id);
      this.setState({
        selectAll : false,
        checkedUsers : checkUsers
      })
    }

    // FINAL CHECKER IF ALL ITEMS ARE SELECTED
    if(this.state.checkedUsers.length == this.state.users.length){
      let users = this.state.users;
      let checkedUsers = [];
      for(let x = 0; x < users.length ; x++){
        checkedUsers.push(users[x].id);
      }
      this.setState({
        selectAll : true,
        checkedUsers
      })
    }
  }
   
  deleteUser = () => {
    let ids = this.state.checkedUsers;
    ids.map((itemId, i) => {
      axios.post('/api/v1/delete-admin',
      {
          id: itemId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        if(response.data.result == 'Success') {

          let copyOfCheckedUsers = this.state.users;
          copyOfCheckedUsers.splice(copyOfCheckedUsers.indexOf(itemId.id), 1);
    
          this.setState({
            selectAll : false,
            prompt: 'Deleted Successfully.',
            showAlert: true,
            users: copyOfCheckedUsers,
            checkedUsers: copyOfCheckedUsers
          });

        }
      })
      .catch(error => {
      });
    })
  }
  
  inputChange = (e) => {
    this.setState({
        ...this.state,
        [e.target.name]: e.target.value
    });
  }

  onChangeUsername = (e) => {
    this.checkUsername(e.target.value);    
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value
      })
  }

  onChangePassword = (e) => {
    if(e.target.name === "password" || e.target.name === "confirmPassword"){
      this.setState({
        ...this.state,
        [e.target.name]: e.target.value
      },
      () => {
          let pword = String(this.state.password);
          let confirm_pword = String(this.state.confirmPassword);
      
          if(pword.length < 8){
            this.setState({ 
                notEqualPassword: true,
                pwErrMsg: "Your password must be between 8 – 15 characters long"
            });
          }
          else{
              if(pword===confirm_pword){
                  this.setState({ notEqualPassword: false });
              }else{
                  this.setState({ notEqualPassword: true, pwErrMsg: "Password doesn't match!" });
              }
          }
      })
    }
  }

  handleCloseModalAlert = () => {
    this.setState({showAlert: false});
  }


  render() { 
    const errPwordMsg       = this.state.notEqualPassword === true ? this.state.pwErrMsg+" " : 'Please enter your password.';
    const errPwordInpClass  = this.state.notEqualPassword === true ? 'invalid form-control' : 'valid form-control';
    const errPwordMsgDisp   = this.state.notEqualPassword === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';
    const errUsernameMsg   = this.state.checkUsernameIfExist === true ? 'Username already exist' : 'Please enter your username';
    const errUsernameInpClass  = this.state.checkUsernameIfExist === true ? 'invalid form-control' : 'valid form-control';
    const errUsernameMsgDisp   = this.state.checkUsernameIfExist === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';

    
    return (
      <React.Fragment>

      <Modal
        show={this.state.showAlert} 
        onHide={this.handleCloseModalAlert} 
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        id="modal-for-alert"
        backdrop="static"
        centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <h6>{this.state.prompt}</h6>
          {
            this.state.hideOk ? 
            null :
            <div className="btn-submit-change-ok" >
              <button type="submit" onClick={this.handleCloseModalAlert} className="btn btn-change-ok font-bold btn-primary">Okay</button>
            </div>
          }
          
        </Modal.Body>
      </Modal>

        <div id="admin-control-dashboard" >
            <Container fluid={true}>
                <Row>
                  <Col className="add-user-container" lg={4}>
                    <h5>Add User</h5>
                    {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                    <div className="add-form-container">
                      <Form onSubmit={this.submitAddUserForm.bind(this)} autocomplete="off">                        
                        <Form.Group controlId="admin-email">
                        <Form.Label>User Role</Form.Label>
                          <Form.Control
                          autocomplete="off"
                              required
                              as="select"
                              name="user_role"
                              className="valid"
                              onChange={this.inputChange.bind(this)}
                          > 
                              <option></option>    
                          {       
                                       
                            this.state.roles.map((item, i) => {
                              return (
                                <option key={i} value={item.id}>{item.admin_role}</option>
                              ); 
                              
                            })
                          }
                          </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="admin-email">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            required
                            autocomplete="off"
                            type="email"
                            autocomplete="off"
                            name="email"
                            className="valid"
                            onChange={this.inputChange.bind(this)}
                        />
                        <Form.Control.Feedback id="feedback" type="invalid">
                            Please enter your Email.
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="admin-username">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                            required
                            type="text"
                            autocomplete="off"
                            name="username"
                            className={errUsernameInpClass}
                            onBlur={this.onChangeUsername.bind(this)}
                        />
                        <Form.Control.Feedback id="feedback" className={errUsernameMsgDisp} type="invalid">
                              {errUsernameMsg}
                        </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="admin-password">
                            <Form.Label>Password (Password must be between 8 – 15 characters long)</Form.Label>
                            <Form.Control
                            autocomplete="off"
                              required
                              type="password"
                              name="password"
                              className={errPwordInpClass}
                              value={this.state.password}
                              onChange={this.onChangePassword.bind(this)}
                          />
                          <Form.Control.Feedback id="feedback" className={errPwordMsgDisp} type="invalid">
                              {errPwordMsg}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="admin-confirm-password">
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control
                            autocomplete="off"
                              required
                              type="password"
                              name="confirmPassword"
                              className={errPwordInpClass}
                              value={this.state.confirmPassword}
                              onChange={this.onChangePassword.bind(this)}
                          />
                          {/* <Form.Control.Feedback id="feedback" className={errPwordMsgDisp} type="invalid">
                              {errPwordMsg}
                          </Form.Control.Feedback> */}
                        </Form.Group>
                        {
                          this.state.isDisabled ? 
                          <button type="submit" className="btn btn-primary font-bold isDisabled-admin">Add User</button>
                          :
                          <button type="submit" className="btn btn-primary font-bold" >Add User</button>
                        }
                      </Form>
                    </div>
                  </Col>
                  <Col className="view-user-container" lg={8}>
                    <div className="">
                      <h5>Current Users</h5>
                      {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p> */}
                      <div className="table-users-container">
                      <Table className="table users-table" responsive="sm">
                        <thead>
                          <tr>
                            <th>
                            <Form.Group className="check-container">
                              <label className="container-check">
                                <Form.Check.Input isValid id="main-check" checked={this.state.selectAll} onClick={this.handleCheckAll.bind(this)} />
                                <span className="checkmark"></span>
                              </label>
                            </Form.Group>
                            </th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Role</th>
                            <th>
                              <a onClick={this.deleteUser}>
                                { this.state.selectAll || this.state.checkedUsers.length > 1 ?
                                  <img className="gpa_logo" src={process.env.PUBLIC_URL + "/images/delete.png"} alt="gpa-logo"></img>
                                  :
                                  null
                                }
                              </a>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.users.map( (item, i) => {
                             
                              return(
                                item.username === localStorage.getItem("adminusername") ?
                                ""
                                :
                                <tr key={i}>
                                  <td>
                                    <Form.Group className="check-container">
                                      <label className="container-check">
                                        <Form.Check.Input isValid className="" value={item.id} checked={this.state.checkedUsers.includes(item.id)} onClick={() => this.checkInput(item, i)}/>
                                        <span className="checkmark"></span>
                                      </label>
                                    </Form.Group>
                                  </td>
                                  <td>{item.username}</td>
                                  <td>{item.email}</td>
                                  <td>{item.role}</td>
                                  <td>
                                  <DropdownButton id="edit-admin-users-info" title="" alignRight>
                                    <Dropdown.Item href=""><AdminChangePass userId={item.id}/></Dropdown.Item>
                                    <Dropdown.Item href=""><AdminChangeRole userId={item.id}/></Dropdown.Item>
                                  </DropdownButton>
                                  
                                    <a onClick={this.deleteUser} >
                                      { this.state.selectAll || this.state.checkedUsers.length > 1 ?
                                        null
                                      :
                                        <img className="gpa_logo" src={process.env.PUBLIC_URL + "/images/delete.png"} alt="gpa-logo"></img>
                                      }
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          }
                        </tbody>
                      </Table>
                      </div>
                    </div>
                  </Col>

              
              </Row>
            </Container>
          </div>
      </React.Fragment> 
    );
  }
}

export default AdminUsers;