import React, { Component } from 'react';
import { Form, Col, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { checkUsername,signUpUser } from '../actions/loginAndSignUpActions';
import _ from 'lodash';

class CreateAccount extends Component {
    constructor() {
        super()
        this.state = {
            testResult: '',
            userDetails : {
                // first_name: this.props.users.userDetails.accountId,
                personId: '',
                first_name: '',
                last_name: '',
                username: '',
                password: '',
                confirm_password: '',
                line_1: '',
                line_2: '',
                city: '',
                province: '',
                zipcode: '',
                country: '',
                email_address: '',
                home_number: '',
                mobile_number: '',
                work_number: '',
                security_question_txt: '',
                security_question_val: '',
                security_answer: '',
            },
            securityQuestions: [],
            user_status: '',
            validated: false,
            isNotPword: false,
            isEmail: true,
            isUsernameExist: false,
            isUsernameValidating: false,
            isSuccess: false,
        }
        this.onChange = this.onChange.bind(this)
    }
    componentWillMount() {
        var fullName =  this.props.users.userBasicInfo.fullName
        var firstName = fullName.substr(fullName.indexOf(' ')+1)
        var lastName = fullName.substr(0,fullName.indexOf(' '))
        this.setState({
            userDetails: {
                personId: this.props.users.userBasicInfo.personId,
                first_name: firstName,
                last_name: lastName,
                username: '',
                password: '',
                confirm_password: '',
                line_1: this.props.users.userAddressDetails.address1,
                line_2: '',
                city: this.props.users.userAddressDetails.city,
                province: this.props.users.userAddressDetails.state,
                zipcode: this.props.users.userAddressDetails.postal,
                country: this.props.users.userAddressDetails.country,
                email_address: this.props.users.userBasicInfo.emailAddress,
                home_number: this.props.users.userContactNumbers.homePhone,
                mobile_number: this.props.users.userContactNumbers.mobilePhone,
                work_number: this.props.users.userContactNumbers.workPhone,
                security_question_txt: this.props.users.securityQuestions[0].description,
                security_question_val: this.props.users.securityQuestions[0].characteristicValue,
                security_answer: '',
            }
        },()=>{
        })
    }

    onChange = (e) => {
        if(e.target.name === "password" || e.target.name === "confirm_password"){
            this.setState({
                ...this.state,
                userDetails: {
                    ...this.state.userDetails,
                    [e.target.name] : e.target.value
                }
            },
            () => {
                let pword = String(this.state.userDetails.password);
                let confirm_pword = String(this.state.userDetails.confirm_password);
            
                if(pword===confirm_pword){
                    this.setState({ isNotPword: false });
                }else{
                    this.setState({ isNotPword: true });
                }
            })
        }
        else if(e.target.name === "email_address"){
            this.setState({
                ...this.state,
                userDetails: {
                    ...this.state.userDetails,
                    [e.target.name] : e.target.value
                }
            },
            () => {
                let email = String(this.state.userDetails.email_address);
                // eslint-disable-next-line
                if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)){
                    this.setState({ isEmail: true });
                }
                else{
                    this.setState({ isEmail: false });
                }
            })
        }
        else{
            this.setState({
                ...this.state,
                userDetails: {
                    ...this.state.userDetails,
                    [e.target.name] : e.target.value
                }
            })
        }
    }
    
    onBlur = (e) => {
        this.setState({...this.state,isUsernameExist:false,isUsernameValidating:true})
        this.props.checkUsername(this.state.userDetails.username)
        .then((response) => {
            if(response.status === "True"){
                this.setState({...this.state,isUsernameExist:true,isUsernameValidating:false})
            } 
            else{
                this.setState({...this.state,isUsernameExist:false,isUsernameValidating:false})
            }
        })
    }

    selectOnChange = (e) => {
        var select = document.getElementById("validationCustom16")
        this.setState({ 
            ...this.state,
            userDetails: { 
                ...this.state.userDetails,
                security_question_txt: select[select.selectedIndex].text,
                security_question_val: e.target.value
            } 
        }) 
    }

    handleSubmit(event) {
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else{
            const postData = {
                personId: this.state.userDetails.personId,
                first_name: this.state.userDetails.first_name,
                last_name: this.state.userDetails.last_name,
                username: this.state.userDetails.username,
                password: this.state.userDetails.password,
                line_1: this.state.userDetails.line_1,
                line_2: this.state.userDetails.line_2,
                city: this.state.userDetails.city,
                province: this.state.userDetails.province,
                zipcode: this.state.userDetails.zipcode,
                country: this.state.userDetails.country,
                email_address: this.state.userDetails.email_address,
                home_number: this.state.userDetails.home_number,
                mobile_number: this.state.userDetails.mobile_number,
                work_number: this.state.userDetails.work_number,
                // security_question_txt: this.state.userDetails.security_question_txt,
                security_question_val: this.state.userDetails.security_question_val,
                security_answer: this.state.userDetails.security_answer,
            }
            this.props.signUpUser(postData)
            .then((response) => {
                if(response.result.status === 'True'){
                    this.setState({
                        isSuccess: true
                    })
                }
            })
            .catch((error)=>{
            })
            
        }
        this.setState({ validated: true });
    }

    render() { 
        const { validated } = this.state;

        //for validations
        const errUsernameMsg = this.state.isUsernameExist === true ? 'Username already exists. Kindly choose again.' : 'Please choose your username.';
        const errUsernameInpClass = this.state.isUsernameExist === true ? 'invalid form-control' : 'valid form-control';
        const errUsernameMsgDisp = this.state.isUsernameExist === true ? 'invalid_msg_small invalid-feedback' : 'invalid-feedback';
        
        const errPwordMsg = this.state.isNotPword === true ? "Password doesn't match " : 'Please enter your password.';
        const errPwordInpClass = this.state.isNotPword === true ? 'invalid form-control' : 'valid form-control';
        const errPwordMsgDisp = this.state.isNotPword === true ? 'invalid_msg invalid-feedback' : 'invalid-feedback';

        const errEmailMsg = this.state.isEmail === true ? "Please enter your Email Address. " : "Please enter a valid Email Address.";
        const errEmailInpClass = this.state.isEmail === true ? 'valid form-control' : 'invalid form-control';
        const errEmailMsgDisp = this.state.isEmail === true ? ' invalid-feedback' : 'invalid_msg invalid-feedback';

        return (  
            (this.state.isSuccess === true) ? 
            <React.Fragment>
                <section id="result_message">
                    <div className="container">
                        <div id="title" className="row justify-content-md-center">
                            <div className="col-lg-12">
                                <h4 className="primary-font-color text-center">THANK YOU FOR ACTIVATING YOUR MY POWER SELF SERVICE WEB PORTAL!</h4>
                            </div>
                        </div>
                        <div id="body" className="row">
                            <div className="col-lg-12 text-center">
                                <p className="font-bold">
                                    You will receive an email confirming your registration. If you do NOT receive this email within a few minutes of signing up, please email us at cutomers@guampowerauthority.org <br/>
                                    Remember to check your junk folder as the email may have been caught in your spam filter
                                </p>
                            </div>
                            <div className="col-lg-12 text-center mb-2 mt-5">
                                <a href="http://guampowerauthority.com/"><button type="submit" className="btn btn-primary font-bold">Back to Homepage</button></a>
                            </div>
                            <div className="col-lg-12 text-center">
                                <a href="/" className="dark-font-color font-bold">Login Here</a>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
            :
            <React.Fragment>
                <section id="create_account">
                    <div className="container">
                        <div id="title" className="row justify-content-md-center pt-4">
                            <div className="col-lg-12">
                                <h4 className="primary-font-color text-center">Create Account</h4>
                            </div>
                        </div>
                        <div className="signup-form">
                            <Form
                                noValidate
                                validated={validated}
                                onSubmit={e => this.handleSubmit(e)}
                            >
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom01">
                                        <Form.Label>First Name <span className="secondary-font-color"></span></Form.Label>
                                        <Form.Control disabled type="text" autocomplete="off" className="valid" name="first_name" value={this.state.userDetails.first_name}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter your First Name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom02">
                                        <Form.Label>Last name <span className="secondary-font-color"></span></Form.Label>
                                        <Form.Control disabled type="text" autocomplete="off" name="last_name" value={this.state.userDetails.last_name}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter your Last Name.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-4">
                                    <Form.Group as={Col} md="6" controlId="validationCustom03">
                                        <Form.Label>Choose Username  <span className="secondary-font-color"><strong>*</strong></span></Form.Label>
                                        <Form.Control type="text" autocomplete="off" name="username" value={this.state.userDetails.username} className={errUsernameInpClass}
                                            onChange={this.onChange.bind(this)}
                                            onBlur={this.onBlur.bind(this)}
                                        />
                                        {(this.state.isUsernameValidating === true) ? <Spinner animation="border" size="sm" /> : ''}
                                        <Form.Control.Feedback id="feedback" className={errUsernameMsgDisp} type="invalid">
                                            {errUsernameMsg}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom04">
                                        <Form.Label>Password <span className="secondary-font-color"><strong>*</strong></span></Form.Label>
                                        <Form.Control required type="password" name="password" value={this.state.userDetails.password} className={errPwordInpClass}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" className={errPwordMsgDisp} type="invalid">
                                            {errPwordMsg}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom05">
                                        <Form.Label>Confirm Password <span className="secondary-font-color"><strong>*</strong></span></Form.Label>
                                        <Form.Control required type="password" name="confirm_password" value={this.state.userDetails.confirm_password} className={errPwordInpClass}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please confirm your password.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-4">
                                    <Form.Group as={Col} md="6" controlId="validationCustom06">
                                        <Form.Label>Line 1</Form.Label>
                                        <Form.Control disabled type="text" autocomplete="off" name="line_1" value={this.state.userDetails.line_1}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Address Line 1.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom07">
                                        <Form.Label>Line 2</Form.Label>
                                        <Form.Control disabled type="text" autocomplete="off" name="line_2" value={this.state.userDetails.line_2}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Address Line 2.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom08">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control disabled type="text" autocomplete="off" name="city" value={this.state.userDetails.city}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter City.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom09">
                                        <Form.Label>State / Province</Form.Label>
                                        <Form.Control disabled type="text" autocomplete="off" name="province" value={this.state.userDetails.province}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter State/Province.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom10">
                                        <Form.Label>GWA Billing Zip Code <span className="secondary-font-color"><strong>*</strong></span></Form.Label>
                                        <Form.Control required type="number" autocomplete="off" name="zipcode" value={this.state.userDetails.zipcode}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Zip Code.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom11">
                                        <Form.Label>Country</Form.Label>
                                        <Form.Control disabled type="text" autocomplete="off" name="country" value={this.state.userDetails.country}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Country.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom12">
                                        <Form.Label>Email Address <span className="secondary-font-color custom-line-height"><strong>*</strong></span></Form.Label>
                                        <Form.Control required type="text" autocomplete="off" name="email_address" value={this.state.userDetails.email_address} className={errEmailInpClass}
                                            onChange={this.onChange.bind(this)}                                            
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid" className={errEmailMsgDisp}>
                                            {errEmailMsg}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom13">
                                        <Form.Label>Home Phone <span className="secondary-font-color custom-line-height"><strong>*</strong></span></Form.Label>
                                        <Form.Control required type="text" autocomplete="off" name="home_number" value={this.state.userDetails.home_number}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Home Phone.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} md="6" controlId="validationCustom14">
                                        <Form.Label>Mobile Phone <span className="secondary-font-color custom-line-height"><strong>*</strong></span></Form.Label>
                                        <Form.Control required type="text" autocomplete="off" name="mobile_number" value={this.state.userDetails.mobile_number}
                                            onChange={this.onChange.bind(this)}      
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Mobile Phone.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom15">
                                        <Form.Label>Work Phone <span className="secondary-font-color custom-line-height"><strong>*</strong></span></Form.Label>
                                        <Form.Control required type="text" autocomplete="off" name="work_number" value={this.state.userDetails.work_number}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Work Phone.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row className="mt-4">
                                    <Form.Group as={Col} md="6" controlId="validationCustom16">
                                        <Form.Label>Security Question <span className="secondary-font-color"><strong>*</strong></span></Form.Label>
                                        <Form.Control as="select" name="security_question_txt" ref="sec_ques" 
                                            onChange={ this.selectOnChange.bind(this) }>
                                        >
                                            { _.map(this.props.users.securityQuestions, (item, index) => {
                                                return(
                                                <option key={index} value={item.characteristicValue}>{item.description}</option>
                                                )
                                            })}
                                        </Form.Control>
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please Select Question.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="validationCustom17">
                                        <Form.Label>Answer <span className="secondary-font-color"><strong>*</strong></span></Form.Label>
                                        <Form.Control required  type="text" autocomplete="off" name="security_answer" value={this.state.userDetails.security_answer}
                                            onChange={this.onChange.bind(this)}
                                        />
                                        <Form.Control.Feedback id="feedback" type="invalid">
                                            Please enter Answer.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form.Row>
                                <div id="gpa-form-actions" className="row">
                                    <div className="col-lg-6">
                                        <div id="info-content" className="float-left">
                                            <p className="font-bold">Fields marked as <span className="secondary-font-color custom-line-height"><strong>*</strong></span> are mandatory</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="row">
                                            <div id="sign-up" className="col-lg-6 order-lg-last">
                                                <button type="submit" className="btn btn-primary gpa-form-button regular-font-size font-bold">Sign Up</button>
                                            </div>
                                            <div id="cancel" className="col-lg-6 order-lg-first">
                                                <a href="/" className="dark-font-color regular-font-size"><u>Cancel</u></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    users: state.users
});

export default connect(mapStateToProps, { checkUsername, signUpUser })(CreateAccount);
