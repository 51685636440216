import React, { Component } from "react";
import { connect } from "react-redux";
import {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
} from "../actions/dashboardActions";
import { showMessage } from "../actions/popupMessageActions";
import CurrencyInput from "react-currency-masked-input";
import EditAccount from "../components/editAccount";
import {
  Table,
  Form,
  DropdownButton,
  Dropdown,
  Popover,
  OverlayTrigger,
  Spinner,
} from "react-bootstrap";
import $ from "jquery";
import _ from "lodash";
import Loading from "./loading";
import ModalViewChart from "./modalViewChart";
import ModalMyBills from "./modalMyBills";
import ModalPaymentHistory from "./modalPaymentHistory";
import PayNowModal from "./paynowModal";
// import ModalPaperlessBilling from './modalPaperlessBilling';
// import formatNum from 'format-num'
import NumberFormat from "react-number-format";
import InfiniteScroll from "react-infinite-scroll-component";

class MultipleAccView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAccDetails: {
        accountId: "",
        personId: "",
        fullName: "",
        addressLine1: "",
        city: "",
        state: "",
        postal: "",
        country: "",
        homePhone: "",
        mobilePhone: "",
        workPhone: "",
        emailAddress: "",
      },
      overlayShow: false,
      overlayTarget: null,
      waterOptions: {},
      amountsOptions: {},
      waterChartData: {},
      amountsChartData: {},
      isEdit: false,
      validated: false,
      selectedAccountId: "",
      selectedPremiseAddress: "",
      isMultipleAccount: false,
      subtotal: 0,
      accountSummary: [],
      isCheckedAll: false,
      isLatestBill: false,
      isUserAccDetails: false,
      isLoading: true,
      edited: false,
      currentAccountLength: 0,
      accountLengthWithLocation: 0,
    };
  }
  componentDidMount() {
    // let timer = setInterval(() => {
    //     const sortedAccountSummary = this.sortAccountSummary();
    //     this.setState({
    //         accountSummary: this.props.dashboard.orderData.accountSummary,
    //     });
    //     if(this.state.userAccDetails.accountId.length === this.state.accountSummary.length){
    //         clearInterval(timer);
    //     }
    // }, 3000);
    this.getLocalData();
  }

  getLocalData = () => {
    //session storage key search
    let sessionAccountId, sessionPersonId;
    let lsAccountId = JSON.parse(localStorage.getItem("accountId"));
    let lsAccountIds = JSON.parse(localStorage.getItem("accountIds"));
    let count = 0;
    let accountWithLoc = [];

    if (
      !(lsAccountId === "" || lsAccountId === null || lsAccountId === undefined)
    ) {
      sessionAccountId = lsAccountId;
    } else if (
      !(
        lsAccountIds === "" ||
        lsAccountIds === null ||
        lsAccountIds === undefined
      )
    ) {
    
    sessionAccountId = lsAccountIds;
    sessionAccountId.map(sessionAccount => {
      if(sessionAccount[1] != ""){
        count ++;
        accountWithLoc.push(sessionAccount);
      }
    })
    sessionAccountId = accountWithLoc;
    }

    sessionPersonId = localStorage.getItem("personId");
    this.setState(
      {
        ...this.state,
        selectedAccountId: this.props.dashboard.selectedAccountId,
        selectedPremiseAddress: this.props.dashboard.selectedPremAdd,
        isMultipleAccount: sessionAccountId.length > 1 ? true : false,
        userAccDetails: {
          ...this.state.userAccDetails,
          accountId: sessionAccountId,
          personId: sessionPersonId,
        },
        
      },
      () => {
        const accountId = this.state.selectedAccountId;
        const personId = sessionPersonId;
        this.executeRequests(
          sessionAccountId.slice(
            0,
            sessionAccountId.length > 5 ? 6 : sessionAccountId.length
          ),
          accountId,
          personId
        );
      }
    );
  };

  sortAccountSummary = () => {
    let sortedAccountId = [];
    let allResidAccts = [];
    let allNonResidAccts = [];
    let sortedAccountSummary = [];
    for (
      let count = 0;
      count < this.props.dashboard.orderData.accountSummary.length;
      count++
    ) {
      sortedAccountId.push(
        this.props.dashboard.orderData.accountSummary[count].accID
      );
    }

    //get all resid accounts
    for (
      let count = 0;
      count < this.props.dashboard.orderData.accountSummary.length;
      count++
    ) {
      if (
        this.props.dashboard.orderData.accountSummary[count].className ===
        "RESID"
      ) {
        allResidAccts.push(
          this.props.dashboard.orderData.accountSummary[count]
        );
      }
    }
    //get all non-resid accounts
    for (
      let count = 0;
      count < this.props.dashboard.orderData.accountSummary.length;
      count++
    ) {
      if (
        this.props.dashboard.orderData.accountSummary[count].className !=
        "RESID"
      ) {
        allNonResidAccts.push(
          this.props.dashboard.orderData.accountSummary[count]
        );
      }
    }
    //insert all resid accounts
    for (let count = 0; count < sortedAccountId.sort().length; count++) {
      for (let count1 = 0; count1 < allResidAccts.length; count1++) {
        if (sortedAccountId.sort()[count] === allResidAccts[count1].accID) {
          sortedAccountSummary.push(allResidAccts[count1]);
          break;
        }
      }
    }
    //insert all non-resid accounts
    for (let count = 0; count < sortedAccountId.sort().length; count++) {
      for (let count1 = 0; count1 < allNonResidAccts.length; count1++) {
        if (sortedAccountId.sort()[count] === allNonResidAccts[count1].accID) {
          sortedAccountSummary.push(allNonResidAccts[count1]);
          break;
        }
      }
    }
    return sortedAccountSummary;
  };

  async executeRequests(sessionAccountId, accountId, personId) {
    this.props.saveAccountId(accountId);

    try {
      let [result1, result2] = await Promise.all([
        this.props.fetchMultipleLatestBill(
          sessionAccountId,
          this.props.dashboard.orderData.accountSummary
        ),
        this.props.fetchMultipleAddOpptyRequest(accountId, personId),
      ]);

      if (result1 && result2.dataFetched) {
        const userAccountDetails = this.props.dashboard.userAccountDetails;



        this.setState({
          ...this.state,
          accountSummary: this.props.dashboard.orderData.accountSummary,
          subtotal: this.props.dashboard.orderData.subTotal,
          consumptionViewBy: "monthlyWater",
          currentAccountLength:
            this.props.dashboard.orderData.accountSummary.length,
          userAccDetails: {
            ...this.state.userAccDetails,
            fullName: userAccountDetails.fullName,
            addressLine1: userAccountDetails.addressLine1,
            city: userAccountDetails.city,
            state: userAccountDetails.state,
            postal: userAccountDetails.postal,
            country: userAccountDetails.country,
            homePhone: userAccountDetails.homePhone,
            mobilePhone: userAccountDetails.mobilePhone,
            workPhone: userAccountDetails.workPhone,
            emailAddress: userAccountDetails.emailAddress,
          },
          isLoading: false,
        });
      }
    } catch (error) {
      this.props.showMessage("danger", "Server Error. Try again later!");
      setInterval(() => {
        window.location.reload();
      }, 1000);
    }
  }

  async fetchInfiniteScroll() {
    try {
      let lsAccountIds = JSON.parse(localStorage.getItem("accountIds"));
      let accountWithLoc = [];
      let count = 0;
      lsAccountIds.map(sessionAccount => {
        if(sessionAccount[1] != ""){
          count ++;
          accountWithLoc.push(sessionAccount);
        }
      })

      let [result1] = await Promise.all([
        this.props.fetchMultipleLatestBill(
          accountWithLoc.slice(
            this.state.currentAccountLength,
            this.state.currentAccountLength + 20
          ),
          this.props.dashboard.orderData.accountSummary
        ),
      ]);
      if (result1) {
        this.setState({
          ...this.state,
          accountSummary: this.props.dashboard.orderData.accountSummary,
          subtotal: this.props.dashboard.orderData.subTotal,
          currentAccountLength:
            this.props.dashboard.orderData.accountSummary.length,
        });
      }
    } catch (error) {
      this.props.showMessage("danger", "Server Error. Try again later!");
      setInterval(() => {
        window.location.reload();
      }, 1000);
    }
  }

  showEdit = (e) => {
    e.preventDefault();
    this.setState({ isEdit: true });
  };

  saveOrderData = () => {
    const orderData = this.props.dashboard.orderData;
    const postData = {
      subTotal: this.state.subtotal,
      accountSummary: orderData.isHasInvalid
        ? orderData.accountSummary
        : this.sortAccountSummary(),
      isHasInvalid: orderData.isHasInvalid ? true : false,
    };
    this.props.saveOrderData(postData);
  };

  updateSubTotal = () => {
    let subtotal = 0;
    const arrAccountSummary = this.state.accountSummary;
    // console.log('arrAccountSummary', arrAccountSummary)
    if (this.state.edited) {
      for (let count = 0; count < arrAccountSummary.length; count++) {
        if (arrAccountSummary[count].checked) {
          subtotal =
            subtotal +
            parseFloat(
              arrAccountSummary[count].amountToBePaid === ""
                ? 0
                : arrAccountSummary[count].amountToBePaid
            );
          // console.log('arrAccountSummary subtotal', subtotal)
        }
      }
    } else {
      for (let count = 0; count < arrAccountSummary.length; count++) {
        if (arrAccountSummary[count].checked) {
          subtotal =
            subtotal +
            parseFloat(
              arrAccountSummary[count].amountToBePaid === ""
                ? 0
                : arrAccountSummary[count].amountToBePaid
            );
          console.log("arrAccountSummary subtotal", subtotal);
        }
      }
    }
    this.setState(
      {
        subtotal:
          subtotal <= parseFloat(0).toFixed(2)
            ? parseFloat(0).toFixed(2)
            : parseFloat(subtotal).toFixed(2),
      },
      () => {
        this.saveOrderData();
      }
    );
  };

  amountToBePaidOnBlur = (e) => {};
  formatAmount = (value) => {
    value = value.replace("$ ", ""); //removes '$ '
    value = value.replace(/\,/g, ""); //removes all ','
    return (value = parseFloat(Math.round(value * 100) / 100).toFixed(2));
  };

  amountToBePaidOnChange = (e) => {
    let id = e.target.id,
      value = e.target.value;
    value = value.replace("$ ", ""); //removes '$ '
    value = value.replace(".", ""); //removes '$ '
    value = value.replace(/\,/g, ""); //removes all ','
    value =
      value == 1
        ? "0.01"
        : value.slice(0, -3) == "00"
        ? ".0" + value.slice(-2)
        : Number(value.slice(0, -2)) + "." + value.slice(-2);
    // console.log("value", value)
    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "";
    // console.log('arrAccountSummary', arrAccountSummary)
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if ("amount-input-" + arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    // value = parseInt(value) > 1000000 ? 1000000 : value
    arrAccountSummary[selectedIndex].amountToBePaid = value;
    arrAccountSummary[selectedIndex].checked = true;
    this.setState(
      {
        ...this.state,
        amountToBePaid: arrAccountSummary,
        accountSummary: arrAccountSummary,
        edited: true,
      },
      () => {
        this.updateSubTotal();
      }
    );
  };

  amountToBePaidDefault = (accountSummary) => {
    let id = accountSummary.accID,
      value = accountSummary.amount < 0 ? 0.0 : accountSummary.amount;

    const arrAccountSummary = this.state.accountSummary;
    let selectedIndex = "";
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if (arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }
    arrAccountSummary[selectedIndex].amountToBePaid = value;
    arrAccountSummary[selectedIndex].checked = true;
    this.setState(
      {
        ...this.state,
        amountToBePaid: arrAccountSummary,
        accountSummary: arrAccountSummary,
      },
      () => {
        this.updateSubTotal();
      }
    );
  };

  checkBoxOnChange = (e) => {
    console.log("checkBoxOnChange");
    const id = e.target.id,
      value = e.target.value;
    const arrAccountSummary = this.state.accountSummary;
    console.log("arrAccountSummary", arrAccountSummary);

    let selectedIndex = "",
      isCheckedAll = false;
    for (let count = 0; count < arrAccountSummary.length; count++) {
      if (arrAccountSummary[count].accID === id) {
        selectedIndex = count;
      }
    }

    if (arrAccountSummary[selectedIndex].checked === false) {
      arrAccountSummary[selectedIndex].checked = true;
      this.amountToBePaidDefault(arrAccountSummary[selectedIndex]);
    } else {
      isCheckedAll = false;
      arrAccountSummary[selectedIndex].checked = false;
      arrAccountSummary[selectedIndex].amountToBePaid = 0;
      console.log("arrAccountSummary[count]", arrAccountSummary[selectedIndex]);
    }

    // console.log('checkBoxOnChange before setstate', arrAccountSummary);
    // arrAccountSummary.amountToBePaid = 0;
    this.setState(
      {
        ...this.state,
        isCheckedAll: isCheckedAll,
        accountSummary: arrAccountSummary,
      },
      () => {
        document
          .getElementById(
            "amount-input-" + arrAccountSummary[selectedIndex].accID
          )
          .focus();
        this.updateSubTotal();
      }
    );
  };

  checkAll = (e) => {
    let subtotal = 0;
    const arrAccountSummary = this.state.accountSummary;
    if (e.target.checked) {
      for (let count = 0; count < arrAccountSummary.length; count++) {
        arrAccountSummary[count].checked = true;
        subtotal =
          subtotal + parseFloat(arrAccountSummary[count].amountToBePaid);
      }
      this.setState(
        {
          isCheckedAll: true,
          subtotal: subtotal,
        },
        () => {
          this.saveOrderData();
        }
      );
    } else {
      for (let count = 0; count < arrAccountSummary.length; count++) {
        arrAccountSummary[count].checked = false;
      }
      this.setState(
        {
          isCheckedAll: false,
          subtotal: 0,
        },
        () => {
          this.saveOrderData();
        }
      );
    }
  };
  handleClick = (event) => {
    this.setState({
      overlayShow: !this.state.overlayShow,
      overlayTarget: event.target,
    });
  };
  scrollTop = () => {
    let element = document.getElementById("scrollable-container");
    element.scrollTop = 0;
  };

  render() {
    var fullName = this.state.userAccDetails.fullName;
    var firstName = fullName.substr(0, fullName.indexOf(" "));
    var lastName = fullName.substr(fullName.indexOf(" ") + 1);
    if (fullName.includes(",")) {
      firstName = fullName.substr(fullName.indexOf(",") + 1);
      lastName = fullName.substr(0, fullName.indexOf(","));
    }
    const userFullname = firstName + " " + lastName;
    const userAccountId = this.state.userAccDetails.accountId;
    //const userFullname = lastName;
    const addressLine1 = this.state.userAccDetails.addressLine1;
    const city = this.state.userAccDetails.city;
    const state = this.state.userAccDetails.state;
    const postal = this.state.userAccDetails.postal;
    const country = this.state.userAccDetails.country;
    const homePhone = this.state.userAccDetails.homePhone;
    const mobilePhone = this.state.userAccDetails.mobilePhone;
    const workPhone = this.state.userAccDetails.workPhone;
    const emailAddress = this.state.userAccDetails.emailAddress;
    console.log(
      "this.props.dashboard.orderData.accountSummary",
      this.props.dashboard.orderData.accountSummary
    );

    // console.log(this.props.)
    const priceSplitter = (number) =>
      number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return this.state.isEdit === true ? (
      <EditAccount />
    ) : this.state.isLoading ? (
      <Loading />
    ) : (
      <React.Fragment>
        <div id="welcome-wrap">
          <div className="row pb-1">
            <div className="col-lg-8">
              <h3 className="primary-font-color">Hi {userFullname}!</h3>
              <br />
              {/* <p className="font-bold">Welcome to your GWA member dashboard</p> */}
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
        <div className="account-summary">
          <div className="row">
            <div className="col-lg-12">
              <h6 className="mr-auto">
                {`Accounts: ${this.state.accountSummary.length} / ${this.state.userAccDetails.accountId.length}`}
              </h6>
              <div className="account-summary-label">
                <label className="light-font-color font-bold">
                  Your Account Summary{" "}
                  <span className="ml-2">
                    <i className="fa fa-refresh" aria-hidden="true"></i>
                  </span>{" "}
                </label>
                {window.screen.width <= 991 ? (
                  <OverlayTrigger
                    trigger="hover"
                    placement="top"
                    overlay={
                      <Popover id="popover-contained">
                        {
                          "Please swipe right to show the rest of the information."
                        }
                      </Popover>
                    }
                  >
                    <i
                      class="fas fa-info-circle"
                      style={{
                        fontSize: "15px",
                        verticalAlign: "middle",
                        marginLeft: "5px",
                        color: "#fff",
                      }}
                    ></i>
                  </OverlayTrigger>
                ) : null}
              </div>
              {/* style={ ((this.state.accountSummary).length) > 5 ? { height: "340px!important" } : "" } */}
              {/* <div
                id="scrollable-container"
                style={
                  this.state.accountSummary.length > 5
                    ? { height: "340px"}
                    : { height: "200px"}
                }
                className={
                  this.state.accountSummary.length > 5 ? "table-overflow" : ""
                }
              > */}
                <InfiniteScroll
                  height={340}
                  dataLength={this.state.accountSummary.length}
                  next={() => this.fetchInfiniteScroll()}
                  hasMore={
                    this.state.userAccDetails.accountId.length !==
                    this.state.accountSummary.length
                  }
                  loader={
                    <div className="text-center py-2">
                      <p className="m-2">Getting Other Accounts..</p>
                      <Spinner animation="border" role="status" />
                    </div>
                  }
                  endMessage={
                    <div className="text-center py-2">
                      <p className="m-2">All accounts have been retrieved!</p>
                    </div>
                  }
                  scrollableTarget="scrollable-container"
                >
                  <Table id="account-numbers" className="" bordered hover>
                    <thead>
                      <tr>
                        <th>Account ID</th>
                        <th>Type</th>
                        <th>Service Location</th>
                        <th>Amount</th>
                        <th>Due Date</th>
                        <th style={{ zIndex: "1" }}>Actions</th>
                        <th style={{ backgroundColor: "#c3c1c1", zIndex: "1" }}>
                          Amount to be Paid
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.map(
                        !this.props.dashboard.orderData.isHasInvalid
                          ? this.sortAccountSummary()
                          : this.props.dashboard.orderData.accountSummary,
                        (item, index) => {
                            return (
                              this.props.dashboard.orderData.isHasInvalid
                                ? this.scrollTop()
                                : "",
                              !item.isActive &&
                              item.amount == 0 ? null : true ? (
                                <tr
                                  key={index}
                                  className={item.checked ? "highlighted" : ""}
                                >
                                  <td>
                                    <div className="account-number">
                                      {item.accID}
                                    </div>
                                  </td>
                                  <td>
                                    <div className="acc-class">
                                      {item.className === "RESID" ? (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/home-solid.svg"
                                          }
                                          width="16px"
                                        />
                                      ) : (
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/images/building-solid.svg"
                                          }
                                          width="16px"
                                        />
                                      )}
                                    </div>
                                  </td>
                                  {window.screen.width <= 991 ? (
                                    <td style={{ minWidth: "270px" }}>
                                      <div className="service-location">
                                        <OverlayTrigger
                                          trigger="hover"
                                          placement={
                                            index === 0 ? "bottom" : "top"
                                          }
                                          overlay={
                                            <Popover id="popover-contained">
                                              {item.serviceLocation}
                                            </Popover>
                                          }
                                        >
                                          <p className="m-0">
                                            {item.serviceLocation.substr(
                                              0,
                                              25
                                            ) + "..."}
                                          </p>
                                        </OverlayTrigger>
                                      </div>
                                    </td>
                                  ) : (
                                    <td>
                                      <div className="service-location">
                                        <OverlayTrigger
                                          trigger="hover"
                                          placement={
                                            index === 0 ? "bottom" : "top"
                                          }
                                          overlay={
                                            <Popover id="popover-contained">
                                              {item.serviceLocation}
                                            </Popover>
                                          }
                                        >
                                          <p className="m-0">
                                            {item.serviceLocation.substr(
                                              0,
                                              25
                                            ) + "..."}
                                          </p>
                                        </OverlayTrigger>
                                      </div>
                                    </td>
                                  )}

                                  {window.screen.width <= 991 ? (
                                    <td
                                      className="text-right"
                                      style={{ minWidth: "110px" }}
                                    >
                                      ${" " + priceSplitter(item.amount)}
                                    </td>
                                  ) : (
                                    <td className="text-right">
                                      ${" " + priceSplitter(item.amount)}
                                    </td>
                                  )}
                                  <td>
                                    <div
                                      className="due-date"
                                      style={
                                        item.isDueDateRed > 0 && item.amount > 0
                                          ? { color: "red", fontWeight: "600" }
                                          : {}
                                      }
                                    >
                                      {item.dueDate === "Due Now" ? (
                                        <OverlayTrigger
                                          trigger="hover"
                                          placement="left"
                                          overlay={
                                            <Popover id="popover-contained">
                                              The amount of{" "}
                                              <strong>
                                                ${" "}
                                                {parseFloat(
                                                  item.arrearsTotal
                                                ).toFixed(2)}
                                              </strong>{" "}
                                              is necessary to avoid
                                              disconnection.
                                            </Popover>
                                          }
                                        >
                                          <p className="m-0">
                                            {item.dueDate + " "}
                                            <span
                                              style={{
                                                color: "black",
                                                fontSize: "12px",
                                              }}
                                            >
                                              <i
                                                className="fa fa-question-circle"
                                                aria-hidden="true"
                                              ></i>
                                            </span>
                                          </p>
                                        </OverlayTrigger>
                                      ) : (
                                        <React.Fragment>
                                          {item.dueDate}
                                        </React.Fragment>
                                      )}
                                    </div>
                                  </td>
                                  <td>
                                    <DropdownButton
                                      id="dropdown-item-button"
                                      title={
                                        item.checked
                                          ? "Make Payment"
                                          : "Select an Action"
                                      }
                                      className="actions-dropdown dropdown"
                                    >
                                      <Dropdown.Item as="button">
                                        <p
                                          id={item.accID}
                                          style={{ margin: "0" }}
                                          value={item.amount}
                                          onClick={this.checkBoxOnChange.bind(
                                            this
                                          )}
                                        >
                                          <i
                                            className="fa fa-credit-card"
                                            aria-hidden="true"
                                          ></i>
                                          {item.checked
                                            ? "Cancel Payment"
                                            : "Make Payment"}
                                        </p>
                                      </Dropdown.Item>
                                      {item.isActive ? (
                                        <React.Fragment>
                                          <Dropdown.Item as="button">
                                            <ModalMyBills accId={item.accID} />
                                          </Dropdown.Item>
                                          <Dropdown.Item as="button">
                                            <ModalViewChart
                                              accId={item.accID}
                                            />
                                          </Dropdown.Item>
                                          <Dropdown.Item as="button">
                                            <ModalPaymentHistory
                                              accId={item.accID}
                                            />
                                          </Dropdown.Item>
                                        </React.Fragment>
                                      ) : null}
                                    </DropdownButton>
                                  </td>
                                  <td>
                                    <div className="amount-to-pay row">
                                      <div className="col-lg-9 p-0">
                                        <div className="dollar-sign">$</div>
                                        <CurrencyInput
                                          id={"amount-input-" + item.accID}
                                          defaultValue={
                                            item.amount <=
                                              parseFloat(0).toFixed(2) &&
                                            !this.state.edited
                                              ? parseFloat(0).toFixed(2)
                                              : parseFloat(item.amount).toFixed(
                                                  2
                                                )
                                          }
                                          onChange={this.amountToBePaidOnChange.bind(
                                            this
                                          )}
                                          style={{
                                            fontWeight: item.checked
                                              ? "bold"
                                              : "normal",
                                          }}
                                          className={
                                            item.validAmountToBePaid
                                              ? "valid-amount"
                                              : "invalid-amount"
                                          }
                                          disabled={!item.checked}
                                        />
                                      </div>
                                      {!item.validAmountToBePaid ? (
                                        <div className="col-lg-3 p-0 m-0">
                                          <OverlayTrigger
                                            trigger="hover"
                                            placement="left"
                                            overlay={
                                              <Popover id="popover-contained">
                                                Payment has exceeded the monthly
                                                maximum of <strong>$500</strong>{" "}
                                                for non-residential accounts
                                                using{" "}
                                                {item.usedCC === "visa"
                                                  ? "Visa"
                                                  : "Discover"}{" "}
                                                Card.{" "}
                                                {500 -
                                                  parseInt(item.alreadyPaid) >
                                                0
                                                  ? "You can only pay up to "
                                                  : ""}
                                                <strong>
                                                  {500 -
                                                    parseInt(item.alreadyPaid) >
                                                  0
                                                    ? "$ " +
                                                      (500 -
                                                        parseInt(
                                                          item.alreadyPaid
                                                        ))
                                                    : ""}
                                                </strong>{" "}
                                                {500 -
                                                  parseInt(item.alreadyPaid) >
                                                0
                                                  ? " for now using a " +
                                                    (item.usedCC === "visa"
                                                      ? "VISA"
                                                      : "DISCOVER") +
                                                    ", or you may complete your payment using Mastercard or " +
                                                    (item.usedCC === "visa"
                                                      ? "Discover card."
                                                      : "Visa card.")
                                                  : "Alternatively, you may complete your payment using a Mastercard or " +
                                                    (item.usedCC === "visa"
                                                      ? "Discover card."
                                                      : "Visa card.")}
                                              </Popover>
                                            }
                                          >
                                            <span
                                              style={{
                                                color: "red",
                                                fontSize: "18px",
                                              }}
                                            >
                                              <i class="fas fa-exclamation-triangle"></i>
                                            </span>
                                          </OverlayTrigger>
                                        </div>
                                      ) : null}
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )
                            );
                          
                        }
                      )}
                    </tbody>
                  </Table>
                </InfiniteScroll>
              {/* </div> */}
              <br />
              <div className="sub-total">
                <div className="text-right-lg">
                  <NumberFormat
                    value={parseFloat(this.state.subtotal)}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$ "}
                    decimalScale={2}
                    fixedDecimalScale={true}
                    renderText={(value) => (
                      <h5 className="sub-total-val">Sub Total: {value}</h5>
                    )}
                  />
                </div>
                <PayNowModal
                  accountSummary={this.state.accountSummary}
                  subTotal={this.state.subtotal}
                />
              </div>
              <br />
              <div id="info-wrap" className="border">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="account-info">
                      <div className="header">
                        <label className="secondary-font-color font-bold">
                          Account Information
                        </label>
                        <a
                          href="/"
                          className="primary-font-color"
                          onClick={this.showEdit}
                        >
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/images/edit-user.png"
                              }
                            />
                          </span>{" "}
                          Edit Account
                        </a>
                      </div>
                      <hr />
                      <div className="body">
                        <div className="row">
                          <div className="col-lg-4">
                            <p>
                              <strong>Customer Name</strong>
                            </p>
                            <p>{userFullname}</p>
                          </div>
                          {/* <div className="col-lg-3">
                                                        <p><strong>Mailing Address</strong></p>
                                                        <p>{addressLine1+' '+city+' '+state+' '+postal+' '+country}</p>
                                                    </div> */}
                          <div className="col-lg-4">
                            <p>
                              <strong>Phone Numbers</strong>
                            </p>
                            <p>Home: {homePhone}</p>
                            <p>Mobile: {mobilePhone}</p>
                            <p>Work: {workPhone}</p>
                          </div>
                          <div className="col-lg-4">
                            <p>
                              <strong>Email Address</strong>
                            </p>
                            <p>{emailAddress}</p>
                          </div>
                        </div>
                        {/* <p>Meter Number - 02334342</p>
                                                <p>Service Type - Water</p>
                                                <p>Tariff - Electric Residential Schedule R</p>
                                                <p>Service Address - 191 CAMELIA LN, BARRIGADA, Guam, 96913, United States of America</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  dashboard: state.dashboard,
});

export default connect(mapStateToProps, {
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData,
  showMessage,
})(MultipleAccView);
