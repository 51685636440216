import { 
    SHOW_MESSAGE, 
    HIDE_MESSAGE
} from './types';

export const showMessage = (type, message) => dispatch => {
    const postData = {
        type: type, 
        message: message
    }
    dispatch({
        type:    SHOW_MESSAGE,
        payload: postData
    })
}
export const hideMessage = () => dispatch => {
    const postData = {
        type: 'success', 
        message: ""
    }
    dispatch({
        type:    HIDE_MESSAGE,
        payload: postData
    })
}