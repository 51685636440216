import React, { Component } from "react";
import Header from "../../partials/header";
import Footer from "../../partials/less-content-footer";
import { connect } from "react-redux";
import { 
    showMessage
} from '../../actions/popupMessageActions';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import * as moment from 'moment';
import { CSVLink } from "react-csv";

import {
  Modal,
  Tabs,
  Tab,
  Table,
  Col,
  Container,
  Row,
  Form,
  Spinner,
  Button
} from "react-bootstrap";
import axios from "axios";

class AdminReports extends Component {
  constructor() {
    super();
    this.state = {
      reports: [],
      indivdualList: [],
      pages: "",
      showModalIndividual: false,
      receiptNum: "",
      startDate: new Date(),
      endDate: new Date(),
      receipt: "",
      credit_card: "",
      username: "",
      mode: "",
      accountId: "",
      payeezy: "",
      totalOnline: "",
      totalPhonedIn: "",
      sumOnline: "",
      sumPhonedin: "",
      buttonIsDisabled: false,
      isLoading: true,
      fileName: 'GWA-Admin-Control-Reports-'+moment().format("DD-MM-YYYY")+".csv",
      indivdualTransLog: [],
    };
  }
  componentWillMount() {
    this.getReports();
  }

  getReports = () => {
    return axios
      .get("/api/v1/transactions", {
        headers: { "Content-Type": "application/json" }
      })
      .then(response => {
        this.setState({
          pages: response.data.result.pages,
          reports: response.data.result.list,
          sumOnline: response.data.result.sumOnline ,
          sumPhonedin: response.data.result.sumPhonedin,
          totalOnline: response.data.result.totalOnline,
          totalPhonedIn: response.data.result.totalPhonedIn,
          isLoading: false,
        });
      });
  };

  searchReports = e => {
    this.setState({reports:[],buttonIsDisabled:true,isLoading:true});
    e.preventDefault();
    let startDateSave = this.state.startDate != null ? moment(this.state.startDate).format('MM/DD/YYYY'):null;
    let endDateSave = this.state.endDate != null ? moment(this.state.endDate).format('MM/DD/YYYY'):null;
    return axios
      .post(
        "/api/v1/search-transaction",
        {
          pay_date_start: startDateSave,
          pay_date_end: endDateSave,
          receipt: this.state.receipt,
          credit_card: this.state.credit_card,
          username: this.state.username,
          mode: this.state.mode,
          accountId: this.state.accountId,
          // payeezy: this.state.payeezy
          auth_no: this.state.payeezy
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => { 
        this.setState({
          reports: response.data.result.list,
          sumOnline: response.data.result.sumOnline ,
          sumPhonedin: response.data.result.sumPhonedin,
          totalOnline: response.data.result.totalOnline,
          totalPhonedIn: response.data.result.totalPhonedIn,
          buttonIsDisabled: false,
          isLoading: false
        });
      })
      .catch(error => {
        this.props.showMessage('danger', "Server Error!");
      });
  };

  inputChange = e => {
    this.setState({
      ...this.state,
      [e.target.name]: e.target.value
    });
  };

  renderList() {
    const columns = 
    [
      {
        dataField: 'username',
        text: 'User ID',
        formatter: (cellContent, row) => (
          <a id={row.id} receipt={row.receipt_number} onClick={this.getTranslog.bind(this)}>{row.username}</a>
        )
      }, {
        dataField: 'accountId',
        text: 'Account #',
        sort: true,
      }, {
        dataField: 'auth_no',
        text: 'Auth #',
        sort: true,
      }, {
        dataField: 'payment_date',
        text: 'Payment Date',
        sort: true,
      }, {
        dataField: 'receipt_number',
        text: 'Receipt #',
        sort: true,
      },{
        dataField: 'count',
        text: '# of Payments',
        sort: true,
      },{
        dataField: 'transaction_tag',
        text: 'Trans. Tag',
        sort: true,
      },
      // {
      //   dataField: 'pay_eezy_seq_num',
      //   text: 'Payeezy Seq. #',
      //   sort: true,
      // },
      {
        dataField: 'amount',
        text: 'Amount',
        sort: true,
      }, {
        dataField: 'id',
        text: 'Details',
        formatter: (cellContent, row) => (
          <a id={row.id} receipt={row.receipt_number} onClick={this.getIndividual.bind(this)}>view</a>
        )
      }, {
        dataField: 'process_message',
        text: 'Process Msg'
      }, {
        dataField: 'bank_response',
        text: 'Bank Response'
      }, {
        dataField: 'mode',
        text: 'Mode'
      },{ 
        dataField: 'ccb_status',
        text: 'In CCB',
        sort: true,
      }
    ];

    const options = {
      paginationSize: 4,
      pageStartIndex: 1,
      // alwaysShowAllBtns: true, // Always show next and previous button
      // withFirstAndLast: false, // Hide the going to First and Last page button
      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
      firstPageText: 'First',
      prePageText: 'Back',
      nextPageText: 'Next',
      lastPageText: 'Last',
      nextPageTitle: 'First page',
      prePageTitle: 'Pre page',
      firstPageTitle: 'Next page',
      lastPageTitle: 'Last page',
      showTotal: true,
      // paginationTotalRenderer: customTotal,
      sizePerPageList: [{
        text: '10', value: 10
      }, {
        text: '30', value: 30
      }, {
        text: '50', value:50
      }]
    };

    const defaultSorted =
     [
      {
        dataField: 'payment_date',
        order: 'desc'
      }, {
        dataField: 'receipt_number',
        order: 'desc'
      }, {
        dataField: 'transaction_tag',
        order: 'desc'
      }, {
        dataField: 'pay_eezy_seq_num',
        order: 'desc'
      }, {
        dataField: 'amount',
        order: 'desc'
      }
    ];

    const headers = [
      {
        label: 'UserId',
        key: 'username'
      },
      {
        label: 'Account #',
        key: 'accountId'
      },
      {
        label: 'Auth #',
        key: 'auth_no'
      },
      {
        label: 'Payment Date',
        key: 'payment_date'
      },
      {
        label: 'Receipt #',
        key: 'receipt_number'
      },
      {
        label: '# of Payments',
        key: 'count'
      },
      {
        label: 'Trans. Tag',
        key: 'transaction_tag'
      },
      // {
      //   label: 'Payeezy Seq. #',
      //   key: 'pay_eezy_seq_num'
      // },
      {
        label: 'Amount',
        key: 'amount'
      },
      {
        label: 'Process Msg',
        key: 'process_message'
      },
      {
        label: 'Bank Response',
        key: 'bank_response'
      },
      {
        label: 'Mode',
        key: 'mode'
      },
      {
        label: 'In CCB',
        key: 'ccb_status'
      },
    ]

   return (
      <div id="report-list-container">
        <div class="csv-container">
          <CSVLink 
            data={ this.state.reports }
            headers={headers}
            filename={this.state.fileName} 
            className="btn-export-csv">
            Export CSV
          </CSVLink>
        </div>
        <BootstrapTable 
        bootstrap4 = {true}
        keyField='id' 
        data={ this.state.reports } 
        columns={ columns } 
        pagination={ paginationFactory(options) }
        defaultSorted={ defaultSorted }
        noDataIndication="No Web Payments Made"  
        hover
        condensed
        wrapperClasses="table-responsive"
        />
        
      </div>
    );
  }

  getIndividual = (e) => {
    let id = e.currentTarget.getAttribute("id");
    let receiptNum = e.currentTarget.getAttribute("receipt");
    e.preventDefault();
    return axios
    .get('/api/v1/get-individual/'+id, 
    {
        headers: {'Content-Type':'application/json'}
    })
    .then(response => {
      this.setState({
        receiptNum: receiptNum,
        indivdualList: response.data.result.data
      }, () => {
        console.clear();
        
          if(this.state.indivdualList != null) {
            this.setState({
              showModalIndividual: true
            })
          }
      });
      
    });
}

getTranslog = (e) => {
  let id = e.currentTarget.getAttribute("id");
  e.preventDefault();
  return axios
  .post('/api/v1/search-transaction-logs', 
  {
    overallId: id,
    pay_date_start: '',
    pay_date_end: ''
  },
  {
      headers: {'Content-Type':'application/json'}
  })
  .then(response => {
    this.setState({
      indivdualTransLog: response.data
    }, () => {
      // console.clear();
          this.setState({
            showModalTranslog: true
          })
    });
    
  });
}
  handleCloseModalIndividual = () => {
    this.setState({ showModalIndividual: false });
  };
  handleCloseModalIndividualTransLogs= () => {
    this.setState({ showModalTranslog: false });
  };
  handleChangeStart = (date) => {
    this.setState({
      startDate: date,
    });
  };

  handleChangeEnd = (date) => {  
    this.setState({
      endDate: date
    });
  };

  render() {

    const columnsTransLog = 
    [
      {
        text: 'Insert overall transaction result',
        formatter: (cellContent, row) => (
          <pre>{JSON.stringify(row.save_over_all_transaction, undefined, 2)}</pre>
        )
      },{
        text: 'Insert individual transaction result',
        formatter: (cellContent, row) => (
          <pre>{JSON.stringify(row.save_individual_transation, undefined, 2)}</pre>
        )
      }, {
        text: 'Payeezy result',
        formatter: (cellContent, row) => (
          <pre style={{width: '310px'}}>{JSON.stringify(row.payeezy_response, undefined, 2)}</pre>
        )
      }, {
        text: 'CCB make payment result',
        formatter: (cellContent, row) => (
          <pre>{JSON.stringify(row.save_ccb_response, undefined, 2)}</pre>
        )
      }, {
        text: 'Email result',
        formatter: (cellContent, row) => (
          <pre>{JSON.stringify(row.email_response, undefined, 2)}</pre>
        )
      }, {
        text: 'Update overall transaction result',
        formatter: (cellContent, row) => (
          <pre>{JSON.stringify(row.update_over_all_transaction, undefined, 2)}</pre>
        )
      }, {
        text: 'Update individual transaction result',
        formatter: (cellContent, row) => (
          <pre>{JSON.stringify(row.update_individual_transaction, undefined, 2)}</pre>
        )
      }
    ];

    const columns = 
    [
      {
        dataField: 'account_number',
        text: 'Account No.',
      },{
        dataField: 'receipt_num',
        text: 'Receipt No.',
      }, {
        dataField: 'amount',
        text: 'Amount'
      }, {
        dataField: 'status',
        text: 'Status'
      }, {
        dataField: 'ccb_status',
        text: 'In CCB'
      }, {
        dataField: 'payment_date',
        text: 'GWA Interface Data'
      }
    ];

    return (
      <React.Fragment>
        <Modal
          show={this.state.showModalTranslog}
          onHide={this.handleCloseModalIndividualTransLogs}
          // aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-individualtranslog"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <BootstrapTable 
              bootstrap4 = {true}
              keyField='id' 
              data={ this.state.indivdualTransLog } 
              columns={ columnsTransLog } 
              noDataIndication="No transaction log found"  
              hover
              condensed
              wrapperClasses="table-responsive"
            />
          <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalIndividualTransLogs}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showModalIndividual}
          onHide={this.handleCloseModalIndividual}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-individual"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <p>Payment Details for Receipt Number: {this.state.receiptNum}</p>
            <BootstrapTable 
              bootstrap4 = {true}
              keyField='id' 
              data={ this.state.indivdualList } 
              columns={ columns } 
              noDataIndication="No Web Payments Made"  
              hover
              condensed
              wrapperClasses="table-responsive"
            />
          <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalIndividual}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Container fluid={true}>
          <Row>
            <Col>
              <div id="admin-reports-container">
                <h5>All Payments/Transaction Inquiry</h5>
                <h6>(Successful and Unsuccessful Transactions)</h6>
                <p>Search for a Payment Transactions</p>
                <div className="search-container">
                  <div className="search-container-left">
                    <div className="input-cont">
                      <label>Pay Date Start: </label><div className="input-right"><DatePicker dateFormat="MM/dd/yyyy" selected={this.state.startDate} onChange={this.handleChangeStart} /></div>
                    </div>
                    <div className="input-cont">
                      <label>Receipt No.: </label><div className="input-right"><input type="text" autocomplete="off" name="receipt" onChange={this.inputChange.bind(this)} /></div>
                    </div>
                    <div className="input-cont">
                      <label>User ID:</label><div className="input-right"><input type="text" autocomplete="off" name="username" onChange={this.inputChange.bind(this)} /></div>
                    </div>
                    <div className="input-cont">
                      <label>Account No.:</label><div className="input-right"><input type="text" autocomplete="off" name="accountId" onChange={this.inputChange.bind(this)} /></div>
                    </div>
                  </div>
                  <div className="search-container-right">
                    <div className="input-cont">
                      <label>Pay Date End: </label><div className="input-right"><DatePicker dateFormat="MM/dd/yyyy" selected={this.state.endDate} onChange={this.handleChangeEnd} /></div>
                    </div>
                    <div className="input-cont">
                      <label>Credit Card Transaction Tag: </label><div className="input-right"><input type="text" autocomplete="off" name="credit_card" onChange={this.inputChange.bind(this)} /></div>
                    </div>
                    <div className="input-cont">
                      <label>Mode:</label>
                        <div className="input-right">
                          <select name="mode" onChange={this.inputChange.bind(this)} >
                            <option value=""></option>
                            <option value="Online">Online</option>
                            <option value="Phoned-In">Phoned-In</option>
                          </select>
                        </div>
                    </div>
                    <div className="input-cont">
                      {/* <label>Payeezy Sequence No.:</label><div className="input-right"><input type="text" name="payeezy" autocomplete="off" /></div> */}
                      <label>Authorization #:</label><div className="input-right"><input type="text" name="payeezy" autocomplete="off" onChange={this.inputChange.bind(this)}/></div>
                    </div>
                  </div>
                  <div>
                    {
                      this.state.buttonIsDisabled ? 
                      <button
                          type="submit"
                          disabled
                          className="btn btn-change-ok font-bold "
                        >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                      :
                      <button
                          type="submit"
                          onClick={this.searchReports.bind(this)}
                          className="btn btn-change-ok font-bold btn-primary"
                        >
                          Search
                      </button>
                    }
 
                  </div>
                </div>

                <div className="total-cont">
                  <div className="total-left">
                    <p><label>Total No of Online Payments</label><span>{this.state.totalOnline}</span></p>
                    <p><label>Total No of Phoned-In Payments</label><span>{this.state.totalPhonedIn}</span></p>
                  </div>
                  <div className="total-right">
                    <p><label>Total Amount of Online Payments</label><span>{this.state.sumOnline}</span></p>
                    <p><label>Total Amount of Phoned-In Payments</label><span>{this.state.sumPhonedin}</span></p>
                  </div>
                </div>
                  { this.state.isLoading ? 
                    <div style={{marginTop: '50px'}}>
                        <center>
                        <Spinner
                            
                            as="span"
                            animation="border"
                            size="md"
                            role="status"
                            aria-hidden="true"
                        />
                        </center>
                    </div> :
                    this.renderList()
                  }
                
                      
              </div>
          </Col>
        </Row>
      </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  dashboard: state.dashboard
});

export default connect(mapStateToProps, 
{ 
  showMessage 
})(AdminReports);
