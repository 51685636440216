import React, { Component } from 'react';
import Header from '../../partials/header';
import Footer from '../../partials/less-content-footer';
import { connect } from 'react-redux';
import { 
  saveAccountId,
  savePremiseAddress,
  fetchMultipleAddOpptyRequest,
  fetchMultipleLatestBill,
  saveOrderData
} from '../../actions/dashboardActions';

import { Tabs, Tab, Modal, Table, Col, Container, Row, Form, Spinner, Button,OverlayTrigger,Popover } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import axios from 'axios';
import $ from 'jquery';
import _ from 'lodash';
import PayNowModal from '../paynowModal'
import NumberFormat from 'react-number-format';

import { 
  showMessage
} from '../../actions/popupMessageActions';

import * as moment from 'moment';

const dashboardBaseURL = process.env.REACT_APP_LOGIN_SIGNUP_API_URL;

class AdminPhonedIn extends Component {
  constructor(){
    super()
    this.state = {
      step1: true,
      step2: false,
      step3: false,
      step4: false,
      accounts: [],
      defaultId: "",
      values: [''],
      buttonDisabled: false,
      showModalDetails: false,
      showModalDetailsHistory: false,
      showModalAdminPaynow: false,
      viewDetails: [],
      paymentHistory: [],
      paymentHistoryComplete: [],
      paymentAccountId: "",
      shoppingCartData: [],
      acctIdsMultple: [],
      subTotalArr: [],
      selectAll: false,
      accountSummary: [],
      subtotal: 0,
      isLoading: false,
      isCheckedAll: false,
      username: localStorage.getItem("adminusername"),
      emailAddress: ""
    }
  }

  componentWillMount() {
    window.setInterval(() => {
      this.setState({
          isLoading: false,
      });
      
    }, 50);
    
  }

  //coded by ian
  saveOrderData = () => {
    const postData = {
        subTotal: this.state.subtotal,
        accountSummary: this.state.accountSummary,
        isHasInvalid: false
    }
    this.props.saveOrderData(postData)
  }

  updateSubTotal = () => {
    let subtotal = 0
    const arrAccountSummary = this.state.accountSummary
    for(let count = 0; count < arrAccountSummary.length; count++){
        if(arrAccountSummary[count].checked){
            subtotal = subtotal + parseFloat(arrAccountSummary[count].amountToBePaid)
        }
    }
    
    this.setState({
        subtotal: subtotal
    },() => {
        this.saveOrderData()
    })
  }
  formatAmount = (value) => {
    value = value.replace('$ ',''); //removes '$ '
    value = value.replace(/\,/g,'') //removes all ','
    return value = parseFloat(Math.round(value * 100) / 100).toFixed(2)
  } 
  amountToBePaidOnChange = (e) => {
    let id = e.target.id, value = e.target.value;
    value = this.formatAmount(value);
    const arrAccountSummary = this.state.accountSummary
    let selectedIndex = ""
    for(let count = 0; count < arrAccountSummary.length; count++){
        if(arrAccountSummary[count].accID === id){
            selectedIndex = count
        }
    }
    arrAccountSummary[selectedIndex].amountToBePaid = (value === "") ? 0 : value
    this.setState({
        ...this.state,
        accountSummary: arrAccountSummary
    },()=>{this.updateSubTotal()})
  }
  checkBoxOnChange = (e) => {
      const id = e.target.id, value = e.target.value
      const arrAccountSummary = this.state.accountSummary
      let selectedIndex = "", isCheckedAll = false
      for(let count = 0; count < arrAccountSummary.length; count++){
          if(arrAccountSummary[count].accID === id){
              selectedIndex = count
          }
      }
      if(e.target.checked){
          arrAccountSummary[selectedIndex].checked = true
      }
      else{
          isCheckedAll = false
          arrAccountSummary[selectedIndex].checked = false
      }
      this.setState({
          ...this.state,
          isCheckedAll: isCheckedAll,
          accountSummary: arrAccountSummary
      },()=>{this.updateSubTotal()})
  }
  checkAll = (e) => {
      let subtotal = 0
      const arrAccountSummary = this.state.accountSummary
      if(e.target.checked){
          for(let count = 0; count < arrAccountSummary.length; count++){
              arrAccountSummary[count].checked = true
              subtotal = subtotal + parseFloat(arrAccountSummary[count].amountToBePaid)
          }
          this.setState({
              isCheckedAll: true,
              subtotal: subtotal
          },() => {
              this.saveOrderData()
          })
      }else{
          for(let count = 0; count < arrAccountSummary.length; count++){
              arrAccountSummary[count].checked = false
          }
          this.setState({
              isCheckedAll: false,
              subtotal: 0
          },() => {
              this.saveOrderData()
          })
      }
  }
  handleGoStep1 = () => {
    this.setState({
      step1: true,
      step2: false,
      step3: false,
      step4: false
    })
  }

  handleGoStep2 = async () => {
    this.setState({
      addToCartBtnLoading: true
    })
    
    const postAccIDs = [],
      { accountSummary, accounts, values } = this.state;
    console.log("values", values)
    if(accounts.length == 0){
      this.setState({
        addToCartBtnLoading: false
      })
      this.props.showMessage('danger', "Please search an account!");
      return false;
    }
    for(let count=0; count < (this.state.acctIdsMultple).length; count++){
      if(values.includes(this.state.acctIdsMultple[count])){
        postAccIDs.push([this.state.acctIdsMultple[count], "account prem address", "account type"]);
      }
    }
    let modifiedAccIds = [];

    if(accountSummary.length > 0){
      modifiedAccIds = accountSummary.filter(({amount, amountToBePaid, checked}) => amountToBePaid < parseFloat(amount) || checked);
    }
    console.log("accountSummary", accountSummary)
    console.log("postAccIDs", postAccIDs)
    if(postAccIDs.length == 0){
      this.setState({
        addToCartBtnLoading: false
      })
      this.props.showMessage('danger', "Please add atleast 1 account to your cart!");
      return false;
    }
    const res = await Promise.resolve(this.props.fetchMultipleLatestBill(postAccIDs));
    if(res){
      let accountsSmmry = this.props.dashboard.orderData.accountSummary;
      let final = [];
      if(modifiedAccIds.length > 0){
        for(let count = 0; count < accountsSmmry.length; count++){
          for(let count1 = 0; count1 < modifiedAccIds.length; count1++){
            if(accountsSmmry[count].accID == modifiedAccIds[count1].accID){
              accountsSmmry[count].amountToBePaid = modifiedAccIds[count1].amountToBePaid;
              accountsSmmry[count].checked = modifiedAccIds[count1].checked;
            }
          }
        }
        accountsSmmry = accountsSmmry.filter(({amount, amountToBePaid, checked, accID}) => values.includes(accID) );
        console.log('final', accountsSmmry);
        this.updateSubTotal();
      }
      
      this.setState({
          accountSummary: accountsSmmry,
          subtotal: this.props.dashboard.orderData.subTotal,
      }, () => {
        let details = [];
        let accounts = this.state.accounts;
        let acctIdsMultple = this.state.acctIdsMultple;

        for(let x=0; x<accounts.length; x++){
          for(let y=0; y<acctIdsMultple.length; y++){
            if(accounts[x].accountId  == acctIdsMultple[y] ){
              let objTest = {
                
              }
              details.push();
            }
          }
        }
        this.setState({
          shoppingCartData: details,
          addToCartBtnLoading: false,
          step1: false,
          step2: true,
          step3: false,
          step4: false
        })
      })
    }
    
  }

  handleGoStep3 = () => {
    this.setState({
      step1: false,
      showModalAdminPaynow: true
      // step3: true,
      // step4: false
    })
  }

  handleGoStep4 = () => {
    this.setState({
      step1: false,
      step2: false,
      step3: false,
      step4:true
    })
  }
  

createUI(){
    return this.state.values.map((el, i) => (
      <div key={i} className="top-cont">
       <div className="input-left-search">
         <input type="text" value={el||''} onChange={this.handleChange.bind(this, i)} />
        </div>
        <div className="add-left">
          {this.state.values.length == 1 || i == 0 ? null : <img onClick={this.removeClick.bind(this, i)} src={process.env.PUBLIC_URL+'/images/icon-del.png'} />}
          
        </div>
      </div>          
    ))                                                                                                                                  
 }
 
handleChangeDefault(e) {
  this.setState({defaultId: e.target.value});
}

handleChange(i, event) {
  let values = [...this.state.values];
  values[i] = event.target.value;
  this.setState({ values });
}
 
 addClick(){
   this.setState(prevState => ({ values: [...prevState.values, '']}))
 }
 
 removeClick(i){
    let values = [...this.state.values];
    values.splice(i,1);
    this.setState({ values });
 }

 handleSubmit(event) {
  event.preventDefault();
  this.setState({accounts: [], buttonDisabled: true, selectAll: false})
  let accountIds = [...this.state.values,this.state.defaultId];
  let uniqueAccIds = [];

  $.each(accountIds, function(i, el){
      if($.inArray(el, uniqueAccIds) === -1 && el != "") uniqueAccIds.push(el);
  });
  console.log("uniqueAccIdsuniqueAccIdsuniqueAccIdsuniqueAccIds",uniqueAccIds)
  if(uniqueAccIds.length == 0){
    this.setState({
      buttonDisabled: false
    })
    this.props.showMessage('danger', "Please add atleast 1 search parameter!");
    return false;
  }
  uniqueAccIds.map((itemId, i) => {
    
    axios.post(dashboardBaseURL+'/api/v1/user-latest-bill',
    {
      accountId: itemId,
      division: "GWA"
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => {
      this.setState({
        values: uniqueAccIds
      })
      console.log(this.state.values)
      if(response.data.result.status === 'Failed'){
        this.setState({
          buttonDisabled: false
        }, () => {
          this.props.showMessage('danger', response.data.result.description+` (${itemId})`);
        })
      }
      else{
        this.setState({accounts:  [...this.state.accounts, response.data.result], buttonDisabled: false, emailAddress: response.data.result.email, userFullname: response.data.result.firstName});
      }
    })
    .catch(error => {
      this.setState({
        buttonDisabled: false
      })
      this.props.showMessage('danger', "Server Error!");
    });
  })
 
}

handleCloseModalIndividual = () => {
  this.setState({ showModalDetails: false, showModalDetailsHistory:false, showModalAdminPaynow: false });
};

getDataDetails = (e) => {
  let accountId = e.currentTarget.getAttribute("accountId");
  let details = [];
  this.state.accounts.map((item, i) => {
    if(item.arrears.details.AccountID == accountId){
      details.push(item);
    }
  });
  this.setState({ viewDetails: details, showModalDetails: true });
  
}

getDataDetailsPayment = (e) => {
  let accountId = e.currentTarget.getAttribute("accountId");
  axios.post('/api/v1/user-payment-history',
  {
    accountId: accountId,
  },
  {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    this.setState({paymentHistory: response.data, showModalDetailsHistory: true, paymentAccountId:accountId});
  })
  .catch(error => {
    this.props.showMessage('danger', "Server Error!");
  });
  axios.post('/api/v1/user-payment-history-ccb',
  {
    accountId: accountId,
  },
  {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then(response => {
    this.setState({paymentHistoryComplete: response.data.result});
  })
  .catch(error => {
    this.props.showMessage('danger', "Server Error!");
  });
}

handleCheckAll = (e) => {
  let accounts = this.state.accounts;
  let acctIdsMultple = [];
  
  this.setState({ selectAll : !this.state.selectAll }, () => {
    if(this.state.selectAll == true) { //true or false
      for(let x = 0; x < accounts.length ; x++){
        acctIdsMultple.push(accounts[x].arrears.details.AccountID);
      }
      this.setState({
        acctIdsMultple
      })
    }
    else{
      this.setState({
        acctIdsMultple
      })
    }
  });
}

checkInput = (item, index) => {
  if(this.state.acctIdsMultple.includes(item.arrears.details.AccountID)){ //if true, it is in checked users -> user will uncheck 
    let copyOfacctIdsMultple = this.state.acctIdsMultple;
    copyOfacctIdsMultple.splice(copyOfacctIdsMultple.indexOf(item.arrears.details.AccountID), 1);
    this.setState({ //remove on checked list and disable select all mode
      selectAll : false,
      acctIdsMultple : copyOfacctIdsMultple
    })
  }
  else
  {
    let accounts = this.state.accounts;
    let acctIdsMultple = this.state.acctIdsMultple;
    acctIdsMultple.push(item.arrears.details.AccountID);
    this.setState({
      selectAll : false,
      acctIdsMultple : acctIdsMultple
    })
  }

  // FINAL CHECKER IF ALL ITEMS ARE SELECTED
  // alert("this.state.acctIdsMultple.length "+this.state.acctIdsMultple.length);
  // alert("this.state.accounts.length "+this.state.accounts.length);
  if(this.state.acctIdsMultple.length == this.state.accounts.length){
    // let accounts = this.state.accounts;
    // let acctIdsMultple = [];
    // for(let x = 0; x < accounts.length ; x++){
    //   acctIdsMultple.push(item.arrears.details.AccountID);
    // }
    this.setState({
      selectAll : true,
      // acctIdsMultple
    })
  }
}

computeSubtotal = (i, event) => {
  let subTotalArr = [...this.state.subTotalArr];
  subTotalArr[i] = event.target.value;
  this.setState({ 
    subTotalArr }, () => {
      this.updateTotal();
  });
  
}
formatDate = (date) => {
  return moment(date).format('MM/DD/YYYY');
}
updateTotal = () => {
  let subtotal = 0;
  let subTotalArr = this.state.subTotalArr;

  for(let count = 0; count < subTotalArr.length; count++){
    subtotal += parseFloat(subTotalArr[count]);
  }
  this.setState({ subtotal: subtotal });
}
handleCancelStep2 = (e) => {
  this.setState({
    accounts: [],
    acctIdsMultple: [],
    values: [''],
    defaultId: ""
  })
}
  render() { 
    const columns = 
    [
      {
        dataField: 'payment_date',
        text: 'Pay Date',
      }, {
        dataField: 'username',
        text: 'UserID'
      }, {
        dataField: 'receipt_num',
        text: 'Receipt No.'
      }, {
        dataField: 'transaction_tag',
        text: 'CC Trans No.'
      }, {
        dataField: 'status',
        text: 'Status'
      }, {
        dataField: 'amount',
        text: 'Amount'
      }
    ];
    
    return (
      <React.Fragment>
        <Modal
          show={this.state.showModalDetails}
          onHide={this.handleCloseModalIndividual}
          size="l"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-individual-reports"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
          <h5>Account Details</h5>
          <div class="details-summary-cont">
            <Table>
              <tbody>
                {
                  this.state.viewDetails.map((item, i) => (
                  <tr key={i}>
                    <tr>
                      <td colSpan="3" className="td-style-first">
                        Billing Date:
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{this.formatDate(item.date.billDate)}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="td-style-first">
                        Account No:
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{item.arrears.details.AccountID}</td>
                    </tr>
                    <tr>
                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="td-style-first">
                        Last Payment Date:
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{this.formatDate(item.date.billDate)}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="td-style-first">
                        Last Payment Amount:
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{item.arrears.details.CurrentBalance}</td>
                    </tr>
                    <tr>
                      <td>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="td-style-first">
                        Current Billing Amount:
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{item.arrears.details.CurrentBalance}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="td-style-first">
                        Total Due:
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{item.arrears.details.CurrentBalance}</td>
                    </tr>
                    <tr>
                      <td colSpan="3" className="td-style-first">
                        Due Date:
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{this.formatDate(item.date.dueDate)}</td>
                    </tr>
                  </tr>
                ))
              }
              </tbody>
            </Table>   
          </div>


          <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalIndividual}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        
        <Modal
          show={this.state.showModalDetailsHistory}
          onHide={this.handleCloseModalIndividual}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          id="modal-for-phonedin-payment"
          backdrop="static"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
          <h5>Payment History</h5>
          <p>Payment History for Account Number: {this.state.paymentAccountId}  </p>
          <Tabs defaultActiveKey="web" id="uncontrolled-tab-example">
            <Tab eventKey="web" title="Web">
              <BootstrapTable 
                id="web-pay-history-table"
                bootstrap4 = {true}
                keyField='id' 
                data={ this.state.paymentHistory } 
                columns={ columns } 
                hover
                condensed
                wrapperClasses="table-responsive"
              />
            </Tab>
            <Tab eventKey="web-complete" title="Complete">
              <table id="payment-history-complete" className="table table-bordered table-striped">
                <thead>
                    <tr>
                    <th scope="col" className="text-center">Payment Date</th>
                    <th scope="col" className="text-center">Description</th>
                    <th scope="col" className="text-center">Payment Amount</th>
                    </tr>
                </thead>
                <tbody>
                    { 
                      this.state.paymentHistoryComplete.length > 0 ?
                      _.map(this.state.paymentHistoryComplete, (item, index) => {
                        return(
                          index < 18 ?
                              <tr key={index}>
                                  <td>{this.formatDate(item.ArrearsDate)}</td>
                                  <td>{item.FinancialTransactionType === "PS" ? "Payment" : "Payment Adjustment"}</td>
                                  <td>${item.TotalAmount}</td>
                              </tr>
                          :
                              ""
                        )
                      })
                      :
                      <tr><td colSpan="12"><center>Loading...</center></td></tr>
                    }    
                </tbody>
              </table>
            </Tab>
          </Tabs>
          
          

          <div className="btn-submit-change-ok">
              <button
                type="submit"
                onClick={this.handleCloseModalIndividual}
                className="btn btn-change-ok font-bold btn-primary"
              >
                Close
              </button>
            </div>
          </Modal.Body>
        </Modal>
        
        <Container fluid={true}>
          <Row>
            <Col>
              <div id="admin-phonedin-container">
                {
                  this.state.step1 ?
                <div id="step1-cont"> 
                <h5>Phoned-In Bill Payments</h5>
                <p>Account Search Filter(s):</p>
                <div className="phonedin-search-cont">
                  <div className="input-cont-left">
                    <label>Search By:</label>
                      <div className="">
                        <h6>Account Number:</h6>
                      </div>
                  </div>
                  <div className="input-cont-right">                      
                      <div className="input-left-search">
                        <p class="m-0">Search Parameters</p>
                      </div>
                      <div className="add-left">
                       <img onClick={this.addClick.bind(this) }src={process.env.PUBLIC_URL+'/images/add-text.png'} />
                      </div>
                        {/* <input type="text" className="defaultInput" onChange={this.handleChangeDefault.bind(this)} /> */}
                       {this.createUI()}
                  </div>
                  <div className="button-cont-right">
                    {
                      this.state.buttonDisabled ? 
                      <button
                          type="submit"
                          disabled
                          className="btn btn-change-ok font-bold btn-primary"
                        >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                      :
                      <button
                        type="submit"
                        onClick={this.handleSubmit.bind(this)}
                        className="btn btn-change-ok font-bold btn-primary"
                      >
                        Search
                    </button>
                    }
                  </div>
                </div>

                <div className="table-summary-cont">
                  <p>Please select the account(s) to pay and click ADD TO CART to proceed.</p>
                  <Table className="table table-responsive-sm">
                        <thead>
                          <tr>
                            <th colSpan="7">Account Summary</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="with-style">
                            <td width="5%">
                            <Form.Group className="check-container">
                              <label className="container-check">
                              <Form.Check.Input isValid className="" checked={this.state.selectAll} onClick={this.handleCheckAll.bind(this)} />          
                                <span className="checkmark"></span>
                              </label>
                            </Form.Group>
                            </td>
                            <td className="with-outline">Account No.</td>
                            <td className="with-outline">Service Location</td>
                            <td className="with-outline">Balance Due</td>
                            <td className="with-outline">Customer Name</td>
                            <td className="with-outline">Details</td>
                            <td className="with-outline">Payment History</td>
                          </tr>
                          {
                            this.state.accounts.map((item, i) => (
                              <tr key={i}>
                                <td width="5%">
                                <Form.Group className="check-container">
                                  <label className="container-check">
                                    <Form.Check.Input isValid className="" value={item.arrears.details.AccountID} checked={this.state.acctIdsMultple.includes(item.arrears.details.AccountID)} onClick={() => this.checkInput(item, i)}/>          
                                    <span className="checkmark"></span>
                                  </label>
                                </Form.Group>
                                </td>
                                <td className="with-outline">{item.arrears.details.AccountID}</td>
                                <td className="with-outline">
                                  {/* {item.arrears.details.PremiseInfo} */}
                                  <div className="service-location">
                                        <OverlayTrigger
                                            trigger="hover"
                                            placement="top"
                                            overlay={
                                                <Popover id="popover-contained">
                                                    {item.arrears.details.PremiseInfo}
                                                </Popover>
                                            }
                                        >
                                            <p className="m-0">{(item.arrears.details.PremiseInfo).substr(0, 25) + '...'}</p>
                                        </OverlayTrigger>
                                      </div>
                                </td>
                                <td className="with-outline text-right">{item.arrears.details.CurrentBalance}</td>
                                <td className="with-outline">{item.arrears.details.Name}</td>                
                                <td className="with-outline"><a onClick={this.getDataDetails.bind(this)} accountId={item.arrears.details.AccountID}>view</a></td>
                                <td className="with-outline"><a onClick={this.getDataDetailsPayment.bind(this)} accountId={item.arrears.details.AccountID}>view history</a></td>
                              </tr>
                            ))
                          }             
                        </tbody>
                    </Table>
                      <div className="add-to-cart-cont">
                        <div className="button-cart-add">
                          {
                            this.state.addToCartBtnLoading ?
                              <button
                                type="submit"
                                disabled
                                className="btn btn-change-ok font-bold btn-primary"
                              >
                                <Spinner
                                  as="span"
                                  animation="grow"
                                  size="sm"
                                  role="status"
                                  aria-hidden="true"
                                />
                                Loading...
                              </button>
                            :
                              <button
                                  type="submit"
                                  onClick={this.handleGoStep2}
                                  className="btn btn-change-ok font-bold btn-primary"
                                >
                                  Add to Cart
                              </button>
                          }
                        </div>
                        <div className="button-cart-cancel">
                          <button
                              type="submit"
                              // onClick={this.handleGoStep2}
                              className="btn btn-change-ok font-bold btn-primary"
                              onClick={this.handleCancelStep2.bind(this)}
                            >
                              Cancel
                          </button>
                        </div>
                      </div>
                </div>
                </div>
                  :null
                }

                {
                  this.state.step2 ? 
                  <div id="step2-cont">
                    <h5>Shopping Cart</h5>
                    <p>Please verify the amount to be paid for each selected account.</p>
                    <div className="table-shopping-cont">
                      <p>You may choose to pay an amount that differs from the Amount Due - simply fill-on the text box in the Payment Amount column with the desired amount. When ready, click CONTINUE to process to the next step.</p>
                      <Table className="table">
                            <thead>
                              <tr>
                                <th colSpan="7">Account Information</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="with-style">
                                <td width="5%">
                                <Form.Group className="check-container">
                                  <label className="container-check">
                                    {/* <Form.Check.Input isValid className="" checked={this.state.selectAllAnnounce} onClick={this.handleInputAnnounceAll.bind(this)}/> */}

                                    <Form.Check.Input isValid id="check-all" checked={this.state.isCheckedAll} onChange={this.checkAll.bind(this)}/>          
                                    <span className="checkmark"></span>
                                  </label>
                                </Form.Group>
                                </td>
                                <td className="with-outline">Account No.</td>
                                <td className="with-outline">Balance Due</td>
                                <td className="with-outline">Payment Amount</td>
                                <td className="with-outline">Last Billing Date</td>
                                <td className="with-outline">Customer Name</td>
                              </tr>
                              {
                                this.state.accountSummary.map((item, index) => (
                                  <tr key={index}>
                                    <td width="5%">
                                      <Form.Group className="check-container">
                                        <label className="container-check">
                                          {/* <Form.Check.Input isValid className="" checked={this.state.selectAllAnnounce} onClick={this.handleInputAnnounceAll.bind(this)}/> */}

                                          <Form.Check.Input isValid className="" id={item.accID} value={item.amount} checked={item.checked} onChange={this.checkBoxOnChange.bind(this)}/>          
                                          <span className="checkmark"></span>
                                        </label>
                                      </Form.Group>
                                    </td>
                                    <td className="with-outline">{item.accID}</td>
                                    {/* <td className="with-outline">
                                      <div className="service-location">
                                        <OverlayTrigger
                                            trigger="hover"
                                            placement="top"
                                            overlay={
                                                <Popover id="popover-contained">
                                                    {item.serviceLocation}
                                                </Popover>
                                            }
                                        >
                                            <p className="m-0">{(item.serviceLocation).substr(0, 25) + '...'}</p>
                                        </OverlayTrigger>
                                      </div>
                                    </td> */}
                                    <td className="with-outline text-right">{item.amount}</td>
                                    <td className="with-outline">
                                      <div className="row">
                                        <div className="col-lg-8">
                                            {/* <input id={item.accID} type="number" onChange={this.amountToBePaidOnChange.bind(this)} className={item.validAmountToBePaid ? "valid-amount" : "invalid-amount"} disabled={!item.checked}/> */}
                                            <NumberFormat 
                                              id={item.accID}
                                              value={!item.checked ? "" : parseFloat(item.amountToBePaid)}
                                              thousandSeparator={true} 
                                              prefix={'$ '} 
                                              onChange={this.amountToBePaidOnChange.bind(this)}
                                              decimalScale={2}
                                              fixedDecimalScale={true}
                                              allowNegative={false}
                                              className={item.validAmountToBePaid ? "valid-amount text-right" : "invalid-amount text-right"}
                                              disabled={!item.checked}
                                            />
                                        </div>
                                        {
                                            (!item.validAmountToBePaid) ?
                                            <div className="col-lg-3 pl-1"> 
                                                <OverlayTrigger
                                                    trigger="hover"
                                                    placement="left"
                                                    overlay={
                                                    <Popover id="popover-contained">Payment has exceeded the monthly maximum of <strong>$500</strong> for non-residential accounts using {(item.usedCC === "visa" ? "Visa" : "Discover")} Card. { (500 - parseInt(item.alreadyPaid)) > 0 ? "You can only pay up to " : ""}<strong>{ (500 - parseInt(item.alreadyPaid)) > 0 ? "$ "+ (500 - parseInt(item.alreadyPaid)) : ""}</strong> { (500 - parseInt(item.alreadyPaid)) > 0 ? " for now using a "+(item.usedCC === "visa" ? "VISA" : "DISCOVER")+", or you may complete your payment using Mastercard or "+(item.usedCC === "visa" ? "Discover card." : "Visa card.") : "Alternatively, you may complete your payment using a Mastercard or "+(item.usedCC === "visa" ? "Discover card." : "Visa card.")}</Popover>
                                                    }
                                                    >
                                                    <span style={{color: "red", fontSize: "18px"}}><i class="fas fa-exclamation-triangle"></i></span>  
                                                </OverlayTrigger>
                                            </div>
                                            :
                                                ""
                                        }  
                                      </div>
                                    </td>                
                                    <td className="with-outline">{item.billDate}</td>
                                    <td className="with-outline">{item.fullName}</td>
                                  </tr>
                                ))
                              }    
                            </tbody>
                        </Table>

                        <div className="subtotal-cont-right">
                         <NumberFormat 
                            value={parseFloat(this.state.subtotal)} 
                            displayType={'text'} 
                            thousandSeparator={true} 
                            prefix={'$ '} 
                            decimalScale={2}
                            fixedDecimalScale={true}
                            renderText={value => <h6>Sub Total: <span>{value}</span></h6>} 
                          />
                        </div>
  
                          <div className="shopping-button-cont">
                            <div className="shopping-button-cont-left">
                              <div className="button-shopping-back">
                                <button
                                    type="submit"
                                    onClick={this.handleGoStep1}
                                    className="btn btn-change-ok font-bold btn-primary"
                                  >
                                    Back
                                </button>
                              </div>
                            </div>
                            <div className="shopping-button-cont-right">
                              <div className="button-shopping-cancel">
                                <button
                                    type="submit"
                                    onClick={this.handleGoStep1}
                                    className="btn btn-change-ok font-bold btn-primary"
                                  >
                                    Cancel
                                </button>
                              </div>
                              <div className="button-shopping-continue">
                                {/* <button
                                    type="submit"
                                    onClick={this.handleGoStep3}
                                    className="btn btn-change-ok font-bold btn-primary"
                                  >
                                    Continue
                                </button> */}
                                <PayNowModal adminUsername={this.props.adminUsername} accountSummary={this.state.accountSummary} subTotal={this.state.subtotal} email={this.state.emailAddress} fullName={this.state.userFullname} mode={"admin"}/>
                              </div>
                            </div>
                          </div>
                    </div>
                  </div>
                  :null
                }

                {
                  this.state.step3 ?
                  <div id="step3-cont">
                    
                  </div>
                  :null
                }

              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment> 
    );
  }
}

const mapStateToProps = state => ({
    dashboard: state.dashboard
});

export default connect(mapStateToProps, { 
    saveAccountId,
    savePremiseAddress,
    fetchMultipleAddOpptyRequest,
    fetchMultipleLatestBill,
    saveOrderData,
    showMessage 
})(AdminPhonedIn);